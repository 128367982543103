import { 
    SET_LANGUAGE, 
    SET_LOCALES, 
    BaseActionTypes 
} from './types';

import { REHYDRATE } from 'redux-persist';

const initialState = {
    language: '',
    locales: {},
};

export const baseReducer = (state = initialState, action: BaseActionTypes) => {
    switch (action.type) {

        case SET_LANGUAGE: {
            return {
                ...state,
                language: action.payload
            };
        }

        case SET_LOCALES: {
            return {
                ...state,
                locales: action.payload
            };
        }

        case REHYDRATE: {
            return state;
        }

        default: {
            return {
                ...state
            }
        }

    }
};