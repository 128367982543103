import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import {
    differenceInMinutes,
    differenceInHours,
    differenceInDays,
    differenceInWeeks,
    differenceInMonths,
} from 'date-fns';


import Store from 'core/store/store';
import { setUsers, setCalendars, setAppointments, setLessons, setPayments, setUserActions, setAnnouncements } from 'core/store/admin/action';
import {
    getUserInsights, getAppointmentInsights, getPaymentInsights,
    getUsers, getUserActions, getPayments, getAnnouncements
} from 'core/store/admin/selectors';

import User from 'core/apis/User';
import Calendar from 'core/apis/Calendar';
import Appointment from 'core/apis/Appointment';
import Lesson from 'core/apis/Lesson';
import Payment from 'core/apis/Payment';
import UserActions from 'core/apis/UserActions';
import Announcement from 'core/apis/Announcement';

import { LocalizationContext } from 'core/context/localizationContext';

import Spinner from 'components/Spinner/Spinner';
import Card from 'components/Card/Card';
// import DropdownMenu from 'components/Dropdown/DropdownMenu/DropdownMenu';
// import DropdownItem from 'components/Dropdown/DropdownItem/DropdownItem';

import InsightsCard from 'containers/Admin/AdminPanel/Sections/Dashboard/InsightsCard/InsightsCard';
import PaymentsOverview from 'containers/Admin/AdminPanel/Sections/Dashboard/Payments/PaymentsOverview/PaymentsOverview';
import NotificationCenter from 'containers/Admin/AdminPanel/Sections/Dashboard/NotificationCenter/NotificationCenter';
import AnnouncementComponent from 'containers/Admin/AdminPanel/Sections/Dashboard/Announcements/Announcement';

import icons from 'assets/svg/icons.svg';

import './Dashboard.scss';

// const onRefresh = () => async () => {
//     setAppointments(await Appointment.index());
// }

const Dashboard = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [announcement, setAnnouncement] = useState<string>('');
    const [hasError, setHasError] = useState<boolean>(false);

    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    const userInsights = useSelector(getUserInsights);
    const appointmentInsights = useSelector(getAppointmentInsights);
    const paymentInsights = useSelector(getPaymentInsights);

    const users = useSelector(getUsers);
    const userActions = useSelector(getUserActions);
    const payments = useSelector(getPayments);
    const announcements = useSelector(getAnnouncements);

    useEffect(() => {
        const setup = () => {
            setLoading(true);

            const adminState = Store.getState().admin;

            if (_.isEmpty(adminState.users)) {
                dispatchUsers();
            }

            if (_.isEmpty(adminState.calendars)) {
                dispatchCalendars();
            }

            if (_.isEmpty(adminState.appointments)) {
                dispatchAppointments();
            }

            if (_.isEmpty(adminState.lessons)) {
                dispatchLessons();
            }

            if (_.isEmpty(adminState.payments)) {
                dispatchPayments();
            }

            if (_.isEmpty(adminState.userActions)) {
                dispatchUserActions();
            }

            if (_.isEmpty(adminState.announcements)) {
                dispatchAnnouncements();
            }

            setLoading(false);
        }
        setup();
    }, []);

    const dispatchUsers = async () => {
        const request = await User.index();
        Store.dispatch(setUsers({ users: request.data }));
    }

    const dispatchCalendars = async () => {
        const request = await Calendar.index();
        Store.dispatch(setCalendars({ calendars: request.data }));
    }

    const dispatchAppointments = async () => {
        const request = await Appointment.index();
        Store.dispatch(setAppointments({ appointments: request.data }));
    }

    const dispatchLessons = async () => {
        const request = await Lesson.index();
        Store.dispatch(setLessons({ lessons: request.data }));
    }

    const dispatchPayments = async () => {
        const request = await Payment.index();
        Store.dispatch(setPayments({ payments: request.data }));
    }

    const dispatchUserActions = async () => {
        const request = await UserActions.index();
        Store.dispatch(setUserActions({ userActions: request.data }));
    }

    const dispatchAnnouncements = async () => {
        const request = await Announcement.index();
        Store.dispatch(setAnnouncements({ announcements: request.data }));
    }

    // -> ActivityCard component
    const getElapsedTime = (date: Date): string => {
        const currentDate = new Date();
        let diff = differenceInMinutes(currentDate, date);
        let indicator = `${_t.dashboard.minutes} ${_t.dashboard.ago}`.toLowerCase();

        if (diff > 60) {
            diff = differenceInHours(currentDate, date);
            indicator = `${_t.dashboard.hours} ${_t.dashboard.ago}`.toLowerCase();
        }
        else {
            return `${diff} ${indicator}`;
        }

        if (diff > 24) {
            diff = differenceInDays(currentDate, date);
            indicator = `${_t.dashboard.days} ${_t.dashboard.ago}`.toLowerCase();
        }
        else {
            return `${diff} ${indicator}`;
        }

        if (diff > 7) {
            diff = differenceInWeeks(currentDate, date);
            indicator = `${_t.dashboard.weeks} ${_t.dashboard.ago}`.toLowerCase();
        }
        else {
            return `${diff} ${indicator}`;
        }

        if (diff > 4) {
            diff = differenceInMonths(currentDate, date);
            indicator = `${_t.dashboard.months} ${_t.dashboard.ago}`.toLowerCase();
        }

        return `${diff} ${indicator}`;
    }

    const handleCreateAnnouncement = (e) => {
        setAnnouncement(e.target.value);
    }

    const handlePublishAnnouncement = async () => {
        setHasError(false);

        if (announcement) {
            await Announcement.store({ message: announcement });

            setAnnouncement('');

            dispatchAnnouncements();
        } else {
            setHasError(true);
        }
    }

    return (
        loading
            ?
            <div>
                <Spinner color={window.Branding.primaryColor} />
            </div>
            :
            <div>
                <div className='dashboard-row'>
                    <div className='dashboard-item'>
                        <InsightsCard insights={userInsights} model='users' icon='user' />
                    </div>

                    <div className='dashboard-item'>
                        <InsightsCard insights={appointmentInsights} model='appointments' icon='calendar' />
                    </div>

                    <div className='dashboard-item'>
                        <InsightsCard insights={paymentInsights} model='payments' icon='cash' />
                    </div>
                </div>

                <div className='dashboard-row'>

                    <div className='dashboard-item two'>
                        <NotificationCenter />
                    </div>

                    <div className='dashboard-item overlay-container one'>
                        <Card header={_t && _t.dashboard.announcements ? _t.dashboard.announcements : 'Announcements'} height={'643px'}>
                            <div className='headline'>{_t && _t.dashboard.announcement ? _t.dashboard.announcement : 'Announcement'}</div>
                            <textarea onChange={handleCreateAnnouncement} className={`text-input ${hasError ? 'warning' : ''}`} name='announcements-text-area' value={announcement}></textarea>
                            <button className='button small' type='submit' style={{ marginBottom: '5px' }} onClick={handlePublishAnnouncement}>{_t && _t.dashboard.publish ? _t.dashboard.publish : 'Publish'}</button>
                            {announcements ? _.orderBy(announcements, ['created_at'], ['desc']).slice(0, 5).map((announcement) => <AnnouncementComponent key={announcement.id} announcement={announcement} onDelete={async () => { await Announcement.delete(announcement.id); dispatchAnnouncements() }} />) : null}
                        </Card>
                    </div>

                </div>

                <div className='dashboard-row'>

                    {/* -> ActivityCard component */}
                    <div className='dashboard-item overlay-container one'>
                        <Card header={_t && _t.dashboard.activityLog ? _t.dashboard.activityLog : 'Activity log'}>
                            {userActions.slice(0, 9).map((userAction) => {
                                const type = _.split(userAction.model, '\\')[_.split(userAction.model, '\\').length - 1];

                                return (
                                    <div key={userAction.id}>
                                        <div className='horizontal-stack align-center space-between' style={{ paddingBottom: '10px' }}>
                                            <div style={{ flexBasis: '20%' }}>
                                                <div className='activity-icon center'>
                                                    <svg className='dashboard-icon small white'>
                                                        <use xlinkHref={`${icons}#${type === 'Appointment' || type === 'Calendar' ? 'calendar' : (type === 'User' ? 'user' : (type === 'Announcement' ? 'pencil' : 'cash'))}`} />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className='vertical-stack' style={{ flexBasis: '45%' }}>
                                                <div style={{ fontWeight: 'bold' }}>{`${_.find(users, (user) => user.id === userAction.user_id) ? _.find(users, (user) => user.id === userAction.user_id)!.name ?? '-' : userAction.user_id ?? '-'}`}</div>
                                                <div className='horizontal-stack'>
                                                    <div className='footnote'>{`${_t && _t.dashboard ? _t.dashboard[type.toLowerCase()] : 'type'} ${_t && _t.dashboard ? _t.dashboard[userAction.action.toLowerCase()] : 'action'}`.toLowerCase()}</div>
                                                </div>
                                            </div>
                                            <div className='footnote one justify-end' style={{ flexBasis: '35%' }}>
                                                {getElapsedTime(new Date(userAction.created_at))}
                                            </div>
                                        </div>
                                    </div>


                                )
                            })}
                        </Card>
                    </div>

                    <div className='dashboard-item two'>
                        <PaymentsOverview payments={payments} total={true} />
                    </div>

                </div>
            </div>
    );
}

export default Dashboard;