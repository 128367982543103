import React, { useState, useContext, useRef, useEffect } from 'react';

import _ from 'lodash';

import Store from 'core/store/store';

import User from 'core/apis/User';

import { LocalizationContext } from 'core/context/localizationContext';

import Checkbox from 'components/Input/Checkbox/Checkbox';
import LabelInput from 'components/Input/LabelInput/LabelInput';

const UserForm = ({ target, handleClose }) => {

    const refs = {
        name: useRef(null),
        email: useRef(null),
        address: useRef(null),
        phoneNumber: useRef(null),
        password: useRef(null),
        passwordConfirmation: useRef(null),
        register: useRef(null)
    }

    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        address: '',
        phone_number: '',
        password: '',
        password_confirmation: ''
    });
    const [validationErrors, setValidationErrors] = useState<{ name: string[], email: string[], address: string[], phone_number: string[], password: string[], register: string[] }>({ name: [], email: [], address: [], phone_number: [], password: [], register: [] });

    const [changePassword, setChangePassword] = useState<boolean>(target ? false : true);

    useEffect(() => {
        if (target) {
            setFormData({
                name: target.name,
                email: target.email,
                address: target.address,
                phone_number: target.phone_number,
                password: '',
                password_confirmation: ''
            });
        }
    }, [target]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleRegister = async () => {
        let errors = {};
        if (target) {
            await User.update(formData, target!.id).catch(error => {
                errors = error.response.data.errors
                const messages = _.assign(_.clone(validationErrors), error.response.data.errors);
                setValidationErrors(messages);
            });
        }
        else {
            await User.register(formData).catch(error => {
                errors = error.response.data.errors
                const messages = _.assign(_.clone(validationErrors), error.response.data.errors);
                setValidationErrors(messages);
            });
        }
        if (_.isEmpty(errors)) {
            handleClose(true);
        }
    }

    // const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, value: string | null = null) => {
    //     let key = e.key || e.keyCode;
    //     if (key === 'Enter' || key === 13) {
    //         if (value) {
    //             refs[value || 'null'].current.focus();
    //         }
    //         else {
    //             handleRegister();
    //         }
    //     }
    // }

    const handleCheckboxChange = (e: { target: { id: any; checked: any; }; }) => {
        setChangePassword(e.target.checked);

        if (!e.target.checked) {
            setFormData({ ...formData, password: '', password_confirmation: '' });
        }
    }

    return (
        <div>

            <label className='headline center'>{target ? (_t ? _t.authentication.editUser + ': ' : 'Edit user: ') + target.name : (_t ? _t.authentication.registerUser : 'New user')}</label>
            <div className='vertical-spacer' />

            <LabelInput
                required
                label={_t.authentication.name ?? 'Name'}
                name={'name'}
                handleChange={handleChange}
                defaultValue={target ? target.name : ''}
                errors={validationErrors.name}
            />

            <LabelInput
                required
                type={'email'}
                label={_t.authentication.email ?? 'Username'}
                name={'email'}
                handleChange={handleChange}
                defaultValue={target ? target.email : ''}
                errors={validationErrors.email}
            />

            <LabelInput
                required
                label={_t.authentication.address ?? 'Address'}
                name={'address'}
                handleChange={handleChange}
                defaultValue={target ? target.address : ''}
                errors={validationErrors.address}
            />

            <LabelInput
                required
                label={_t.authentication.phoneNumber ?? 'Phone number'}
                name={'phone_number'}
                handleChange={handleChange}
                defaultValue={target ? target.phone_number : ''}
                errors={validationErrors.phone_number}
            />

            {target
                ? <div className='justify-end'>
                    <Checkbox id={'changePassword'} label={(_t.authentication && _t.authentication.changePassword) ?? 'Change password'} onChange={handleCheckboxChange} type={'switch'} orientation={'right'} />
                </div>
                : null}

            <LabelInput
                required={changePassword ? true : false}
                disabled={!changePassword}
                type={'password'}
                label={_t.authentication.password ?? 'Password'}
                name={'password'}
                handleChange={handleChange}
                errors={changePassword ? validationErrors.password : []}
            />

            <LabelInput
                required={changePassword ? true : false}
                disabled={!changePassword}
                type={'password'}
                label={_t.authentication.passwordConfirmation ?? 'Confirm password'}
                name={'password_confirmation'}
                handleChange={handleChange}
                errors={changePassword ? validationErrors.password : []}
            />

            <div className='horizontal-stack justify-center'>
                <button style={{ width: '50%' }} type='submit' onClick={handleRegister} ref={refs.register} className='button'>{_t ? (target ? _t.authentication.edit : _t.authentication.register) : (target ? 'Edit' : 'Register')}</button>
            </div>


        </div>
    )
}

export default UserForm;