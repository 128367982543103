import Api from './Api';

const Admin = {

    confirmUser(id: number) {
        return Api().put('/users/confirm/' + id)
    },

    confirmUserWithSubscriptionInvitation(plan: { plan: string }, id: number) {
        return Api().put('/users/confirm/subscribe/' + id, plan)
    },

    blockUser(id: number) {
        return Api().put('/users/block/' + id)
    },

    unblockUser(id: number) {
        return Api().put('/users/unblock/' + id)
    },

    attachRole(role: { role: string }, id: number) {
        return Api().post('/users/role/attach/' + id, role)
    },

    detachRole(role: { role: string }, id: number) {
        return Api().post('/users/role/detach/' + id, role)
    },

};

export default Admin;