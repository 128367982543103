import Api from './Api';

const Announcement = {

    index(query: string = '') {
        return Api().get('/announcements' + query);
    },

    store(form) {
        return Api().post('/announcements/', form);
    },

    update(form, id: number) {
        return Api().put('/announcements/' + id, form);
    },

    delete(id: number) {
        return Api().delete('/announcements/' + id);
    },

};

export default Announcement;