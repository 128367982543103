import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Store from 'core/store/store';
import { register } from 'core/store/authentication/action';

import Card from 'components/Card/Card';
// import SubscriptionCard from 'containers/Authentication/RegisterForm/SubscriptionCard/SubscriptionCard';

import { LocalizationContext } from 'core/context/localizationContext';

const RegisterForm = (props) => {

    // const previousLoggedInState = Store.getState().authentication.userLoggedIn;

    const refs = {
        name: useRef(null),
        email: useRef(null),
        address: useRef(null),
        phoneNumber: useRef(null),
        password: useRef(null),
        passwordConfirmation: useRef(null),
        register: useRef(null)
    }

    const history = useHistory();
    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    const [formData, setFormData] = useState({ name: '', email: '', address: '', phone_number: '', password: '', password_confirmation: '', plan: '' });
    const [validationErrors, setValidationErrors] = useState<{ name: string[], email: string[], address: string[], phone_number: string[], password: string[], register: string[], plan: string[] }>({ name: [], email: [], address: [], phone_number: [], password: [], register: [], plan: [] });
    // const [selectedPlan, setSelectedPlan] = useState<string>('');
    // const [showSubscriptions, setShowSubscriptions] = useState<boolean>(true);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleRegister = async () => {
        await Store.dispatch(register({ credentials: formData })).catch(error => {
            if (error.response) {
                setValidationErrors(error.response.data.errors);
            }
            else {
                setValidationErrors({ name: [], email: [], address: [], phone_number: [], password: [], register: ['connectionRefused'], plan: [] })
            }
        });

        if (Store.getState().authentication.userLoggedIn && !Store.getState().authentication.requestPending) {
            history.push({
                pathname: props.location.state ? props.location.state.from.pathname : '/home',
            });
        }
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, value: string | null = null) => {
        let key = e.key || e.keyCode;
        if (key === 'Enter' || key === 13) {
            if (value) {
                refs[value || 'null'].current.focus();
            }
            else {
                handleRegister();
            }
        }
    }

    return (
        <div className='form-container center'>
                <Card>

                    <h2 className='center'>
                        TIME STABLES
                    </h2>
                    <div className='center'>
                        <div className='headline' style={{ paddingBottom: '3rem' }}>{_t ? _t.authentication.register : 'Register'}</div>
                    </div>

                    <label className='label'>{_t ? _t.authentication.name : 'Name'}</label>
                    <input
                        type='email'
                        className='input'
                        id='name'
                        name='name'
                        ref={refs.name}
                        onChange={handleChange}
                        onKeyUp={(e) => handleKeyUp(e, 'email')}
                    />
                    <div className='validation-text danger'>
                        {validationErrors.hasOwnProperty('name') && validationErrors.name.length ? validationErrors.name.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                    </div>

                    <label className='label'>{_t ? _t.authentication.email : 'Username'}</label>
                    <input
                        type='email'
                        v-model='form.email'
                        className='input'
                        id='email'
                        name='email'
                        ref={refs.email}
                        onChange={handleChange}
                        onKeyUp={(e) => handleKeyUp(e, 'address')}
                    />
                    <div className='validation-text danger'>
                        {validationErrors.hasOwnProperty('email') && validationErrors.email.length ? validationErrors.email.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                    </div>

                    <label className='label'>{_t ? _t.authentication.address : 'Address'}</label>
                    <input
                        type='email'
                        v-model='form.email'
                        className='input'
                        id='address'
                        name='address'
                        ref={refs.address}
                        onChange={handleChange}
                        onKeyUp={(e) => handleKeyUp(e, 'phoneNumber')}
                    />
                    <div className='validation-text danger'>
                        {validationErrors.hasOwnProperty('address') && validationErrors.address.length ? validationErrors.address.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                    </div>

                    <label className='label'>{_t ? _t.authentication.phoneNumber : 'Phone number'}</label>
                    <input
                        type='email'
                        v-model='form.email'
                        className='input'
                        id='phone_number'
                        name='phone_number'
                        ref={refs.phoneNumber}
                        onChange={handleChange}
                        onKeyUp={(e) => handleKeyUp(e, 'password')}
                    />
                    <div className='validation-text danger'>
                        {validationErrors.hasOwnProperty('phone_number') && validationErrors.phone_number.length ? validationErrors.phone_number.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                    </div>

                    <label className='label'>{_t ? _t.authentication.password : 'Password'}</label>
                    <input
                        type='password'
                        v-model='form.password'
                        className='input'
                        id='password'
                        name='password'
                        ref={refs.password}
                        onChange={handleChange}
                        onKeyUp={(e) => handleKeyUp(e, 'passwordConfirmation')}
                    />
                    <div className='validation-text danger'>
                        {validationErrors.hasOwnProperty('password') && validationErrors.password.length ? validationErrors.password.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                    </div>

                    <label className='label'>{_t ? _t.authentication.passwordConfirmation : 'Confirm password'}</label>
                    <input
                        type='password'
                        v-model='form.passwordConfirmation'
                        className='input'
                        id='passwordConfirmation'
                        name='password_confirmation'
                        ref={refs.passwordConfirmation}
                        onChange={handleChange}
                        onKeyUp={(e) => handleKeyUp(e)}
                    />
                    <div className='validation-text danger'>
                        {validationErrors.hasOwnProperty('password') && validationErrors.password.length ? validationErrors.password.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                    </div>

                    <button type='submit' onClick={handleRegister} ref={refs.register} className='button'>{_t ? _t.authentication.register : 'Register'}</button>
                    <div className='validation-text danger center'>
                        {validationErrors.hasOwnProperty('register') && validationErrors.register.length ? validationErrors.register.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                    </div>

                </Card>
                {/* } */}

        </div>
    )
}

export default RegisterForm;