import React, { FC } from 'react';

import './ValidationError.scss';

export interface ValidationErrorProps {
    message: string
}

const ValidationError: FC<ValidationErrorProps> = ({ message }) => {
    return (
        <div className='validation-error'>
            {message}
        </div>
    );
}

export default ValidationError;
