import React, { createRef, FC, useEffect, useState } from 'react';
import SearchInput from '../SearchInput/SearchInput';

import './TokenInput.scss';

export interface TokenInputProps {
    label?: string
    required?: boolean
    data: {}[]
    keyProperty: string
    valueProperty: string
    handleSelect: (key: any) => void
}

const TokenInput: FC<TokenInputProps> = ({ label, required, data, keyProperty, valueProperty, handleSelect, children }) => {
    const node = createRef<HTMLDivElement>();
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleClick = (e: { target: any; }) => {
        const current = node.current;
        if (current && current!.contains(e.target)) {
            return;
        }
        setSearchQuery('');
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    });

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    }

    const handleClear = () => {
        setSearchQuery('');
    }

    const getFilteredData = () => {
        return data.filter((datum) => Object.keys(datum).some(d => datum[d] ? datum[d].toString().toLowerCase().includes(searchQuery.toLowerCase()) : false));
    }

    return (
        <div>
            {label ? <label className='input-label'>{`${label} ${required ? '*' : ''}`}</label> : null}
            <div ref={node} className='search-container' style={{ position: 'relative' }}>
                <SearchInput
                    placeholder={'Zoek een gebruiker...'}
                    value={searchQuery}
                    handleSearch={handleSearch}
                    handleClear={handleClear}
                />

                <ul className={'select-input open'}>
                    {searchQuery
                        ? getFilteredData().map((datum) =>
                            <div key={datum[keyProperty]} onClick={() => { handleSelect(datum[keyProperty]); handleClear() }} className='select-item'>{datum[valueProperty]}</div>
                        )
                        : null}
                </ul>
            </div>
            {children}
        </div>
    );
}

export default TokenInput;
