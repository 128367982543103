import React, { FC, MutableRefObject, useState } from 'react';

import Confirmation from '../Confirmation';
import Spinner from '../../Spinner/Spinner';
import ValidationError from '../../Input/ValidationError/ValidationError';

import './Button.scss';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    label: string
    loading?: boolean
    type?: 'submit' | 'button' | 'reset'
    buttonRef?: MutableRefObject<HTMLButtonElement | null>
    className?: string
    errors?: string[]
    requiresConfirmation?: boolean
    confirmationMessage?: string
    confirmationLabel?: string
    dangerous?: boolean
    disabled?: boolean
}

const Button: FC<ButtonProps> = ({ label, errors, confirmationMessage, confirmationLabel, dangerous, requiresConfirmation, loading = false, type = 'submit', buttonRef = null, className = null, onClick = () => void (0), disabled = false, ...buttonProps }) => {
    const [confirmation, setConfirmation] = useState<boolean>(false);

    return (
        <div>
            <button
                type={type}
                onClick={requiresConfirmation ? () => setConfirmation(true) : (e) => onClick(e)}
                ref={buttonRef}
                className={className ?? 'button'}
                disabled={disabled}
                {...buttonProps}
            >
                <div className='button-label'>
                    {loading ? <Spinner /> : label}
                </div>
            </button>
            {errors
                ? (errors.length
                    ? errors.map((error: string) => <ValidationError key={error} message={error} />)
                    : <div style={{ marginBottom: '1rem' }}><span>&nbsp;&nbsp;</span></div>)
                : null
            }

            {requiresConfirmation ?
                <Confirmation
                    show={confirmation}
                    close={() => setConfirmation(false)}
                    message={confirmationMessage}
                    confirmLabel={confirmationLabel ?? label}
                    onConfirm={onClick}
                    dangerous={dangerous}
                />
                : null}

        </div>
    );
}

export default Button;
