import React, { FC } from 'react';

import Card from 'components/Card/Card';

import './SubscriptionCard.scss';

export interface SubscriptionCardProps {
    title: string
    price: string
    selected: boolean
    features: string[]
}

const SubscriptionCard: FC<SubscriptionCardProps> = ({ title, price, features, selected }) => {
    return (
        <div className={`subscription-container ${selected ? 'selected' : ''}`}>
            <Card height={'430px'}>
                <div className='headline center'>{title}</div>

                <div className='subscription-plan-price center'>{price}</div>
                <div className='subscription-plan-subheader center'>{'per maand'}</div>
                <div className='subscription-plan-body center'>{'schofhoogte'}<br />{'> 1,35m'}<br />{'+ €100'}</div>

                <hr className='solid' />

                {features.map((feature) => {
                    return (
                        <div key={feature} className='feature'>
                            <div className='feature-indicator' />
                            <div className='horizontal-divider' />
                            {feature}
                        </div>
                    );
                })}
            </Card>
        </div>
    );
}

export default SubscriptionCard;
