import { authenticationReducer } from './authentication/reducer';
import { baseReducer } from './base/reducer';
import { adminReducer } from './admin/reducer';
import { userReducer } from './user/reducer';
import { combineReducers } from 'redux';
import Store from './store';

const rootReducer = combineReducers({
    base: baseReducer,
    authentication: authenticationReducer,
    admin: adminReducer,
    user: userReducer
})

Store.register(rootReducer);

export type RootState = ReturnType<typeof rootReducer>