import React, { FC } from 'react';

import { AppointmentType } from '../Interfaces/Interfaces';
import { addDays, getTime, isSameDay, startOfWeek } from 'date-fns';

import Day from 'containers/Admin/AdminPanel/Calendar/2.0/Day/Day';

import './Week.scss';

export interface WeekProps {
    events: AppointmentType[]
    startDate?: Date
    range?: number
}

const Week: FC<WeekProps> = ({ events, startDate = new Date(), range = 7 }) => {

    const getWeekDayDates = () => {
        const start = range === 7 ? startOfWeek(startDate, { weekStartsOn: 1 }) : startDate;
        const days = Array.from(Array(range).keys()).map((i: number) => addDays(start, i));
        return days;
    }

    return (
        <div className='week-row'>
            {getWeekDayDates().map((date: Date) =>
                <Day
                    key={getTime(date)}
                    date={date}
                    events={events.filter((event) => isSameDay(date, new Date(event.start_time)))}
                    consecutive={range}
                />
            )}
        </div>
    )
}

export default Week;
