import React, { FC, MouseEvent, MouseEventHandler } from 'react';
import Modal from '../../Modal/Modal';
import translations from '../Localization/translations';

import './Confirmation.scss';

interface ConfirmationProps {
    show: boolean
    message?: string
    confirmLabel?: string
    cancelLabel?: string
    dangerous?: boolean
    onConfirm: MouseEventHandler<HTMLButtonElement> | undefined
    onCancel?: MouseEventHandler<HTMLButtonElement> | undefined
    close: () => void
}

const Confirmation: FC<ConfirmationProps> = ({ show, message, confirmLabel, cancelLabel, dangerous, onConfirm, onCancel, close }) => {
    const defaultLanguage = navigator.language.trim().split(/-|_/)[0] as string;

    const confirm = (e: MouseEvent<HTMLButtonElement>) => {
        if (onConfirm) {
            onConfirm(e);
        }
        close();
    }

    return (
        <Modal show={show} handleClose={close} closeButtonEnabled>

            <div className='confirmation-message'>
                {message ?? translations[defaultLanguage].confirmMessage}
            </div>

            <div className='confirmation-button-row'>
                <div className='confirmation-button'>
                    <button
                        type='submit'
                        onClick={confirm}
                        className={`button ${dangerous ? 'danger' : ''}`}
                    >
                        <div className='button-label'>
                            {confirmLabel ?? translations[defaultLanguage].confirm}
                        </div>
                    </button>
                </div>
                <div className='confirmation-button'>
                    <button
                        type='submit'
                        onClick={onCancel ?? close}
                        className='button'
                    >
                        <div className='button-label'>
                            {cancelLabel ?? translations[defaultLanguage].cancel}
                        </div>
                    </button>
                </div>
            </div>

        </Modal>
    );
}

export default Confirmation;
