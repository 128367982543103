import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';

import { getLanguage, getLocales } from 'core/store/base/selectors';
import { getCalendars, getUsers } from 'core/store/admin/selectors';
import { getUser } from 'core/store/user/selectors';

import format from 'date-fns/format';

import Modal from 'components/Modal/Modal';
import Tooltip from 'components/Tooltip/Tooltip';

import { LocalizationContext } from 'core/context/localizationContext';
import { CalendarContext } from 'containers/Admin/AdminPanel/Calendar/2.0/Context/CalendarContext';

import icons from 'assets/svg/icons.svg';

export interface WindowProps {
    show: boolean,
    onClose: any
    event: any
}

const Window: FC<WindowProps> = ({ show, onClose, event }) => {
    const language = useSelector(getLanguage);
    const locales = useSelector(getLocales);

    const localization = useContext(LocalizationContext);
    const _t = localization![language] ?? localization!['en'];

    const loggedInUser = useSelector(getUser);
    const users = useSelector(getUsers);
    const user = loggedInUser.id === event.user_id ? loggedInUser : users.find((user) => user.id === event.user_id);

    const calendars = useSelector(getCalendars);
    const calendar = calendars.find((calendar) => calendar.id === event.calendar_id);
    const type = calendar ? calendar.title : '';

    const calendarActions = useContext(CalendarContext);

    return (
        <Modal key={event.id} show={show} handleClose={onClose}>

            <div className='space-between'>

                <div className='window-header'>
                    <div
                        className='window-type'
                        style={{
                            backgroundColor: calendar ? calendar.color : window.Branding.primaryColor
                        }}
                    />
                    <div className='horizontal-divider' />
                    <div className='headline'>{type}</div>
                </div>

                <div className='window-header'>

                    {calendarActions?.onEdit ? <Tooltip
                        body={
                            <svg onClick={() => calendarActions?.onEdit ? calendarActions?.onEdit(event) : () => void (0)} className='window-icon'>
                                <use xlinkHref={`${icons}#pencil`} />
                            </svg>
                        }
                        hoverText={_t && _t.calendar.submitEdit ? _t.calendar.submitEdit : 'Edit'}
                    /> : null}

                    {calendarActions?.onDelete ? <Tooltip
                        body={
                            <svg onClick={() => calendarActions?.onDelete ? calendarActions?.onDelete(event) : () => void (0)} className='window-icon'>
                                <use xlinkHref={`${icons}#trash-can`} />
                            </svg>
                        }
                        hoverText={_t && _t.calendar.delete ? _t.calendar.delete : 'Delete'}
                    /> : null}
                </div>

            </div>

            <div>
                <p>{`${format(event.start_time, 'iiii dd MMMM, HH:mm', { locale: locales[language] })}-${format(event.end_time, 'HH:mm', { locale: locales[language] })}`}</p>
                <p>{event.comments ?? ''}</p>
                <div className='footnote'>{user ? user.name : ''}</div>
                <a className='footnote underline' href={`mailto:${user ? user.email : ''}`}>{user ? user.email : ''}</a>

                {calendarActions?.onAttach ? <div className='justify-end'><button className='button' type='submit' style={{ width: '50%' }} onClick={() => calendarActions?.onAttach ? calendarActions?.onAttach(event) : void (0)}>{_t && _t.calendar.signUp ? _t.calendar.signUp : 'Sign up'}</button></div> : null}
            </div>


        </Modal>
    )
}

export default Window;
