import React, { useState } from 'react'

import './Checkbox.scss';

const Checkbox = ({ id, label = '', hasError = false, type = 'default', initialState = false, orientation = 'left', ...inputProps }) => {
    const [checked, setChecked] = useState<boolean>(initialState);

    const handleClick = () => {
        setChecked(!checked);
    }

    return (
        <div className={'m-checkbox' + (type === 'switch' ? ' m-checkbox--switch' : '') + (hasError ? ' m-checkbox--has-error' : '')} onClick={(e) => e.stopPropagation()}>
            {orientation === 'right' ? <label className={'m-checkbox__label' + (type === 'switch' ? ' m-checkbox--switch__label' : '')} htmlFor={id}>{label}</label> : null}
            <input
                type='checkbox'
                className={'m-checkbox__input' + (type === 'switch' ? ' m-checkbox--switch__input' : '') + (hasError ? ' m-checkbox--has-error__input' : '')}
                id={id}
                checked={checked}
                onClick={handleClick}
                {...inputProps}
            />
            {orientation === 'left' ? <label className={'m-checkbox__label' + (type === 'switch' ? ' m-checkbox--switch__label' : '')} htmlFor={id}>{label}</label> : null}
        </div>
    );
}

export default Checkbox;
