import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { getLanguage, getLocales } from 'core/store/base/selectors';

import Card from 'components/Card/Card';
import Svg from 'components/Icons/Svg/Svg';
import { format } from 'date-fns';

export interface AnnouncementProps {
    announcement: AnnouncementType
    onDelete?: () => void
}

const Announcement: FC<AnnouncementProps> = ({ announcement, onDelete }) => {
    const language = useSelector(getLanguage);
    const locales = useSelector(getLocales);

    return (
        <div style={{ marginBottom: '10px' }}>
            <Card>
                <div className='announcement'>
                    <div style={{ flexBasis: '90%' }}>
                        <div className='announcement-message'>{announcement.message}</div>
                        <div className='footnote'>{format(new Date(announcement.created_at), 'dd MMMM yyyy, H:mm', { locale: locales[language] })}</div>
                    </div>
                    {onDelete
                        ? <div style={{ flexBasis: '10%' }} onClick={onDelete}>
                            <Svg icon={'trash-can'} fill={'black'} height={'30px'} className={'is-clickable'} />
                        </div>
                        : null}
                </div>
            </Card>
        </div>
    );
}

export default Announcement;
