import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunkMiddleware from 'redux-thunk'

let _reduxStore: any = null;
let _reduxPersistor: any = null;

// eslint-disable-next-line
declare global {
    // eslint-disable-next-line
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

class Store {

    static register(store: any) {

        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

        const persistConfig = { 
            key: 'root',
            storage,
            blacklist: ['admin', 'user'],
            stateReconciler: autoMergeLevel2
        }

        const persistedReducer = persistReducer(persistConfig, store);

        const reduxStore = createStore(
            persistedReducer,
            undefined,
            composeEnhancers(
                applyMiddleware(
                    thunkMiddleware,
                ),
            )
        )
        _reduxStore = reduxStore;

        const reduxPersistor = persistStore(reduxStore);
        _reduxPersistor = reduxPersistor;
    }

    static dispatch(...args: any) {
        if (_reduxStore === null) {
            throw new Error('Redux store not initialized!');
        }

        return _reduxStore.dispatch(...args);
    }

    static getState(...args: []) {
        return _reduxStore.getState(...args);
    }

    static getStore() {
        return _reduxStore;
    }

    static getPersistor() {
        return _reduxPersistor;
    }

}

export default Store;