import React, { FC, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import Store from 'core/store/store';
import { setUsers } from 'core/store/admin/action';
import { getUsers } from 'core/store/admin/selectors';

import User from 'core/apis/User';

import { LocalizationContext } from 'core/context/localizationContext';

import Card from 'components/Card/Card';

import UserComponent from 'containers/Admin/AdminPanel/Sections/Users/UserManagement/UserComponent';

const UserManagement: FC = () => {
    const users = useSelector(getUsers);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [activeSearchQuery, setActiveSearchQuery] = useState<string>('');

    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];


    useEffect(() => {
        const setup = () => {
            const adminState = Store.getState().admin;

            if (_.isEmpty(adminState.users)) {
                dispatchUsers();
            }

            setInitialized(true);
        }

        if (!initialized) {
            setup();
        }
    }, [users, initialized]);

    const dispatchUsers = async () => {
        const request = await User.index();
        Store.dispatch(setUsers({ users: request.data }));
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setActiveSearchQuery(e.target.value);
    }

    return (
        <Card>
            <div className='headline'>{_t.dashboard && _t.dashboard.accessControl ? _t.dashboard.accessControl : 'Access control'}</div>
            <input
                type='text'
                className='search-input-table'
                id='search'
                name='search'
                placeholder='Zoeken...'
                autoComplete='off'
                onChange={handleSearch}
            />
            {users.filter((user) => Object.keys(user).some(k => user[k] ? user[k].toString().toLowerCase().includes(activeSearchQuery.toLowerCase()) : false)).map((user) => {
                return (
                    <div key={user.id} className='user-container'>
                        <Card>
                            <UserComponent user={user} />
                        </Card>
                    </div>)
            })}
        </Card>
    )
}

export default UserManagement;
