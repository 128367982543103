import React, { FC, useState, useContext } from 'react';

import Admin from 'core/apis/Admin';
import User from 'core/apis/User';
import Subscription from 'core/apis/Subscription';

import Store from 'core/store/store';
import { setUsers } from 'core/store/admin/action';

import Collapse from 'components/Animation/Collapse/Collapse';
import Checkbox from 'components/Input/Checkbox/Checkbox';

import './UserManagement.scss';
import Modal from 'components/Modal/Modal';
import ConfirmForm from 'containers/Admin/AdminPanel/ModelForm/ConfirmForm';
import { format } from 'date-fns';
import UserRow from './UserRow';

import { LocalizationContext } from 'core/context/localizationContext';
import SelectInput from 'components/Input/SelectInput/SelectInput';

export interface UserProps {
    user: UserType
}

const UserComponent: FC<UserProps> = ({ user }) => {
    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    const admin = user.roles.some(role => role.name === 'admin');
    const confirmed = user.confirmed;
    const subscription = user.subscriptions.length ? user.subscriptions[user.subscriptions.length - 1] : null;
    const canceled = subscription ? subscription.ends_at !== null : null;

    const [showModal, setShowModal] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [handleConfirm, setHandleConfirm] = useState<any>();

    const [selectedPlan, setSelectedPlan] = useState<string>(subscription ? subscription.plan : '');

    const dispatchUsers = async () => {
        const request = await User.index();
        Store.dispatch(setUsers({ users: request.data }));
    }

    const handleSelect = (e) => {
        setSelectedPlan(e.target.value);
    }

    const prepareModal = (title: string, message: string, confirmAction: any) => {
        setTitle(title);
        setMessage(message);
        setHandleConfirm(confirmAction);
        setShowModal(true);
    }

    const handleSetUserStatus = () => async () => {
        if (user.id === Store.getState().authentication.userId) {
            alert('Deze actie kan niet uitgevoerd worden op de ingelogde gebruiker!')
            return;
        }

        if (user.blocked) {
            await Admin.unblockUser(user.id);
        } else {
            await Admin.blockUser(user.id);
        }
        dispatchUsers();
    }

    const handleUserRole = () => async () => {
        if (user.id === Store.getState().authentication.userId) {
            alert('Deze actie kan niet uitgevoerd worden op de ingelogde gebruiker!')
            return;
        }

        if (admin) {
            await Admin.detachRole({ role: 'admin' }, user.id);
        } else {
            await Admin.attachRole({ role: 'admin' }, user.id);
        }
        dispatchUsers();
    }

    const confirmUser = () => async () => {
        await Admin.confirmUser(user.id);
        dispatchUsers();
    }

    const createSubscription = () => async () => {
        const request = await Subscription.trigger({ plan: selectedPlan }, user.id);
        if (request.data.succes) {
            console.log('E-mail uitnodiging succesvol verstuurd!');
            // do something to validate
        }
    }

    const swapSubscription = () => async () => {
        await Subscription.swap({ plan: selectedPlan }, user.id);
        dispatchUsers();
    }

    const resumeSubscription = () => async () => {
        await Subscription.resume({ plan: subscription!.plan }, user.id);
        dispatchUsers();
    }

    const cancelSubscription = () => async () => {
        await Subscription.cancel({ plan: subscription!.plan }, user.id);
        dispatchUsers();
    }

    const renderAdminStatus = () => (
        <div></div>
    );

    const renderCanceledStatus = () => (
        <div>
            {subscription && subscription.ends_at ? <div className='warning-text'>{`${_t && _t.subscription ? _t.subscription.canceledMessage : 'Canceled, active until'} ${format(new Date(subscription.ends_at), 'dd-MM-yyyy')}.`}</div> : null}
            <div className='warning-text'>{_t && _t.subscription ? _t.subscription.resumeMessage : ''}</div>
            <button className='button' type='submit' style={{ width: 'fit-content' }} onClick={() => prepareModal(_t && _t.subscription ? _t.subscription.resumeTitle : 'Resume subscription', _t && _t.subscription ? _t.subscription.resumeConfirmMessage : 'Sure?', resumeSubscription)}>{_t && _t.subscription ? _t.subscription.resume : 'Resume'}</button>
        </div>
    );

    const renderSubscribedStatus = () => (
        <div>
            {subscription ? <div>{`${_t && _t.subscription ? _t.subscription.activeSince : 'Active since'} ${format(new Date(subscription.created_at), 'dd-MM-yyyy')}`}</div> : null}
            <p className=''>{_t && _t.subscription ? _t.subscription.changeTitle : 'Edit'}</p>
            <SelectInput
                value={_t.subscription[selectedPlan] ?? ''}
                placeholder={_t.appointment.selectOption ?? '-- Select an option --'}
            >
                {['bronze', 'silver', 'gold'].map((plan) => <div key={plan} onClick={() => setSelectedPlan(plan)}>{_t.subscription[plan] ?? plan}</div>)}
            </SelectInput>
            <p><button className='button' type='submit' style={{ width: 'fit-content' }} onClick={() => prepareModal(_t && _t.subscription ? _t.subscription.changeTitle : 'Change subscription', _t && _t.subscription ? _t.subscription.changeMessage : 'Sure?', swapSubscription)}>{_t && _t.subscription ? _t.subscription.change : 'Edit'}</button></p>
            <p className='warning-text' onClick={() => prepareModal(_t && _t.subscription ? _t.subscription.cancelTitle : 'Cancel subscription', _t && _t.subscription ? _t.subscription.cancelMessage : 'Sure?', cancelSubscription)}>{_t && _t.subscription ? `${_t.subscription.cancel} ${_t.subscription.subscription.toLowerCase()}` : 'Cancel subscription'}</p>
        </div>
    );

    const renderNewUserStatus = () => (
        <div>
            <div>{_t && _t.subscription ? _t.subscription.noSubscription : 'No active subscriptions'}</div>
            <p className=''>{_t && _t.subscription ? _t.subscription.signUpTitle : 'Sign up'}</p>
            <select id='type' name='type' className='input' onChange={handleSelect} defaultValue={subscription ? subscription.plan : 'placeholder'}>
                <option value='placeholder' disabled>{_t ? _t.appointment.selectOption : '-- Select an option --'}</option>
                <option value='bronze'>{_t && _t.subscription ? _t.subscription.bronze : 'Bronze'}</option>
                <option value='silver'>{_t && _t.subscription ? _t.subscription.silver : 'Silver'}</option>
                <option value='gold'>{_t && _t.subscription ? _t.subscription.gold : 'Gold'}</option>
            </select>
            <p><button className='button' type='submit' style={{ width: 'fit-content' }} onClick={() => prepareModal(_t && _t.subscription ? _t.subscription.signUpTitle : 'Sign up', _t && _t.subscription ? _t.subscription.signUp : 'Sign up', createSubscription)}>{_t && _t.subscription ? _t.subscription.signUp : 'Sign up'}</button></p>
        </div>
    );

    const renderAccessControl = () => (
        <div className='user-body'>
            <p className='headline'>{_t && _t.subscription ? _t.subscription.access : 'Access'}</p>

            <div className='section'>
                <Checkbox
                    id={'active'}
                    label={!user.blocked ? _t && _t.subscription ? _t.subscription.active : 'Active' : _t && _t.subscription ? _t.subscription.inactive : 'Inactive'}
                    type='switch'
                    onChange={() => prepareModal((user.blocked ? _t && _t.subscription ? _t.subscription.activateTitle : 'Activate user' : _t && _t.subscription ? _t.subscription.blockTitle : 'Block user'), _t && _t.subscription ? _t.subscription.activateMessage : 'Change user rights?', handleSetUserStatus)}
                    checked={!user.blocked}
                />
                <Checkbox
                    id={'admin'}
                    label={admin ? _t && _t.subscription ? _t.subscription.admin : 'Admin' : _t && _t.subscription ? _t.subscription.regularUser : 'Regular user'}
                    type='switch'
                    onChange={() => prepareModal(_t && _t.subscription ? _t.subscription.changeRightsTitle : 'Change user rights?', admin ? (_t && _t.subscription ? _t.subscription.removeAdmin : 'Remove admin rights?') : (_t && _t.subscription ? _t.subscription.addAdmin : 'Give admin rights?'), handleUserRole)}
                    checked={admin}
                />
            </div>
        </div>
    );

    return (
        <div className='user-card-container'>
            <Collapse
                showLabel={<UserRow plan={subscription ? subscription.plan : null} name={user.name} status={admin ? 'Admin' : (canceled ? (_t && _t.subscription ? _t.subscription.canceled : 'Canceled') : '')} open={false} />}
                hideLabel={<UserRow plan={subscription ? subscription.plan : null} name={user.name} status={admin ? 'Admin' : (canceled ? (_t && _t.subscription ? _t.subscription.canceled : 'Canceled') : '')} open={true} />}
            >
                <div className='user-row'>
                    <div className='user-body'>
                        <p className='headline'>{_t && _t.subscription ? _t.subscription.status : 'Status'}</p>

                        <div className='section'>
                            <div className=''><span className='attribute'>{_t && _t.subscription ? _t.subscription.current : 'Current subscription'}</span>{subscription ? `: ${_t && _t.subscription ? _t.subscription[subscription.plan] : ''}` : ': n.v.t.'}</div>
                            {confirmed
                                ? (admin
                                    ? renderAdminStatus()
                                    : (subscription
                                        ? (subscription.ends_at
                                            ? renderCanceledStatus()
                                            : renderSubscribedStatus())
                                        : renderNewUserStatus()))
                                : <div>
                                    <div>{_t && _t.subscription ? _t.subscription.confirmUser : 'User requires confirmation'}</div>
                                    <p><button className='button' type='submit' style={{ width: 'fit-content' }} onClick={() => prepareModal(_t && _t.subscription ? _t.subscription.confirmTitle : 'Confirm user', _t && _t.subscription ? _t.subscription.confirmMessage : 'Sure?', confirmUser)}>{_t && _t.subscription ? _t.subscription.confirm : 'Confirm'}</button></p>
                                </div>}
                        </div>
                    </div>

                    {renderAccessControl()}
                </div>
            </Collapse>

            <Modal show={showModal} handleClose={() => setShowModal(false)}>
                <ConfirmForm title={title} message={message} action='EDIT' handleConfirm={handleConfirm} handleClose={() => setShowModal(false)} />
            </Modal>
        </div>
    )
}

export default UserComponent;
