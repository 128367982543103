import React from 'react';

export const LocalizationContext = React.createContext<ILocalization | null>(null);

const LocalizationProvider = ({ localization, children }: { localization: ILocalization, children: React.ReactNode }) => {
    return (
        <LocalizationContext.Provider
            value={localization}
        >
            {children}
        </LocalizationContext.Provider>
    );
};

export default LocalizationProvider;