import React from 'react';

import './Tooltip.scss';

const Tooltip = ({ body, hoverText}) => {

    return (

        <div className='tooltip'>
            {body}
            <span className='tooltiptext'>{hoverText}</span>
        </div>
    );

}

export default Tooltip;