import React, { FC } from 'react';

import icons from 'assets/svg/icons.svg';

export interface UserRowProps {
    plan: string | null;
    name: string
    status: string | null
    open: boolean
}

const UserRow: FC<UserRowProps> = ({ plan, name, status, open }) => {
    return (
        <div className='user-row space-between'>
            <div className='align-center'>
                <div
                    className='subscription-plan-indicator'
                    style={{
                        backgroundColor: plan ? (plan === 'bronze' ? '#cd7f32' : plan) : window.Branding.primaryColor
                    }}
                />
                <div className='horizontal-divider' />
                <span>{name}</span>
            </div>

            <div className='user-row'>
                {status ? <div className='user-row-status'>{status}</div> : null}
                <div className='horizontal-divider' />
                <svg className='sidebar-icon'>
                    <use xlinkHref={`${icons}#chevron-${open ? 'down' : 'right'}-small`} />
                </svg>
            </div>
        </div>
    )
}

export default UserRow;
