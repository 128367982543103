import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { startOfHour, addHours } from 'date-fns';

import Store from 'core/store/store';
import { setAppointments, setCalendars } from 'core/store/admin/action';
import { getAppointments } from 'core/store/admin/selectors';

import Appointment from 'core/apis/Appointment';
import Calendar from 'core/apis/Calendar';

import Modal from 'components/Modal/Modal';
import Card from 'components/Card/Card';
import AppointmentForm from 'containers/Admin/AdminPanel/ModelForm/AppointmentForm';
import ConfirmForm from 'containers/Admin/AdminPanel/ModelForm/ConfirmForm';

import CrudTable from 'containers/Admin/AdminPanel/CrudTable/CrudTable';

interface AppointmentType {
    id: number;
    user_id: number;
    calendar_id: number;
    confirmed: boolean;
    type: string;
    comments: string;
    start_time: number;
    end_time: number;
    created_at: string;
    updated_at: string;
}

const _hiddenAttributes = [
    'updated_at'
];

const AppointmentIndex = () => {

    const appointments = useSelector(getAppointments);

    const [initialized, setInitialized] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<string>('');
    const [target, setTarget] = useState<AppointmentType | null>();

    useEffect(() => {
        const setup = () => {
            const adminState = Store.getState().admin;

            if (_.isEmpty(adminState.appointments)) {
                dispatchAppointments();
            }

            if (_.isEmpty(adminState.calendars)) {
                dispatchCalendars();
            }

            setInitialized(true);
        }

        if (!initialized) {
            setup();
        }
    }, [appointments, initialized]);

    const dispatchAppointments = async () => {
        const request = await Appointment.index();
        Store.dispatch(setAppointments({ appointments: request.data }));
    }

    const dispatchCalendars = async () => {
        const request = await Calendar.index();
        Store.dispatch(setCalendars({ calendars: request.data }));
    }

    const onCreate = () => {
        setShowModal('AppointmentForm');
    }

    const onEdit = (target: AppointmentType) => {
        setTarget(target);
        setShowModal('AppointmentForm');
    }

    const onDelete = (target: AppointmentType) => {
        setTarget(target);
        setShowModal('ConfirmForm');
    }

    const handleClose = () => {
        setShowModal('');
        setTarget(null);
    }

    return (
        <div>
            <Card>
                <CrudTable key={'appointments'} model={_.map(appointments, appointment => _.omit(appointment, _hiddenAttributes))} onEditAction={onEdit} onDeleteAction={onDelete} onCreateAction={onCreate} />
            </Card>

            <Modal key={new Date().toString()} show={showModal === 'AppointmentForm'} handleClose={handleClose}>
                <AppointmentForm target={target} date={startOfHour(addHours(new Date(), 1))} handleClose={() => { dispatchAppointments(); handleClose() }} />
            </Modal>

            <Modal show={showModal === 'ConfirmForm'} handleClose={handleClose}>
                <ConfirmForm title='Verwijder afspraak' message='Weet u zeker dat u deze afspraak wilt verwijderen?' action='DELETE' handleConfirm={async () => { await Appointment.delete(target!.id); dispatchAppointments(); }} handleClose={handleClose} />
            </Modal>
        </div>
    );
}

export default AppointmentIndex;