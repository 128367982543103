import React, { FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage, getLocales } from 'core/store/base/selectors';

import { format } from 'date-fns';

import { LocalizationContext } from 'core/context/localizationContext';
import Modal from 'components/Modal/Modal';

export interface SubscriptionDetailsProps {
    subscriptions: SubscriptionType[]
}

const SubscriptionDetails: FC<SubscriptionDetailsProps> = ({ subscriptions }) => {
    const language = useSelector(getLanguage);
    const locales = useSelector(getLocales);
    const localization = useContext(LocalizationContext);
    const _t = localization![language] ?? localization!['en'];

    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <div>
            {subscriptions ? subscriptions.map((subscription) => (

                <div key={subscription.id}>
                    <div className='align-center'>
                        <span className='property'>
                            {_t && _t.subscription ? _t.subscription.current : 'Current subscription'}
                        </span>
                        <div className='value'>{subscription ? `: ${_t && _t.subscription ? _t.subscription[subscription.plan] : ''}` : _t.userpanel.subscriptionDetails.na ?? 'n.a.'}</div>
                        <span className='subscription-plan-indicator' style={{ backgroundColor: subscription.plan === 'bronze' ? '#cd7f32' : subscription.plan }} />
                    </div>
                    <div className='value'><span className='property'>{`${_t.userpanel.subscriptionDetails.startDate ?? 'Start date'}: `}</span>{format(new Date(subscription.created_at), 'dd MMMM yyyy', { locale: locales[language] })}</div>

                    <div className='horizontal-divider' />

                    {subscription.next_plan || subscription.ends_at
                        ? <div>
                            <div className='headline'>{`${_t.userpanel.subscriptionDetails.changedNotification ?? 'Changes have been made'}:`}</div>
                            {subscription.ends_at
                                ? <div className='value danger'>{`${_t.userpanel.subscriptionDetails.subscriptionCanceled ?? 'Subscription canceled: ends'} ${format(new Date(subscription.ends_at), 'dd MMMM yyyy', { locale: locales[language] })}.`}</div>
                                : (subscription.next_plan
                                    ? <div className='value'>
                                        {_t.userpanel.subscriptionDetails.subscriptionSwapped.replace(':date', format(new Date(subscription.cycle_ends_at as string), 'dd-MM-yyyy')).replace(':plan', _t.subscription[subscription.next_plan]) ?? 'Subscription swapped'}
                                    </div>
                                    : null)
                            }
                        </div>
                        : null}

                    <div className='horizontal-divider' />

                    <span className='footer footnote is-clickable' onClick={() => setShowModal(true)}>{_t.userpanel.subscriptionDetails.editSubscription ?? 'Edit subscription'}</span>
                </div>

            )) : null}
            <Modal show={showModal} handleClose={() => setShowModal(false)}>
                <div className='headline center'>{_t.userpanel.subscriptionDetails.editSubscription ?? 'Edit subscription'}</div>
                <div>{`${_t.userpanel.subscriptionDetails.contact ?? 'Please contact an administrator'}:`}</div>
                <div className='vertical-divider' />
                <div className='footnote'>{'071-3314241'}</div>
                <a className='footnote underline' href='mailto:info@timestables.nl'>{'info@timestables.nl'}</a>
            </Modal>
        </div>
    );
}

export default SubscriptionDetails;
