import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import Store from 'core/store/store';

import DropdownMenu from 'components/Dropdown/DropdownMenu/DropdownMenu';
import DropdownItem from 'components/Dropdown/DropdownItem/DropdownItem';

import Calendar from 'core/apis/Calendar';

import { LocalizationContext } from 'core/context/localizationContext';

import SelectInput from 'components/Input/SelectInput/SelectInput';
import LabelInput from 'components/Input/LabelInput/LabelInput';

import icons from 'assets/svg/icons.svg';

const COLORS = [
    window.Branding.primaryColor,
    // window.Branding.secondaryColor,
    window.Branding.tertiaryColor,
    '#FF3532',
    '#FF32E0',
    '#C532FF',
    '#6D32FF',
    '#3250FF',
    '#32A8FF',
    '#32FFFC',
    '#32FFA4',
];

const timeIntervalOptions = {
    hourly: 60,
    halfHourly: 30
}

const CalendarForm = ({ target, handleClose }) => {
    const [validationErrors, setValidationErrors] = useState({ title: [], time_interval: [], available_slots: [], limit: [], price: [] });
    const [formData, setFormData] = useState({ title: '', color: window.Branding.primaryColor, time_interval: 0, available_slots: 0, limit: null, price: '' });

    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    useEffect(() => {
        if (target) {
            setFormData({
                title: target.title,
                color: target.color,
                time_interval: target.time_interval,
                available_slots: target.available_slots,
                limit: target.limit,
                price: target.price
            });
        }
    }, [target]);

    //: React.ChangeEvent<HTMLInputElement>
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const storeCalendar = async (update = false) => {
        const calendar = {
            title: formData.title,
            color: formData.color,
            time_interval: formData.time_interval > 0 ? formData.time_interval : null,
            available_slots: formData.available_slots > 0 ? formData.available_slots : null,
            limit: formData.limit,
            price: formData.price ? formData.price.replace(/,/g, '.') : null,
        }

        let errors = {};
        if (update) {
            await Calendar.update(calendar, target!.id).catch(error => {
                errors = error.response.data.errors;
                const messages = _.assign(_.clone(validationErrors), error.response.data.errors);
                setValidationErrors(messages);
            });
        }
        else {
            await Calendar.store(calendar).catch(error => {
                errors = error.response.data.errors;
                const messages = _.assign(_.clone(validationErrors), error.response.data.errors);
                setValidationErrors(messages);
            });
        }

        if (!Object.keys(errors).some((key) => errors[key].length)) {

            setFormData({
                title: '',
                time_interval: 60,
                available_slots: 1,
                limit: null,
                price: '',
                color: ''
            });

            handleClose(true);
        }
    }

    return (
        <div>
            <label className='headline center'>{target ? (_t ? _t.calendar.edit + ': ' : 'Edit calendar: ') + target.id : (_t ? _t.calendar.create : 'New calendar')}</label>
            <div className='vertical-spacer' />

            <div className='horizontal-stack'>
                <div style={{ width: '100%' }}>
                    <LabelInput
                        required
                        label={_t.calendar.title ?? 'Title'}
                        name={'title'}
                        handleChange={handleChange}
                        defaultValue={target ? target.title : ''}
                        errors={validationErrors.title}
                    />
                </div>

                <div className='horizontal-divider' />

                <div className=''>
                    <label className='label'><span>&nbsp;&nbsp;</span></label>
                    <DropdownMenu
                        fitContent={false}
                        label={
                            <div className='input center' style={{ border: '1px solid rgba(0,0,0,.3)' }}>
                                <span style={{ height: '25px', width: '25px', borderRadius: '50%', display: 'inline-block', backgroundColor: formData.color }} />
                                <div className='horizontal-spacer' />
                                <div className='icon-container'>
                                    <svg className='icon' style={{ fill: 'rgba(0,0,0,.3)', height: '1.5rem', width: '1.5rem' }}>
                                        <use xlinkHref={`${icons}#chevron-down`} />
                                    </svg>
                                </div>
                            </div>
                        }
                    >
                        <div className=''>
                            {COLORS.map((color) => (
                                <DropdownItem key={color} label={
                                    <span style={{ height: '25px', width: '25px', borderRadius: '50%', display: 'inline-block', backgroundColor: color }} />
                                } onClick={() => setFormData({ ...formData, color: color })} />
                            ))}
                        </div>
                    </DropdownMenu>
                </div>
            </div>

            <SelectInput
                required
                label={_t.calendar.timeInterval ?? 'Timeslots'}
                value={_t.calendar[Object.keys(timeIntervalOptions).find(key => timeIntervalOptions[key] === formData.time_interval) ?? ''] ?? ''}
                placeholder={_t.calendar.selectOption ?? '-- Select an option --'}
                errors={validationErrors.time_interval}
            >
                {_.map(timeIntervalOptions, (value, label) => <div key={value} onClick={() => setFormData({ ...formData, time_interval: value })}>{_t.calendar[label] ?? label}</div>)}
            </SelectInput>

            <LabelInput
                required
                label={_t.calendar.availableSlots ?? 'Available slots'}
                name={'available_slots'}
                handleChange={handleChange}
                defaultValue={target ? target.available_slots : ''}
                errors={validationErrors.available_slots}
            />

            <LabelInput
                label={_t.calendar.limit ?? 'Limit'}
                name={'limit'}
                handleChange={handleChange}
                defaultValue={target ? target.limit : ''}
                errors={validationErrors.limit}
            />

            <LabelInput
                label={_t.calendar.price ?? 'Costs per timeslot (00.00)'}
                name={'price'}
                handleChange={handleChange}
                defaultValue={target ? target.price : ''}
                errors={validationErrors.price}
            />

            <div className='justify-center'>
                {target
                    ? <button style={{ width: '50%' }} type='submit' onClick={() => storeCalendar(true)} className='button' >{_t ? _t.calendar.submitEdit : 'Edit'}</button>
                    : <button style={{ width: '50%' }} type='submit' onClick={() => storeCalendar()} className='button' >{_t ? _t.calendar.submit : 'Submit'}</button>
                }
            </div>
        </div>
    )
}

export default CalendarForm;