import React, { FC } from 'react';

import './LabelInput.scss';

import ValidationError from '../ValidationError/ValidationError';

export interface LabelInputProps {
    label: string
    required?: boolean
    disabled?: boolean
    type?: string
    name: string
    defaultValue?: string
    errors: string[]
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const LabelInput: FC<LabelInputProps> = ({ label, required, disabled, type, name, defaultValue, errors, handleChange }) => {
    return (
        <div>
            <label className='input-label'>{`${label} ${required ? '*' : ''}`}</label>
            <input
                type={type ?? 'text'}
                className='input'
                id={name}
                name={name}
                onChange={handleChange}
                defaultValue={defaultValue}
                autoComplete='off'
                disabled={disabled}
            />
            {errors.length ? errors.map((error: string) => <ValidationError key={error} message={error} />) : <div style={{marginBottom: '1rem'}}><span>&nbsp;&nbsp;</span></div>}
        </div>
    );
}

export default LabelInput;
