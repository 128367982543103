import { ContentProps } from 'components/Sidebar/Sidebar';

export const content: ContentProps[] = [
    {
        title: 'dashboard',
        icon: 'dashboard',
        pages: [
            {
                title: 'dashboard',
                url: '?section=dashboard&page=dashboard',
                tag: 0
            }
        ]
    },
    {
        title: 'users',
        icon: 'user',
        pages: [
            {
                title: 'users',
                url: '?section=users&page=users',
                tag: 1
            },
            {
                title: 'accessControl',
                url: '?section=users&page=accessControl',
                tag: 2
            }
        ]
    },
    {
        title: 'horses',
        icon: 'horse',
        pages: [
            {
                title: 'horses',
                url: '?section=horses&page=horses',
                tag: 3
            }
        ]
    },
    {
        title: 'appointments',
        icon: 'calendar',
        pages: [
            {
                title: 'calendars',
                url: '?section=appointments&page=calendars',
                tag: 4
            },
            {
                title: 'appointments',
                url: '?section=appointments&page=appointments',
                tag: 5
            },
            {
                title: 'schedule',
                url: '?section=appointments&page=schedule',
                tag: 6
            },
        ]
    },
    {
        title: 'lessons',
        icon: 'book',
        pages: [
            {
                title: 'lessons',
                url: '?section=lessons&page=lessons',
                tag: 7
            },
            {
                title: 'lessonSchedule',
                url: '?section=lessons&page=schedule',
                tag: 8
            }
        ]
    }
];
