import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import getTime from 'date-fns/getTime';

import Store from 'core/store/store';
import { getUsers } from 'core/store/admin/selectors';

import Horse from 'core/apis/Horse';

import TokenInput from 'components/Input/TokenInput/TokenInput';
import Token from 'components/Input/TokenInput/Token';

import { LocalizationContext } from 'core/context/localizationContext';

import DatePicker from "react-datepicker";
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LabelInput from 'components/Input/LabelInput/LabelInput';

const locales = {
    nl: require('date-fns/locale/nl'),
    en: require('date-fns/locale/en-US')
}

const HorseForm = ({ target, handleClose }) => {
    const users = useSelector(getUsers);
    const [validationErrors, setValidationErrors] = useState({ user_ids: [], name: [], birth_date: [], chip_number: [], veterinarian: [], farrier: [] });
    const [formData, setFormData] = useState({ name: '', birth_date: new Date(), chip_number: '', veterinarian: '', farrier: '' });

    const [selected, setSelected] = useState<number[]>([]);

    const language = Store.getState().base.language;
    const localization = useContext(LocalizationContext);
    const _t = localization![language] ?? localization!['en'];

    useEffect(() => {
        if (target) {
            setFormData({
                name: target.name,
                birth_date: new Date(target.birth_date),
                chip_number: target.chip_number,
                veterinarian: target.veterinarian,
                farrier: target.farrier,
            });

            setSelected(target.users.map((user) => user.id));
        }

        _.forEach(locales, (locale) => {
            if (locale.code!.split('-')[0] === language) {
                registerLocale(language, locale);
            }
        });
    }, [language, target]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSelect = (id: number) => {
        setSelected([...selected, id]);
    }

    const handleUnselect = (id: number) => {
        setSelected(selected.filter((s) => s !== id));
    }

    const storeHorse = async (update = false) => {
        const horse = {
            user_ids: selected,
            name: formData.name,
            birth_date: getTime(formData.birth_date),
            chip_number: formData.chip_number,
            veterinarian: formData.veterinarian,
            farrier: formData.farrier,
        }

        let errors = {};
        if (update) {
            await Horse.update(horse, target!.id).catch(error => {
                errors = error.response.data.errors;
                const messages = _.assign(_.clone(validationErrors), error.response.data.errors);
                setValidationErrors(messages);
            });
        }
        else {
            await Horse.store(horse).catch(error => {
                errors = error.response.data.errors;
                const messages = _.assign(_.clone(validationErrors), error.response.data.errors);
                setValidationErrors(messages);
            });
        }

        if (!Object.keys(errors).some((key) => errors[key].length)) {

            setFormData({
                name: '',
                birth_date: new Date(),
                chip_number: '',
                veterinarian: '',
                farrier: '',
            });

            handleClose(true);
        }
    }

    return (
        <div>
            <label className='headline center'>{target ? (_t ? _t.horse.edit + ': ' : 'Edit horse: ') + target.id : (_t ? _t.horse.create : 'New horse')}</label>
            <div className='vertical-spacer' />

            <LabelInput
                required
                label={_t.horse.name ?? 'Name'}
                name={'name'}
                handleChange={handleChange}
                defaultValue={target ? target.name : ''}
                errors={validationErrors.name}
            />

            <label className='label'>{(_t ? _t.horse.birthDate : 'Birth date') + ' *'}</label>
            <div>
                <DatePicker
                    selected={formData.birth_date}
                    onChange={(date: Date) => setFormData({ ...formData, birth_date: date })}
                    timeFormat='HH:mm'
                    dateFormat='d-MM-yyyy'
                    customInput={<input className='input' />}
                    locale={Store.getState().base.language}
                    todayButton={_t && _t.calendar ? _t.calendar.today : 'Today'}
                    showPopperArrow={false}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='scroll'
                />
            </div>
            <div className='validation-text danger'>
                {validationErrors && validationErrors.hasOwnProperty('birth_date') && validationErrors.birth_date.length ? validationErrors.birth_date.map((e) => e) : <span>&nbsp;&nbsp;</span>}
            </div>

            <TokenInput
                label={_t.horse.user + '(s)' ?? 'User(s)'}
                data={users.filter((user) => !selected.includes(user.id))}
                keyProperty={'id'}
                valueProperty={'name'}
                handleSelect={handleSelect}
            >
                <div className='token-row'>
                    {selected.map((s) => {
                        const token = users.find((user) => user.id === s);
                        return (
                            token
                                ? <Token
                                    key={token.id}
                                    title={token.name}
                                    handleDelete={() => handleUnselect(token.id)}
                                />
                                : null
                        )
                    })}
                </div>
            </TokenInput>
            <div className='validation-text danger'>
                {validationErrors && validationErrors.hasOwnProperty('user_ids') && validationErrors.user_ids.length ? validationErrors.user_ids.map((e) => e) : <span>&nbsp;&nbsp;</span>}
            </div>

            <LabelInput
                required
                label={_t.horse.chipNumber ?? 'Chip number'}
                name={'chip_number'}
                handleChange={handleChange}
                defaultValue={target ? target.chip_number : ''}
                errors={validationErrors.chip_number}
            />

            <LabelInput
                required
                label={_t.horse.veterinarian ?? 'Veterinarian'}
                name={'veterinarian'}
                handleChange={handleChange}
                defaultValue={target ? target.veterinarian : ''}
                errors={validationErrors.veterinarian}
            />

            <LabelInput
                required
                label={_t.horse.farrier ?? 'Farrier'}
                name={'farrier'}
                handleChange={handleChange}
                defaultValue={target ? target.farrier : ''}
                errors={validationErrors.farrier}
            />

            <div className='justify-end'>
                {target
                    ? <button style={{ width: '50%' }} type='submit' onClick={() => storeHorse(true)} className='button' >{_t ? _t.calendar.submitEdit : 'Edit'}</button>
                    : <button style={{ width: '50%' }} type='submit' onClick={() => storeHorse()} className='button' >{_t ? _t.calendar.submit : 'Submit'}</button>
                }
            </div>
        </div>
    )
}

export default HorseForm;