import Api from './Api';

const UserActions = {

    index(query: string = '') {
        return Api().get('/user-actions' + query);
    }

};

export default UserActions;