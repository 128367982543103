import {  
    SET_CALENDARS,
    SET_USERS,
    SET_APPOINTMENTS,
    SET_PAYMENTS,
    SET_HORSES,
    SET_LESSONS,
    SET_USERACTIONS,
    SET_ANNOUNCEMENTS,
    AdminActionTypes 
} from './types';

import { REHYDRATE } from 'redux-persist';

const initialState = {
    users: [],
    calendars: [],
    appointments: [],
    lessons: [],
    horses: [],
    payments: [],
    announcements: [],
    userActions: [],
};

export const adminReducer = (state = initialState, action: AdminActionTypes) => {
    switch (action.type) {

        case SET_USERS: {
            return {
                ...state,
                users: action.payload
            }
        }

        case SET_CALENDARS: {
            return {
                ...state,
                calendars: action.payload
            }
        }

        case SET_APPOINTMENTS: {
            return {
                ...state,
                appointments: action.payload
            }
        }

        case SET_LESSONS: {
            return {
                ...state,
                lessons: action.payload
            }
        }

        case SET_PAYMENTS: {
            return {
                ...state,
                payments: action.payload
            }
        }

        case SET_HORSES: {
            return {
                ...state,
                horses: action.payload
            }
        }

        case SET_USERACTIONS: {
            return {
                ...state,
                userActions: action.payload
            }
        }

        case SET_ANNOUNCEMENTS: {
            return {
                ...state,
                announcements: action.payload
            }
        }

        case REHYDRATE: {
            return state;
        }

        default: {
            return {
                ...state
            }
        }

    }
};