import React, { useState, useEffect, createRef } from 'react';

import './Modal.scss';

import icons from 'assets/svg/icons.svg';

const Modal = ({ handleClose, show, children, closeButtonEnabled = true }) => {
    const [showModal, setShowModal] = useState(show);
    const node = createRef<HTMLDivElement>();
    const showHideClassName = showModal ? 'modal display-block' : 'modal display-none';

    useEffect(() => {
        setShowModal(show);

        document.addEventListener('keydown', handleEscape, false);

        return () => {
            document.removeEventListener('keydown', handleEscape, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const handleClick = (e) => {
        e.stopPropagation();
        if (node.current!.contains(e.target)) {
            return
        }
        else {
            handleClose();
        }
    }

    const handleEscape = (e: { key: any; keyCode: any; }) => {
        let key = e.key || e.keyCode;
        if (key === 'Escape' || key === 27) {
            handleClose();
        }
    }

    return (
        <div onClick={handleClick} className={showHideClassName}>
            <section ref={node} className='modal-main'>

                {closeButtonEnabled
                    ?
                    <div className='justify-end'>
                        <svg onClick={(e) => { e.stopPropagation(); handleClose() }} className='modal-close-button'>
                            <use xlinkHref={`${icons}#cross`} />
                        </svg>
                    </div>
                    :
                    null
                }

                {children}
            </section>
        </div>
    );
};

export default Modal;