import React from 'react';

export const AssetContext = React.createContext<IAssets | null>(null);

const AssetProvider = ({ assets, children }: { assets: IAssets, children: React.ReactNode }) => {
    return (
        <AssetContext.Provider
            value={assets}
        >
            {children}
        </AssetContext.Provider>
    );
};

export default AssetProvider;