import Api from './Api';

const User = {

    register(form: { name: string; email: string; password: string; password_confirmation: string }) {
        return Api().post('/register', form);
    },

    login(form: { email: string; password: string; device_name: string }) {
        return Api().post('/login', form);
    },

    logout() {
        return Api().post('/logout');
    },

    auth() {
        return Api().get('/user');
    },

    index(query: string = '') {
        return Api().get('/users' + query);
    },

    delete(id: number) {
        return Api().delete('/users/' + id);
    },

    update(form: { name: string; email: string; password: string; password_confirmation: string }, id: number) {
        return Api().put('/users/' + id, form);
    },

    requestEmailVerification() {
        return Api().post('email/resend');
    },

    verifyEmail(id: string, hash: string, query: string) {
        return Api().get(`email/verify/${id}/${hash}?${query}`);
    },

    requestPasswordResetToken(form: { email: string; }) {
        return Api().post('/user/forgot-password', form);
    },

    verifyPasswordResetToken(form: { reset_token: string; }) {
        return Api().post('/user/reset-password-token', form);
    },

    setNewPasswordWithToken(form: { reset_token: string; password: string; password_confirmation: string }) {
        return Api().post('/user/new-password', form);
    },

};

export default User;