import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';

import { getCalendars } from 'core/store/admin/selectors';

import { useDrop } from 'react-dnd';

import _ from 'lodash';

import { addMinutes, differenceInMinutes, getMinutes, getTime } from 'date-fns';

import { AppointmentType } from '../Interfaces/Interfaces';
import { ItemTypes } from '../ItemTypes/ItemTypes';

import { CalendarContext } from 'containers/Admin/AdminPanel/Calendar/2.0/Context/CalendarContext';

import './DropWrapper.scss';

export interface DropWrapperProps {
    timestamp: number
    children?: React.ReactElement
}

const DropWrapper: FC<DropWrapperProps> = ({ timestamp, children }) => {
    const calendars = useSelector(getCalendars);
    const calendarActions = useContext(CalendarContext);

    const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.EVENT,
        canDrop: (item: AppointmentType, monitor) => {
            const calendar = calendars.find((calendar) => calendar.id === item.calendar_id);
            const interval = calendar ? calendar.time_interval : null;

            let validTime = true;
            if (interval) {
                validTime = getMinutes(new Date(timestamp)) % interval === 0;
            }

            return validTime && item.start_time !== timestamp;
        },
        drop: (item: AppointmentType, monitor) => {
            if (calendarActions?.onDrop) {
                const duration = differenceInMinutes(new Date(item.end_time), new Date(item.start_time));
                const endTimeTimestamp = getTime(addMinutes(new Date(timestamp), duration));
    
                let clone = _.clone(item);
                calendarActions?.onDrop(_.assign(clone, { start_time: timestamp, end_time: endTimeTimestamp }), clone.id);

                // // eslint-disable-next-line no-restricted-globals
                // if (confirm('Wil je de activiteit alleen op deze plaats wijzigen of wil je deze activiteit en alle toekomstige herhalingen wijzigen?') === true) {
                //     calendarActions?.onDrop(_.assign(clone, { start_time: timestamp, end_time: endTimeTimestamp }), clone.id);
                // } else {
                //     calendarActions?.onDrop(_.assign(clone, { start_time: timestamp, end_time: endTimeTimestamp }), clone.id);
                // }
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    return (
        <div ref={drop} className='drop-wrapper' onClick={() => calendarActions?.onCreate ? calendarActions?.onCreate(new Date(timestamp)) : () => void(0)}>
            {React.cloneElement(children as React.ReactElement, { isOver })}
        </div>
    );
}

export default DropWrapper;
