import React, { FC } from 'react';

export interface PageProps {
    title: string
    url: string
    tag: number
}

export interface SidebarPageProps {
    page: PageProps
    selected: boolean
}

const SidebarPage: FC<SidebarPageProps> = ({ page, selected }) => {
    return (
        <div className={`sidebar-content-container ${selected ? 'selected' : ''}`}>
            <div className='sidebar-row'>
                <div className='sidebar-row-item'>
                    <svg className='sidebar-icon'>
                    </svg>
                </div>
                <div className='sidebar-row-item'>
                    {page.title}
                </div>
            </div>
        </div>
    )
}

export default SidebarPage;
