import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getConfirmedStatus, getVerifiedStatus, getBlockedStatus } from 'core/store/authentication/selectors';

import { Switch, Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';

import Store from 'core/store/store';
import { authenticate, logout } from 'core/store/authentication/action';

// import Navigation from 'containers/Navigation/Navigation';

import LoginForm from 'containers/Authentication/LoginForm/LoginForm';
import RegisterForm from 'containers/Authentication/RegisterForm/RegisterForm';
import VerifyEmailForm from 'containers/Authentication/VerifyEmailForm/VerifyEmailForm';
import PasswordResetForm from 'containers/Authentication/PasswordResetForm/PasswordResetForm';
import AdminPanel from 'containers/Admin/AdminPanel/AdminPanel';
import UserPanel from 'containers/UserPanel/UserPanel';

interface Props extends RouteComponentProps {
    logo: string;
}

const Root = ({ location, logo }: Props) => {
    const confirmed = useSelector(getConfirmedStatus);
    const verified = useSelector(getVerifiedStatus);
    const blocked = useSelector(getBlockedStatus);

    const state = Store.getState();

    useEffect(() => {

        if (blocked) {
            handleLogout();
        }

        const setup = async () => {
            if (state.authentication.userLoggedIn && !state.authentication.requestPending) {
                await Store.dispatch(authenticate());
            }
        };
        setup();
    }, [blocked, state]);

    const handleLogout = async () => {
        await Store.dispatch(logout());
    }

    const AdminRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={(props) => (
            state.authentication.role === 'admin'
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: '/home',
                    state: { from: props.location }
                }} />
        )} />
    );

    const AuthenticatedRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={(props) => (
            state.authentication.userLoggedIn
                ? (verified && confirmed
                    ? <Component {...props} />
                    : <Redirect to={{
                        pathname: '/verify',
                        state: { from: props.location }
                    }} />)
                : <Redirect to={{
                    pathname: '/',
                    state: { from: props.location }
                }} />
        )} />
    );

    return (

        <div>

            {/* {location.pathname !== '/' && location.pathname !== '/register' && location.pathname !== '/reset' && location.pathname !== '/verify' ? <Navigation header={location.pathname !== '/admin' ? header : ''} /> : ''} */}

            <div className='app-container'>

                <Switch location={location}>

                    <Route exact path='/' render={(props) => state.authentication.userLoggedIn ? <Redirect to={{ pathname: '/home' }} /> : <LoginForm {...props} logo={logo} />} />

                    <Route path='/verify' render={(props) => state.authentication.userLoggedIn && (!verified || !confirmed) ? <VerifyEmailForm /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />} />

                    <Route path='/reset' render={(props) => <PasswordResetForm />} />

                    <Route path='/register' render={(props) => <RegisterForm {...props} />} />

                    <AdminRoute path='/admin' meta='Admin' component={AdminPanel} />

                    <AuthenticatedRoute path='/home' meta='User' component={UserPanel} />

                </Switch>

            </div>

        </div>

    );

};

export default withRouter(Root);