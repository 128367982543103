import { SET_USER, SET_APPOINTMENTS, UserActionTypes } from './types';

export const setUser = ({ user }: { user: UserType }): UserActionTypes => ({
    type: SET_USER,
    payload: user
});

export const setAppointments = ({ appointments }: { appointments: AppointmentType[] }): UserActionTypes => ({
    type: SET_APPOINTMENTS,
    payload: appointments
});
