import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { startOfDay, startOfWeek } from 'date-fns/esm';
import { addDays, addMinutes, getTime } from 'date-fns';

import _ from 'lodash';

import Store from 'core/store/store';
import { setAppointments } from 'core/store/user/action';
import { getUser } from 'core/store/user/selectors';
import Appointment from 'core/apis/Appointment';

import Card from 'components/Card/Card';
import CalendarComponent from 'containers/Admin/AdminPanel/Calendar/2.0/Calendar/Calendar';
import CalendarProvider from 'containers/Admin/AdminPanel/Calendar/2.0/Context/CalendarContext';

export interface PrefilledScheduleProps {
    calendars: CalendarType[]
    appointments: AppointmentType[]
    date?: Date
    range?: number
}

const PrefilledSchedule: FC<PrefilledScheduleProps> = ({ calendars, appointments, date = new Date(), range = 7 }) => {
    const user = useSelector(getUser);

    const [selectedCalendar, setSelectedCalendar] = useState<number>(1);

    const handleChange = (e) => {
        setSelectedCalendar(parseInt(e.target.value));
    }

    const dispatchAppointments = async () => {
        const request = await Appointment.index();
        Store.dispatch(setAppointments({ appointments: request.data }));
    }

    const getEvent = (time: Date): AppointmentType => {
        const calendar = _.find(calendars, (calendar) => calendar.id === selectedCalendar);
        const timeInterval = calendar ? calendar.time_interval : 60;
        const availableSlots = calendar ? calendar.available_slots : 1;

        const numberOfAppointmentsThisHour = appointments.filter((appointment) => appointment.start_time === getTime(time) && appointment.calendar_id === calendar!.id).length;

        let event: AppointmentType;
        if (!_.isEmpty(calendars)) {
            event = {
                id: 0,
                user_id: 0,
                calendar_id: selectedCalendar,
                confirmed: true,
                type: calendar!.title,
                comments: `${numberOfAppointmentsThisHour}/${availableSlots} plaatsen\n`,
                start_time: getTime(time),
                end_time: getTime(addMinutes(time, timeInterval)),
                created_at: date.toString(),
                updated_at: date.toString(),
            }
        } else {
            event = {} as AppointmentType;
        }

        return event;
    }

    const prefillTimeSlots = (day: Date): AppointmentType[] => {
        const calendar = _.find(calendars, (calendar) => calendar.id === selectedCalendar);
        const timeInterval = calendar ? calendar.time_interval : 60;
        const startTime = startOfDay(day);

        let events: AppointmentType[] = [];
        for (let i = 8; i < (21 * (60 / timeInterval)); i++) {
            const time = addMinutes(startTime, i * timeInterval);
            const event = getEvent(time);

            events.push(event);
        }

        return events;
    }

    const getData = (startDate: Date): AppointmentType[] => {
        const startDay = range === 7 ? startOfWeek(startDate, { weekStartsOn: 1 }) : startDate;

        let events: AppointmentType[] = [];
        for (let i = 0; i < 28; i++) {
            const day = addDays(startDay, i);

            if (day >= startOfDay(new Date())) {
                const data = prefillTimeSlots(day);
                events = events.concat(data);
            }

        }

        return events
    }

    const onAttach = async (appointment: AppointmentType) => {

        let storableModel = _.omit(appointment, ['id', 'created_at', 'updated_at', 'confirmed']);
        _.assign(storableModel, {
            user_id: user.id,
            comments: ''
        });

        const response = await Appointment.store(storableModel);

        if (response.data.hasOwnProperty('url')) {
            window.location.href = response.data.url;
        }

        dispatchAppointments();
    }

    const onDetach = async (id: number) => {
        await Appointment.delete(id);
        dispatchAppointments();
    }

    return (
        <Card>
            <label className='label'>{'Kies het type afspraak'}</label>
            <select id='type' name='type' className='input' onChange={handleChange} defaultValue={selectedCalendar}>
                <option value='placeholder' disabled>{'-- Select an option --'}</option>
                {_.map(calendars, (option, key) => <option className='label' key={key} value={option.id}>{option.title}</option>)}
            </select>

            <div className='horizontal-divider' />

            <CalendarProvider actions={{
                type: 'SCHEDULE',
                onAttach: onAttach,
                onDetach: onDetach
            }}>
                <CalendarComponent initialDate={date} initialRange={7} events={getData(date)} />
            </CalendarProvider>
        </Card>
    );
}

export default PrefilledSchedule;
