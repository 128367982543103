import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import {
    format,
    isSameDay, isSameWeek, isSameMonth,
    addDays, addWeeks, addMonths,
    subDays, subWeeks, subMonths,
    isToday, isThisWeek, isThisMonth
} from 'date-fns';

import { VictoryAxis, VictoryChart, VictoryLine, VictoryScatter, VictoryTheme } from 'victory';

import Store from 'core/store/store';

import { LocalizationContext } from 'core/context/localizationContext';

import Card from 'components/Card/Card';

import icons from 'assets/svg/icons.svg';

import './PaymentsOverview.scss';

const locales = {
    nl: require('date-fns/locale/nl'),
    en: require('date-fns/locale/en-US')
}

interface PaymentType {
    id: number;
    amount: number;
    status: string;
    paymentable_type: string;
    paymentable_id: number;
    created_at: string;
    updated_at: string;
}

interface ChartDataType {
    x: number | string;
    y: number;
    date: Date;
}

const PaymentsOverview = ({ payments, total = true }: { payments: PaymentType[]; total?: boolean }) => {
    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    const [activeLocale] = useState(locales[Store.getState().base.language ?? 'en']);

    const [paymentableTypes, setPaymentableTypes] = useState<string[]>([]);
    const [currentPaymentableType, setCurrentPaymentableType] = useState<string>('');

    const [recurrence, setRecurrence] = useState<string>('monthly');
    const [range, setRange] = useState<number>(7);

    useEffect(() => {
        const setup = () => {

            if (!total && !_.isEmpty(payments) && _.isEmpty(paymentableTypes)) {
                const paymentableTypes = _.uniq(payments.map((payment) => payment.paymentable_type));
                setPaymentableTypes(paymentableTypes);
                setCurrentPaymentableType(paymentableTypes[0]);
            }
        }
        setup();
    }, [payments, total, paymentableTypes]);

    // cache.. state?
    const getRecurrenceData = (): {
        previous: number;
        current: number;
        difference: number;
        paid: number;
        pending: number;
        canceled: number;
        expired: number;
    } => {

        let data: PaymentType[] = payments;
        if (!total) {
            data = payments.filter((payment) => payment.paymentable_type === currentPaymentableType)
        }

        let filterFunction = (date: Date) => isThisMonth(date);
        const current = _.reduce(data, (result, payment) => {
            let paid = payment.status === 'paid';

            let isSameRecurrence: boolean = false;
            switch (recurrence) {
                case 'daily':
                    isSameRecurrence = isToday(new Date(payment.created_at));
                    filterFunction = (date: Date) => isToday(date);
                    break;
                case 'weekly':
                    isSameRecurrence = isThisWeek(new Date(payment.created_at));
                    filterFunction = (date: Date) => isThisWeek(date);
                    break;
                case 'monthly':
                    isSameRecurrence = isThisMonth(new Date(payment.created_at));
                    filterFunction = (date: Date) => isThisMonth(date);
                    break;
            }

            return result + (paid && isSameRecurrence ? +payment.amount : 0);
        }, 0);

        const previous = _.reduce(data, (result, payment) => {
            let paid = payment.status === 'paid';

            let isSameRecurrence: boolean = false;
            switch (recurrence) {
                case 'daily':
                    isSameRecurrence = isSameDay(new Date(payment.created_at), subDays(new Date(), 1));
                    break;
                case 'weekly':
                    isSameRecurrence = isSameWeek(new Date(payment.created_at), subWeeks(new Date(), 1));
                    break;
                case 'monthly':
                    isSameRecurrence = isSameMonth(new Date(payment.created_at), subMonths(new Date(), 1));
                    break;
            }
            return result + (paid && isSameRecurrence ? +payment.amount : 0);
        }, 0);

        return {
            current: current,
            previous: previous,
            difference: (current - previous),
            paid: data.filter((payment) => payment.status === 'paid' && filterFunction(new Date(payment.created_at))).length,
            pending: data.filter((payment) => payment.status === 'pending' && filterFunction(new Date(payment.created_at))).length,
            canceled: data.filter((payment) => payment.status === 'canceled' && filterFunction(new Date(payment.created_at))).length,
            expired: data.filter((payment) => payment.status === 'expired' && filterFunction(new Date(payment.created_at))).length,
        }
    }

    const getChartData = () => {

        let startDate: Date;
        switch (recurrence) {
            case 'daily':
                startDate = subDays(new Date(), 1);
                break;
            case 'weekly':
                startDate = subWeeks(new Date(), 1);
                break;
            case 'monthly':
                startDate = subMonths(new Date(), 1);
                break;
        }

        let data: PaymentType[] = payments;
        if (!total) {
            data = payments.filter((payment) => payment.paymentable_type === currentPaymentableType)
        }

        let chartData: ChartDataType[] = [];

        for (let i = 0; i < range; i++) {

            let date: Date = new Date();
            let dateFormat: string = 'MMM';
            const revenue = _.reduce(data, (result, payment) => {
                const paid = payment.status === 'paid';

                let isSameRecurrence: boolean = false;
                switch (recurrence) {
                    case 'daily':
                        date = addDays(startDate, i);
                        dateFormat = 'iiiiii';
                        isSameRecurrence = isSameDay(new Date(payment.created_at), date);
                        break;
                    case 'weekly':
                        date = addWeeks(startDate, i);
                        dateFormat = '\'W\'w-dd/MM';
                        isSameRecurrence = isSameWeek(new Date(payment.created_at), addWeeks(startDate, i));
                        break;
                    case 'monthly':
                        date = addMonths(startDate, i);
                        dateFormat = 'MMM';
                        isSameRecurrence = isSameMonth(new Date(payment.created_at), addMonths(startDate, i));
                        break;
                }

                const valid = paid && isSameRecurrence;
                return result + (valid ? +payment.amount : 0);
            }, 0);

            chartData.push({
                x: format(date, dateFormat, { locale: activeLocale }),
                y: revenue,
                date: date
            });
        }

        return chartData;
    }

    return (
        <div className='dashboard-item overlay-container'>

            <div className='overlay-target'>

                <Card header={_t.dashboard && _t.dashboard.payments ? `${_t.dashboard.payments} ${_t.dashboard.overview.toLowerCase()} - ${total ? _t.dashboard.total : _t.dashboard[_.split(currentPaymentableType, '\\')[_.split(currentPaymentableType, '\\').length - 1].toLowerCase() + 's']}` : 'Payments overview'}>

                    <div className='dashboard-row'>
                        <div className='dashboard-item two'>
                            <div className='vertical-stack'>

                                <div className='dashboard-item'>
                                    <div className='dashboard-row'>
                                        <div className='dashboard-item justify-end'>
                                            <svg className='chart-icon small' onClick={() => setRange(range <= 0 ? 0 : range - 1)}>
                                                <use xlinkHref={`${icons}#circle-minus-filled`} />
                                            </svg>
                                            <svg className='chart-icon small' onClick={() => setRange(range + 1)}>
                                                <use xlinkHref={`${icons}#circle-plus-filled`} />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <VictoryChart theme={VictoryTheme.material} domainPadding={{ x: 20, y: 20 }} height={250} padding={{ top: 0, left: 50, right: 0, bottom: 30 }}>
                                    <VictoryAxis
                                        tickFormat={(tick) => recurrence === 'weekly' ? `${_.split(tick, '-')[0]}\n${_.split(tick, '-')[1]}` : tick}
                                        style={{
                                            axisLabel: { fontSize: 8, padding: 30 },
                                            tickLabels: { fontSize: 8, font: 'oxygen', padding: 5 }
                                        }}
                                    />
                                    <VictoryAxis
                                        dependentAxis
                                        tickFormat={(tick) => `${tick}`}
                                        label={_t && _t.dashboard && _t.dashboard.revenue ? `${_t.dashboard.revenue} ( € )` : 'Revenue ( € )'}
                                        style={{
                                            axisLabel: { fontSize: 8, padding: 35 },
                                            tickLabels: { fontSize: 8, font: 'oxygen' }
                                        }}
                                    />

                                    <VictoryLine
                                        interpolation='monotoneX'
                                        data={getChartData()}
                                        labels={({ datum }) => datum.y > 0 && isThisMonth(datum.date) ? `€${datum.y.toFixed(2)}` : null}
                                        style={{
                                            data: {
                                                stroke: window.Branding.tertiaryColor,
                                                fillOpacity: 0.7,
                                                strokeWidth: 2
                                            },
                                            labels: {
                                                fontSize: 8,
                                            }
                                        }}
                                    />
                                    <VictoryScatter
                                        data={getChartData()}
                                        style={{
                                            data: {
                                                fill: window.Branding.tertiaryColor,
                                                strokeWidth: 2
                                            },
                                            labels: {
                                                fontSize: 8,
                                            }
                                        }}
                                    />
                                </VictoryChart>

                            </div>
                        </div>

                        <div className='horizontal-divider' />

                        <div className='dashboard-item two'>

                            <div className='horizontal-stack' style={{ margin: '0 -10px 0 0' }}>
                                <div className='dashboard-item'>
                                    <button type='submit' onClick={() => setRecurrence('daily')} className={`button small ${recurrence === 'daily' ? 'selected' : ''}`}>{_t.dashboard && _t.dashboard.daily ? _t.dashboard.daily : 'Daily'}</button>
                                </div>
                                <div className='dashboard-item'>
                                    <button type='submit' onClick={() => setRecurrence('weekly')} className={`button small ${recurrence === 'weekly' ? 'selected' : ''}`}>{_t.dashboard && _t.dashboard.weekly ? _t.dashboard.weekly : 'Weekly'}</button>
                                </div>
                                <div className='dashboard-item'>
                                    <button type='submit' onClick={() => setRecurrence('monthly')} className={`button small ${recurrence === 'monthly' ? 'selected' : ''}`}>{_t.dashboard && _t.dashboard.monthly ? _t.dashboard.monthly : 'Monthly'}</button>
                                </div>
                            </div>
                            <div className='vertical-divider' />

                            <div className='headline'>{_t.dashboard && _t.dashboard.revenue ? _t.dashboard.revenue : 'Revenue'}</div>
                            <Card>

                                <div className='space-around align-end' style={{ flexWrap: 'wrap' }}>

                                    <div className='vertical-stack center card-box'>
                                        <label className='footnote'>{_t.dashboard && _t.dashboard.thisMonth ? _t.dashboard[recurrence === 'daily' ? 'today' : (recurrence === 'weekly' ? 'thisWeek' : 'thisMonth')] : 'Current'}</label>
                                        <div className='headline'>
                                            {`€${getRecurrenceData().current.toFixed(2)}`}
                                        </div>
                                    </div>


                                    <div className='card-box center' style={{ padding: '10px 0 10px 0' }}>
                                        {
                                            <label className={`headline ${getRecurrenceData().difference >= 0 ? 'increase' : 'decrease'}`}>
                                                {`${getRecurrenceData().difference >= 0 ? '+' : '-'} €${Math.abs(getRecurrenceData().difference).toFixed(2)}`}
                                            </label>
                                        }
                                    </div>

                                    <div className='vertical-stack center card-box'>
                                        <label className='footnote'>{_t.dashboard && _t.dashboard.previousMonth ? _t.dashboard[recurrence === 'daily' ? 'yesterday' : (recurrence === 'weekly' ? 'previousWeek' : 'previousMonth')] : 'Previous'}</label>
                                        <div className='headline'>
                                            {`€${getRecurrenceData().previous.toFixed(2)}`}
                                        </div>
                                    </div>

                                </div>
                            </Card>

                            <div className='horizontal-divider' />

                            <div className='headline'>{_t.dashboard && _t.dashboard.details ? `${_t.dashboard.details} (${_t.dashboard[recurrence === 'daily' ? 'today' : (recurrence === 'weekly' ? 'thisWeek' : 'thisMonth')].toLowerCase()})` : 'Previous'}</div>
                            <Card>

                                <div className='space-around align-end' style={{ flexWrap: 'wrap' }}>
                                    <div className='vertical-stack center card-box'>
                                        <label className='footnote'>{_t.dashboard && _t.dashboard.paid ? _t.dashboard.paid : 'Paid'}</label>
                                        <div className='headline'>
                                            {getRecurrenceData().paid}
                                        </div>
                                    </div>
                                    <div className='vertical-stack center card-box'>
                                        <label className='footnote'>{_t.dashboard && _t.dashboard.pending ? _t.dashboard.pending : 'Pending'}</label>
                                        <div className='headline'>
                                            {getRecurrenceData().pending}
                                        </div>
                                    </div>
                                    <div className='vertical-stack center card-box'>
                                        <label className='footnote'>{_t.dashboard && _t.dashboard.canceled ? _t.dashboard.canceled : 'Canceled'}</label>
                                        <div className='headline'>
                                            {getRecurrenceData().canceled}
                                        </div>
                                    </div>
                                    <div className='vertical-stack center card-box'>
                                        <label className='footnote'>{_t.dashboard && _t.dashboard.expired ? _t.dashboard.expired : 'Expired'}</label>
                                        <div className='headline'>
                                            {getRecurrenceData().expired}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>

                </Card>

            </div>

            <div className='overlay'>
                <div className='center'>
                    {paymentableTypes.map((paymentableType) =>
                        <svg key={paymentableType} className='dashboard-icon small' style={{ opacity: paymentableType === currentPaymentableType ? 1 : .3 }} onClick={() => setCurrentPaymentableType(paymentableType)}>
                            <use xlinkHref={`${icons}#dot-large`} />
                        </svg>
                    )}
                </div>
            </div>

        </div >

    );
}

export default PaymentsOverview;