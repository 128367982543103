import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage } from 'core/store/base/selectors';

import { camelize } from 'components/Utility/Utility';

import { LocalizationContext } from 'core/context/localizationContext';

export interface ConfirmFormProps {
    action: 'CREATE' | 'EDIT' | 'DELETE'
    title: string
    message: string
    handleConfirm: () => any
    handleClose: () => any
    target?: {} | null

}

const ConfirmForm: FC<ConfirmFormProps> = ({ action, title, message, handleConfirm, handleClose, target = null }) => {
    const language = useSelector(getLanguage);
    const localization = useContext(LocalizationContext);
    const _t = localization![language] ?? localization!['en'];

    return (
        <div className=''>
            <div className='headline'>
                {title}
            </div>

            <div style={{ whiteSpace: 'pre-wrap' }}>
                {message}
            </div>

            <div className='vertical-divider' />

            {target ? Object.keys(target).map((t) => <div key={t}>{`${(_t.model && _t.model[camelize(t)]) ?? t}: ${target[t]}`}</div>) : null}

            <div className='vertical-divider' />

            <div className='horizontal-stack'>
                <div className='dashboard-item'>
                    <button type='submit' onClick={handleClose} className='button'>{_t.subscription && _t.subscription.cancel ? _t.subscription.cancel : 'Cancel'}</button>
                </div>
                <div className='dashboard-item'>
                    <button type='submit' onClick={() => { handleConfirm(); handleClose() }} className={`button ${action === 'DELETE' ? 'danger' : ''}`} >{_t && _t.model ? (action === 'DELETE' ? _t.model.delete : _t.model.confirm) : action}</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmForm;
