import Api from './Api';

const Lesson = {

    index(query: string = '') {
        return Api().get('/lessons' + query);
    },

    store(form: { description: string; instructor: string; start_time: number; end_time: number; available_slots: number }) {
        return Api().post('/lessons/', form);
    },

    update(form: { description: string; instructor: string; start_time: number; end_time: number; available_slots: number }, id: number) {
        return Api().put('/lessons/' + id, form);
    },

    delete(id: number) {
        return Api().delete('/lessons/' + id);
    },

};

export default Lesson;