import React, { FC, useContext } from 'react';

import { addMinutes, getHours, getMinutes, getTime } from 'date-fns';

import { AppointmentType } from '../Interfaces/Interfaces';

import { CalendarContext } from 'containers/Admin/AdminPanel/Calendar/2.0/Context/CalendarContext';

import DropWrapper from 'containers/Admin/AdminPanel/Calendar/2.0/DropWrapper/DropWrapper';
import HalfHour from 'containers/Admin/AdminPanel/Calendar/2.0/Hour/HalfHour';
import Event from 'containers/Admin/AdminPanel/Calendar/2.0/Event/Event';

import './Hour.scss';
import ScheduledEvent from '../Event/ScheduledEvent';

export interface HourProps {
    hour: Date
    events: AppointmentType[]
}

const Hour: FC<HourProps> = ({ hour, events }) => {
    const calendarActions = useContext(CalendarContext);
    const currentDate = new Date();
    const currentTime = getHours(hour) === getHours(currentDate);
    const minutes = getMinutes(currentDate);
    const offset = (minutes / 30) * 100;

    return (
        <div className={`hour-container`}>

            {[0, 1].map((i) => {
                return (
                    <DropWrapper key={`${i}-${getTime(hour)}`} timestamp={getTime(addMinutes(hour, (i * 30)))}>
                        <HalfHour>
                            {events.map((event) => event.start_time === getTime(addMinutes(hour, (i * 30))) ? (calendarActions?.type === 'CALENDAR' ? <Event key={event.id} event={event} /> : <ScheduledEvent key={event.id} event={event} />) : null)}

                            {currentTime && (minutes >= (i * 30 + i) && minutes <= ((i + 1) * 30))
                                ? <div
                                    className='current-time'
                                    style={{
                                        top: `${offset > 100 ? offset - 100 : offset}%`
                                    }}
                                />
                                : null
                            }
                        </HalfHour>
                    </DropWrapper>
                );
            })}
        </div>

    )
}

export default Hour;
