interface Translations {
    [language: string] : {
        [accessor: string]: string
    }
}

const translations: Translations = {
    nl: {
        confirmMessage: 'Weet u het zeker?',
        confirm: 'Bevestig',
        cancel: 'Annuleer',
    },

    en: {
        confirmMessage: 'Are you sure?',
        confirm: 'Confirm',
        cancel: 'Cancel',
    }
}

export default translations;