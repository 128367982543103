import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import queryString from 'query-string'

import Store from 'core/store/store';
import { setCalendars, setAppointments, setAnnouncements } from 'core/store/admin/action';
import { getCalendars, getAppointments, getAnnouncements } from 'core/store/admin/selectors';
import { getUser } from 'core/store/user/selectors';

import Calendar from 'core/apis/Calendar';
import Appointment from 'core/apis/Appointment';
import Announcement from 'core/apis/Announcement';


import { content } from 'containers/UserPanel/Sidebar/UserSidebarContent';

import Home from 'containers/UserPanel//Home/Home';
import Profile from 'containers/UserPanel/Profile/User/Profile';
import AppointmentsOverview from 'containers/UserPanel/Appointments/Overview/AppointmentsOverview';
import PrefilledSchedule from 'containers/UserPanel/Appointments/Schedule/PrefilledSchedule';
import { Layout } from 'containers/Layout';

const UserPanel = () => {
    const parsed = queryString.parse(window.location.search);

    const [activePage, setActivePage] = useState<number>(0);

    const calendars = useSelector(getCalendars);
    const appointments = useSelector(getAppointments);
    const announcements = useSelector(getAnnouncements);
    const user = useSelector(getUser);

    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        const setup = () => {

            if (!calendars.length) {
                dispatchCalendars();
            }

            if (!appointments.length) {
                dispatchAppointments();
            }

            if (!announcements.length) {
                dispatchAnnouncements();
            }

            if (!_.isEmpty(parsed)) {
                const component = _.find(content.filter((c) => c.title === parsed.section), { pages: [{ title: parsed.page }] });
                const tag = component ? _.find(component.pages, (page) => page.title === parsed.page)!.tag : 0;
                setActivePage(tag);
            } else {
                setActivePage(0);
            }

            setInitialized(true);
        }

        if (!initialized) {
            setup();
        }
    }, [initialized, calendars, appointments, announcements, parsed]);

    const dispatchCalendars = async () => {
        const request = await Calendar.index();
        Store.dispatch(setCalendars({ calendars: request.data }));
    }

    const dispatchAppointments = async () => {
        const request = await Appointment.index();
        Store.dispatch(setAppointments({ appointments: request.data }));
    }

    const dispatchAnnouncements = async () => {
        const request = await Announcement.index();
        Store.dispatch(setAnnouncements({ announcements: request.data }));
    }

    const renderPage = (page: number | undefined) => {
        switch (page) {
            case 0:
                return <Home message={'Welkom in de Time Stables app!'} announcements={announcements} appointments={appointments.filter((appointment) => appointment.user_id === user.id)} horses={user.horses} />;
            case 1:
                return <Profile />;
            case 2:
                return <div>COMING SOON...</div>;
            case 3:
                return <AppointmentsOverview appointments={appointments.filter((appointment) => appointment.user_id === user.id)} calendars={calendars} />;
            case 4:
                return <PrefilledSchedule appointments={appointments} calendars={calendars} />;
            default:
                return;
        }
    }

    return (
        activePage !== undefined
            ? <div>
                <Layout
                    navigation={content}
                    activePage={activePage}
                    setActivePage={setActivePage}
                >
                    {renderPage(activePage)}
                </Layout>
            </div>
            : null

    )
}

export default UserPanel;
