import { REHYDRATE } from 'redux-persist';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LOCALES = 'SET_LOCALES';

interface SetLanguageAction {
  type: typeof SET_LANGUAGE
  payload: string
}

interface SetLocalesAction {
  type: typeof SET_LOCALES
  payload: {}
}

interface RehydrateAction {
    type: typeof REHYDRATE,
    payload: null
}

export type BaseActionTypes = SetLanguageAction | SetLocalesAction | RehydrateAction;
