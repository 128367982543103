import User from '../../apis/User';

import { Authentication, AUTH_REQUEST_PENDING, SET_LOGGED_IN, SET_TOKEN, SET_VERIFIED, SET_STATUS, RESET_STATE, AuthenticationActionTypes, AppThunk } from './types';

import { setUser } from 'core/store/user/action';

export const authRequestPending = ({ requestPending }: { requestPending: boolean }): AuthenticationActionTypes => ({
    type: AUTH_REQUEST_PENDING,
    payload: requestPending
});

export const setLoggedIn = ({ userLoggedIn }: { userLoggedIn: boolean }): AuthenticationActionTypes => ({
    type: SET_LOGGED_IN,
    payload: userLoggedIn
});

export const setToken = ({ token }: { token: Authentication }): AuthenticationActionTypes => ({
    type: SET_TOKEN,
    payload: token
});

export const setVerified = ({ verified }: { verified: boolean }): AuthenticationActionTypes => ({
    type: SET_VERIFIED,
    payload: verified
});

export const setStatus = ({ userId, username, role, verified, confirmed, blocked }: { userId: Authentication, username: Authentication, role: Authentication, verified: boolean, confirmed: boolean, blocked: boolean }): AuthenticationActionTypes => ({
    type: SET_STATUS,
    payload: { userId: userId, username: username, role: role, verified: verified, confirmed: confirmed, blocked: blocked }
});

export const resetState = (): AuthenticationActionTypes => ({
    type: RESET_STATE,
    payload: null
});

export const login = ({ credentials }: { credentials: { email: string; password: string; device_name: string } }) => {
    return async (dispatch: any) => {
        const asyncRes = await User.login(credentials);

        dispatch(
            authRequestPending({
                requestPending: true
            })
        );

        dispatch(
            setToken({
                token: asyncRes.data.token
            })
        );

        dispatch(
            setLoggedIn({
                userLoggedIn: true
            })
        );

        await dispatch(
            authenticate()
        );
    }
};

export const logout = (): AppThunk => async dispatch => {
    await User.logout();

    dispatch(
        resetState()
    );
};

export const authenticate = (): AppThunk => async dispatch => {
    const asyncRes = await User.auth();

    dispatch(
        authRequestPending({
            requestPending: true
        })
    );

    dispatch(
        setUser({
            user: asyncRes.data.user
        })
    );

    dispatch(
        setStatus({
            userId: asyncRes.data.user.id,
            username: asyncRes.data.user.name,
            role: asyncRes.data.role,
            verified: asyncRes.data.user.email_verified_at ? true : false,
            confirmed: asyncRes.data.user.confirmed ? true : false,
            blocked: asyncRes.data.user.blocked ? true : false
        })
    );

    dispatch(
        authRequestPending({
            requestPending: false
        })
    );
};

export const register = ({ credentials }: { credentials: { name: string, email: string; password: string; password_confirmation: string } }) => {
    return async (dispatch: any) => {
        await User.register(credentials);

        await dispatch(
            login({
                credentials: {
                    email: credentials.email,
                    password: credentials.password,
                    device_name: 'browser'
                }
            })
        )

        // await User.requestEmailVerification();
    }
};