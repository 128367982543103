import React, { FC } from 'react';
import { NavigationProps } from './Sidebar';
import Svg from '../Icons/Svg/Svg';

export interface SidebarNavigationProps {
    item: NavigationProps
}

const SidebarNavigation: FC<SidebarNavigationProps> = ({ item }) => {
    return (
        <div key={item.title} className='sidebar-navigation-item' onClick={item.onClick}>
            <Svg className={'sidebar-icon'} style={{ marginRight: '5px' }} icon={item.icon} />
            <div>{item.title}</div>
        </div>
    );
}

export default SidebarNavigation;
