import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { getLanguage, getLocales } from 'core/store/base/selectors';

import { format, isToday } from 'date-fns';

import useWindowDimensions from 'components/CustomHooks/useWindowDimensions';

export interface HeaderProps {
    date: Date
}

const Header: FC<HeaderProps> = ({ date }) => {
    const { width } = useWindowDimensions();

    const language = useSelector(getLanguage);
    const locales = useSelector(getLocales);

    const dateFormat = width >= 1200 ? 'iiii' : (width >= 850 ? 'iii' : 'iiiii');

    return (
        <div className={`day-header ${isToday(date) ? 'today' : ''}`}>
            <h6>{`${format(date, dateFormat, { locale: locales[language] })} - ${format(date, 'dd-MM')}`}</h6>
        </div>
    )
}

export default Header;
