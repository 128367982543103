import axios from 'axios';
import Store from '../store/store';

let BaseApi = axios.create({
    baseURL: window.Config.api.url,
});

let Api = function () {
    let token = Store.getState().authentication.token;

    BaseApi.defaults.headers.common['X-localization'] = 'nl';
    // BaseApi.defaults.headers.common['X-localization'] = navigator.language.trim().split(/-|_/)[0];

    if (token) {
        BaseApi.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    return BaseApi;
}

let BlobApi = axios.create({
    baseURL: window.Config.api.url,
    responseType: 'blob'
});

export const DownloadApi = function () {
    let token = Store.getState().authentication.token;

    BlobApi.defaults.headers.common['X-localization'] = 'nl';
    BlobApi.defaults.headers.common['X-localization'] = navigator.language.trim().split(/-|_/)[0];

    if (token) {
        BlobApi.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    return BlobApi;
}

export default Api;
