import React, { Dispatch, FC, RefObject, SetStateAction, useEffect, useState } from 'react';

import Collapse from '../Animation/Collapse/Collapse';
import SidebarNavigation from './SidebarNavigation';
import SidebarLabel from './SidebarLabel';
import SidebarPage from './SidebarPage';

import { PageProps } from './SidebarPage';

import Svg from '../Icons/Svg/Svg';

import './Sidebar.scss';

export interface ContentProps {
    title: string
    icon: string
    pages: PageProps[]
}

export interface NavigationProps {
    title: string
    icon: string
    onClick: () => void
}

export interface SidebarProps {
    header?: string;
    sidebarRef?: RefObject<HTMLDivElement>
    navigation?: NavigationProps[]
    title: string
    content: ContentProps[]
    onSelect: Dispatch<SetStateAction<number>>
    selected?: number;
    width?: number
    footer?: JSX.Element | string
    standalone?: boolean
    collapsed?: boolean
    setCollapsed?: Dispatch<SetStateAction<boolean>>
}

const Sidebar: FC<SidebarProps> = ({ header, sidebarRef, navigation, title, content, onSelect, selected, width, footer, collapsed, setCollapsed, standalone = true }) => {
    const [activePage, setActivePage] = useState<number>(selected ?? 0);
    // const [collapsed, setCollapsed] = useState<boolean>(false);

    const sidebarStyle = {
        width: width
    }

    const handleClick = (e: { target: any; }) => {
        // const current = sidebarRef.current;
        // if (current && current!.contains(e.target)) {
        //     return;
        // }
        // setToggled(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    });

    return (
        <div ref={sidebarRef} className={`sidebar-container ${collapsed ? 'hide' : ''} ${standalone ? 'standalone' : ''}`} style={sidebarStyle}>

            {setCollapsed ?
                <div onClick={() => setCollapsed(!collapsed)}>
                    {collapsed
                        ? <Svg icon={'hamburger'} className='sidebar-button' />
                        : <Svg icon={'cross'} className='sidebar-button small' />
                    }
                </div>
                : null
            }
            <div className={`sidebar-wrapper ${collapsed ? 'hide' : 'show'}`}>
                <div className='top-bar'>
                    {header
                        ? <div className='sidebar-header-container'><img className='sidebar-header' src={header} alt='header' /></div>
                        : null}

                </div>

                <div className='sidebar-navigation'>
                    {navigation ? navigation.map((nav) => <SidebarNavigation key={nav.title} item={nav} />) : null}
                </div>

                <div className='sidebar-content'>

                    <div className='sidebar-title'>
                        <span>{title}</span>
                    </div>

                    {content.map((section) => {
                        const selectedSection = section.pages.find((page) => page.tag === activePage);
                        return (
                            <Collapse
                                collapsed={!selectedSection}
                                key={section.title}
                                showLabel={
                                    <SidebarLabel
                                        title={section.title}
                                        icon={section.icon}
                                        open={false}
                                    />
                                }
                                hideLabel={
                                    <SidebarLabel
                                        title={section.title}
                                        icon={section.icon}
                                        open
                                    />
                                }
                            >
                                {section.pages.map((page) =>
                                    <div key={page.tag} onClick={() => {
                                        setActivePage(page.tag)
                                        onSelect(page.tag)
                                        // setCollapsed(true)
                                    }}>
                                        <SidebarPage page={page} selected={page.tag === activePage} />
                                    </div>
                                )}
                            </Collapse>
                        );
                    })}
                </div>

                <div className='sidebar-footer'>
                    {footer}
                </div>


            </div>

        </div>
    )
}

export default Sidebar;
