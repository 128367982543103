import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import Store from 'core/store/store';
import { setLessons } from 'core/store/admin/action';
import { getLessons } from 'core/store/admin/selectors';

import Lesson from 'core/apis/Lesson';

import Modal from 'components/Modal/Modal';
import Card from 'components/Card/Card';
import LessonForm from 'containers/Admin/AdminPanel/ModelForm/LessonForm';
import ConfirmForm from 'containers/Admin/AdminPanel/ModelForm/ConfirmForm';

import CrudTable from 'containers/Admin/AdminPanel/CrudTable/CrudTable';

interface LessonType {
    id: number;
    description: string;
    instructor: string;
    start_time: number;
    end_time: number;
    available_slots: number;
    created_at: string;
    updated_at: string;
}

const _hiddenAttributes = [
    'updated_at'
];

const LessonIndex = () => {

    const lessons = useSelector(getLessons);

    const [initialized, setInitialized] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<string>('');
    const [target, setTarget] = useState<LessonType | null>();

    useEffect(() => {
        const setup = () => {
            const adminState = Store.getState().admin;

            if (_.isEmpty(adminState.users)) {
                dispatchLessons();
            }

            setInitialized(true);
        }

        if (!initialized) {
            setup();
        }
    }, [lessons, initialized]);

    const dispatchLessons = async () => {
        const request = await Lesson.index();
        Store.dispatch(setLessons({ lessons: request.data }));
    }

    const onCreate = async () => {
        setShowModal('LessonForm');
    }

    const onEdit = (target: LessonType) => {
        setTarget(target);
        setShowModal('LessonForm');
    }

    const onDelete = (target: LessonType) => {
        setTarget(target);
        setShowModal('ConfirmForm');
    }

    const handleClose = () => {
        setShowModal('');
        setTarget(null);
    }

    return (
        <div>
            <Card>
                <CrudTable key={'calendars'} model={_.map(lessons, lesson => _.omit(lesson, _hiddenAttributes))} onEditAction={onEdit} onDeleteAction={onDelete} onCreateAction={onCreate} />
            </Card>

            <Modal show={showModal === 'LessonForm'} handleClose={handleClose}>
                <LessonForm target={target} handleClose={() => { dispatchLessons(); handleClose() }} />
            </Modal>

            <Modal show={showModal === 'ConfirmForm'} handleClose={handleClose}>
                <ConfirmForm target={target} title='Verwijder les' message='Weet u zeker dat u deze les wilt verwijderen?' action='DELETE' handleConfirm={async () => { await Lesson.delete(target!.id); dispatchLessons(); }} handleClose={handleClose} />
            </Modal>
        </div>
    );
}

export default LessonIndex;