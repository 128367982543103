import { REHYDRATE } from 'redux-persist';

export interface UserState {
    user: UserType
    appointments: AppointmentType[]
};

export const SET_USER = 'SET_USER';
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';

interface SetUserAction {
  type: typeof SET_USER
  payload: UserType
}

interface SetAppointmentAction {
  type: typeof SET_APPOINTMENTS
  payload: AppointmentType[]
}

interface RehydrateAction {
    type: typeof REHYDRATE,
    payload: null
}

export type UserActionTypes = SetUserAction | SetAppointmentAction | RehydrateAction;
