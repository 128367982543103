import Api from './Api';

const Payment = {

    index(query: string = '') {
        return Api().get('/payments' + query);
    },

};

export default Payment;