window.Branding = {
    primaryColor: '#292933',
    secondaryColor: '#343441',
    tertiaryColor: '#ff8e33',
    // primaryColor: '#974e14',
    // secondaryColor: '#BD9778',
    // tertiaryColor: '#EE9355',
    // primaryColor: '#2C3539',
    // secondaryColor: '#4d5461',
    // tertiaryColor: '#5f6670',

    // textColor: '#e2e2e2',
    textColor: '#1f1f1f',
    textColorLight:  '#ccc',
    bodyColor: '#FFFFFC',
    bodygradientColor: '#F0F0F0',
    cardColor: '#ffffff',

    iconButtonFill: '#ffffff'

}

export { }
