import React from 'react';

import './Card.scss';

type Props = {
    children: any;
    // scroll?: boolean;
    padding?: string;
    width?: string;
    height?: string;
    header?: string | JSX.Element;
};

const Card = ({ children, padding = '1.5rem', width = '100%', height = '', header = '' }: Props) => {

    return (

        <div className='' style={{ width: width }}>

            <div className=''>
                {header ? <div key='cardHeader' className='cardHeader'>{header}</div> : ''}
                <div key='cardRoot' className={height ? 'card scroll' : 'card'} style={{ padding: padding, height: height }}>
                    <div key='cardInner' style={{ overflow: 'visible' }}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;