import { 
    UserState,
    SET_USER, 
    SET_APPOINTMENTS,
    UserActionTypes 
} from './types';

import { REHYDRATE } from 'redux-persist';

const initialState: UserState = {
    user: {} as UserType,
    appointments: [] as AppointmentType[]
};

export const userReducer = (state = initialState, action: UserActionTypes) => {
    switch (action.type) {

        case SET_USER: {
            return {
                ...state,
                user: action.payload
            };
        }

        case SET_APPOINTMENTS: {
            return {
                ...state,
                appointments: action.payload
            };
        }

        case REHYDRATE: {
            return state;
        }

        default: {
            return {
                ...state
            }
        }

    }
};