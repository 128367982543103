// import React from 'react'

// import './Spinner.scss'

// type Props = {
//     color: string,
//     className?: string,
// }

// const Spinner = ({ color = '#7f58af', className = '' }: Props) => {
//     const circles = [...Array(8)].map((_, index) => {
//         return (
//             <div key={index}>
//                 <div className={'div-after'} style={{ background: color }}></div>
//             </div>
//         )
//     })

//     return (
//         <div className={'lds-roller ' + className}>
//             { circles}
//         </div>
//     )
// }

// export default Spinner;

import React, { FC } from 'react';

import './Spinner.scss';

export interface SpinnerProps {
    size?: number
    color?: string
    className?: string
}

const Spinner: FC<SpinnerProps> = ({ size = 30, color, className }) => {
    return (
        <div
            className={className ?? 'spinner'}
            style={{
                width: size,
                height: size,
                borderTop: `${size / 10}px solid ${color}`
            }}
        />
    );
}

export default Spinner;
