import React, { FC } from 'react';

import icons from 'assets/svg/icons.svg';

export interface IconProps {
    icon: string
    style?: {}
    fill?: string
    width?: string | number
    height?: string | number
    className?: string
}

const Svg: FC<IconProps> = ({
    icon,
    style = {},
    fill = '#fff',
    width = '100%',
    height = '100%',
    className = '',
}) =>
    <svg
        style={{ ...style, fill: fill }}
        width={width}
        height={height}
        className={className}
    >
        <use xlinkHref={`${icons}#${icon}`} />
    </svg>;

export default Svg;
