
interface RootState {
    authentication: {
        requestPending: boolean,
        userId: number | null;
        username: string,
        role: string,
        verified: boolean,
        confirmed: boolean,
        blocked: boolean,
        token: string,
        userLoggedIn: boolean,
    }
}

export const getVerifiedStatus = (state: RootState) => state.authentication.verified;
export const getConfirmedStatus = (state: RootState) => state.authentication.confirmed;
export const getBlockedStatus = (state: RootState) => state.authentication.blocked;
export const getRole = (state: RootState) => state.authentication.role;
