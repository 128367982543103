import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Store from 'core/store/store';
import { login } from 'core/store/authentication/action';

import Card from 'components/Card/Card';

import { LocalizationContext } from 'core/context/localizationContext';

const LoginForm = (props) => {
    const refs = {
        email: useRef(null),
        password: useRef(null),
        login: useRef(null)
    }

    const history = useHistory();
    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    const [formData, setFormData] = useState({ email: localStorage.getItem('lastUsername') ?? '', password: '', device_name: 'browser' });
    const [validationErrors, setValidationErrors] = useState<{ email: string[], password: string[], login: string[] }>({ email: [], password: [], login: [] });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleLogin = async () => {
        await Store.dispatch(login({ credentials: formData })).catch(error => {
            if (error.response) {
                setValidationErrors(error.response.data.errors);
            }
            else {
                setValidationErrors({ email: [], password: [], login: ['connectionRefused'] })
            }
        });

        if (Store.getState().authentication.userLoggedIn && !Store.getState().authentication.requestPending) {
            localStorage.setItem('lastUsername', formData.email);

            history.push({
                pathname: props.location.state ? props.location.state.from.pathname : '/home',
            });
        }
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, value: string | null = null) => {
        let key = e.key || e.keyCode;
        if (key === 'Enter' || key === 13) {
            if (value) {
                refs[value || 'null'].current.focus();
            }
            else {
                handleLogin();
            }
        }
    }

    return (
        <div className='form-container center'>

            <Card padding={'5rem'}>

                <div className='center'>
                    <img style={{ width: '30%', height: 'auto', paddingBottom: '3rem' }} src={props.logo} alt='App' />
                </div>

                <label className='label'>{_t ? _t.authentication.username : 'Username'}</label>
                <input
                    type='email'
                    className='input'
                    // className={'input' + (validationErrors.email ? ' warning' : '')}
                    id='email'
                    name='email'
                    ref={refs.email}
                    defaultValue={ localStorage.getItem('lastUsername') ?? '' }
                    onChange={handleChange}
                    onKeyUp={(e) => handleKeyUp(e, 'password')}
                />
                <div className='validation-text danger'>
                    {validationErrors.hasOwnProperty('email') && validationErrors.email.length ? validationErrors.email.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                </div>

                <label className='label'>{_t ? _t.authentication.password : 'Password'}</label>
                <input
                    type='password'
                    v-model='form.password'
                    className='input'
                    // className={'input' + (validationErrors.hasOwnProperty('password') && validationErrors.password.length ? ' warning' : '')}
                    id='password'
                    name='password'
                    ref={refs.password}
                    onChange={handleChange}
                    onKeyUp={(e) => handleKeyUp(e)}
                />
                <div className='validation-text danger'>
                    {validationErrors.hasOwnProperty('password') && validationErrors.password.length ? validationErrors.password.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                </div>

                <div style={{textDecoration: 'underline'}} onClick={() => history.push('/reset')} className='validation-text interactive-div justify-end'>{_t ? _t.authentication.passwordForgotten : 'Reset password'}</div>
                <button type='submit' onClick={handleLogin} ref={refs.login} className='button'>{_t ? _t.authentication.login : 'Login'}</button>
                <div className='validation-text danger center'>
                    {validationErrors.hasOwnProperty('login') && validationErrors.login.length ? validationErrors.login.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                </div>

                <div className='horizontal-stack'>
                    <div className='validation-text'>{_t ? _t.authentication.unregistered : 'No account?'}</div>
                    <div style={{textDecoration: 'underline'}} onClick={() => history.push('/register')} className='validation-text interactive-div'>{_t ? _t.authentication.register : 'Register'}</div>
                </div>

            </Card>

        </div>
    )
}

export default LoginForm;