import React, { FC } from 'react';

import {
    startOfDay as getStartOfDay,
    addHours,
    getTime,
    isSameHour
} from 'date-fns';

import { AppointmentType } from '../Interfaces/Interfaces';

import Header from 'containers/Admin/AdminPanel/Calendar/2.0/Day/Header';
import Hour from 'containers/Admin/AdminPanel/Calendar/2.0/Hour/Hour';

import './Day.scss';

export interface DayProps {
    date: Date
    events: AppointmentType[]
    consecutive?: number
}

const Day: FC<DayProps> = ({ date, events, consecutive = 7 }) => {
    const startOfDay = getStartOfDay(date);

    const renderHours = (): JSX.Element[] => {
        const hours: JSX.Element[] = [];
        for (let i = 8; i < 21; i++) {
            const hour = addHours(startOfDay, i);
            const eventsThisHour = events.filter(e => isSameHour(new Date(e.start_time), hour));

            hours.push(<Hour key={getTime(hour)} hour={hour} events={eventsThisHour} />);
        }
        return hours;
    }

    return (
        <div className='day-col' style={{ width: `${100 / consecutive}%` }}>
            <Header date={date} />
            <div className='day-spacing' />
            {renderHours()}
        </div>
    )
}

export default Day;
