import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { startOfHour, addHours } from 'date-fns';

import Store from 'core/store/store';
import { setUsers, setAppointments, setCalendars } from 'core/store/admin/action';
import { getAppointments, getCalendars } from 'core/store/admin/selectors';

import User from 'core/apis/User';
import Appointment from 'core/apis/Appointment';
import Calendar from 'core/apis/Calendar';

import Modal from 'components/Modal/Modal';
import Card from 'components/Card/Card';
import AppointmentForm from 'containers/Admin/AdminPanel/ModelForm/AppointmentForm';
import ConfirmForm from 'containers/Admin/AdminPanel/ModelForm/ConfirmForm';

// import WeekCalendar from 'containers/Admin/AdminPanel/Calendar/WeekCalendar/WeekCalendar';
import CalendarComponent from 'containers/Admin/AdminPanel/Calendar/2.0/Calendar/Calendar';
import CalendarProvider from 'containers/Admin/AdminPanel/Calendar/2.0/Context/CalendarContext';
import { Confirmation } from 'components/Button';

interface AppointmentType {
    id: number;
    user_id: number;
    calendar_id: number;
    confirmed: boolean;
    type: string;
    comments: string;
    start_time: number;
    end_time: number;
    created_at: string;
    updated_at: string;
    recurrence: string;
    appointment_id: number;
}

const AppointmentSchedule = () => {

    const appointments = useSelector(getAppointments);
    const calendars = useSelector(getCalendars);

    const [initialized, setInitialized] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<string>('');
    const [target, setTarget] = useState<AppointmentType | null>();
    const [selectedDate, setSelectedDate] = useState<Date>(startOfHour(addHours(new Date(), 1)));

    useEffect(() => {
        const setup = () => {
            const adminState = Store.getState().admin;

            if (_.isEmpty(adminState.users)) {
                dispatchUsers();
            }

            if (_.isEmpty(adminState.appointments)) {
                dispatchAppointments();
            }

            if (_.isEmpty(adminState.calendars)) {
                dispatchCalendars();
            }

            setInitialized(true);
        }

        if (!initialized) {
            setup();
        }
    }, [appointments, selectedDate, initialized]);

    const dispatchUsers = async () => {
        const request = await User.index();
        Store.dispatch(setUsers({ users: request.data }));
    }

    const dispatchAppointments = async () => {
        const request = await Appointment.index();
        Store.dispatch(setAppointments({ appointments: request.data }));
    }

    const dispatchCalendars = async () => {
        const request = await Calendar.index();
        Store.dispatch(setCalendars({ calendars: request.data }));
    }

    const onCreate = (date: Date) => {
        setSelectedDate(date);
        setShowModal('AppointmentForm');
    }

    const onEdit = (target: AppointmentType) => {
        setTarget(target);
        setShowModal('AppointmentForm');
    }

    const onDelete = (target: AppointmentType) => {
        setTarget(target);
        setShowModal('ConfirmForm');
    }

    const onDrop = async (form: AppointmentType, target: number) => {
        await Appointment.update(form, target);
        dispatchAppointments();
    }

    // const onRefresh = () => async () => {
    //     dispatchAppointments();
    // }

    const handleClose = () => {
        setShowModal('');
        setTarget(null);
    }

    const getFilterOptions = () => {
        return _.reduce(calendars, (obj, calendar) => {
            return (obj[calendar.id] = calendar.title, obj);
        }, {});
    }

    const getFilterKey = () => {
        return 'calendar_id';
    }

    return (
        <div>
            {/* <Card>
                <WeekCalendar key={'schedule'} model={appointments} onCreateAction={onCreate} onEditAction={onEdit} onDeleteAction={onDelete} onDragAction={onDrop} onRefreshAction={onRefresh} filterKey={'type'} />
            </Card> */}
            <Card>
                <CalendarProvider actions={{
                    type: 'CALENDAR',
                    onCreate: onCreate,
                    onEdit: onEdit,
                    onDelete: onDelete,
                    onDrop: onDrop,
                    getFilterOptions: getFilterOptions,
                    getFilterKey: getFilterKey
                }}>
                    <CalendarComponent events={appointments} />
                </CalendarProvider>
            </Card>

            <Modal key={selectedDate.toString()} show={showModal === 'AppointmentForm'} handleClose={handleClose}>
                <AppointmentForm target={target} date={selectedDate} handleClose={() => { dispatchAppointments(); handleClose() }} />
            </Modal>

            <Confirmation
                show={showModal === 'ConfirmForm'}
                close={handleClose}
                message={target && target.recurrence ? (!target.appointment_id ? 'Het verwijderen van deze afspraak zal resulteren in het verwijderen van de hele reeks afspraken die op deze afspraak zijn gebaseerd. Weet je het zeker?' : 'Wil je alleen de huidige activiteit verwijderen of wil je deze activiteit en alle toekomstige herhalingen verwijderen?') : 'Weet je zeker dat je deze afspraak wilt verwijderen?'}
                onConfirm={async () => { await Appointment.deleteWithRecurrence(target!.id); dispatchAppointments(); handleClose() }}
                onCancel={target && target.recurrence && !target.appointment_id ? () => handleClose() : async () => { await Appointment.delete(target!.id); dispatchAppointments(); handleClose(); }}
                confirmLabel={target && target.recurrence ? (!target.appointment_id ? 'Ja' : 'Alle toekomstige afspraken') : 'Ja'}
                cancelLabel={target && target.recurrence ? (!target.appointment_id ? 'Nee' : 'Alleen op deze plaats') : 'Nee'}
                dangerous
            />
        </div>
    );
}

export default AppointmentSchedule;