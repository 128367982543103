import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import Store from 'core/store/store';
import { setUsers } from 'core/store/admin/action';
import { getUsers } from 'core/store/admin/selectors';

import User from 'core/apis/User';

import Modal from 'components/Modal/Modal';
import Card from 'components/Card/Card';
import UserForm from 'containers/Admin/AdminPanel/ModelForm/UserForm';
import ConfirmForm from 'containers/Admin/AdminPanel/ModelForm/ConfirmForm';

import CrudTable from 'containers/Admin/AdminPanel/CrudTable/CrudTable';

const _hiddenAttributes = [
    'roles',
    'subscriptions',
    'confirmed',
    'blocked',
    'email_verified_at',
    'updated_at'
];

const UserIndex = () => {

    const users = useSelector(getUsers);

    const [initialized, setInitialized] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<string>('');
    const [target, setTarget] = useState<UserType | null>();

    useEffect(() => {
        const setup = () => {
            const adminState = Store.getState().admin;

            if (_.isEmpty(adminState.users)) {
                dispatchUsers();
            }

            setInitialized(true);
        }

        if (!initialized) {
            setup();
        }
    }, [users, initialized]);

    const dispatchUsers = async () => {
        const request = await User.index();
        Store.dispatch(setUsers({ users: request.data }));
    }

    const onCreate = async () => {
        setShowModal('UserForm');
    }

    const onEdit = (target: UserType) => {
        setTarget(target);
        setShowModal('UserForm');
    }

    const onDelete = (target: UserType) => {
        setTarget(target);
        setShowModal('ConfirmForm');
    }

    const handleClose = () => {
        setShowModal('');
        setTarget(null);
    }

    return (
        <div>
            <Card>
                <CrudTable key={'calendars'} model={_.map(users, user => _.omit(user, _hiddenAttributes))} onEditAction={onEdit} onDeleteAction={onDelete} onCreateAction={onCreate} />
            </Card>

            <Modal show={showModal === 'UserForm'} handleClose={handleClose}>
                <UserForm key={Math.random()} target={target} handleClose={() => { dispatchUsers(); handleClose() }} />
            </Modal>

            <Modal show={showModal === 'ConfirmForm'} handleClose={handleClose}>
            <ConfirmForm target={target} title='Verwijder gebruiker' message='Weet u zeker dat u deze gebruiker wilt verwijderen?' action='DELETE' handleConfirm={async () => { await User.delete(target!.id); dispatchUsers(); }} handleClose={handleClose} />
            </Modal>
        </div>
    );
}

export default UserIndex;