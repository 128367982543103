import Api from './Api';

const Appointment = {

    index(query: string = '') {
        return Api().get('/appointments' + query);
    },

    store(form: { user_id: number; start_time: number; end_time: number; comments: string }) {
        return Api().post('/appointments/', form);
    },

    update(form: { user_id: number; start_time: number; end_time: number; comments: string }, id: number) {
        return Api().put('/appointments/' + id, form);
    },

    delete(id: number) {
        return Api().delete('/appointments/' + id);
    },

    deleteWithRecurrence(id: number) {
        return Api().delete('/appointments/recurring/' + id);
    },

    getAllForUser() {
        return Api().get('/appointments/user');
    },

};

export default Appointment;