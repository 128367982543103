import React, { Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { getYear } from 'date-fns';

import Store from 'core/store/store';
import { getLanguage } from 'core/store/base/selectors';
import { logout, resetState } from 'core/store/authentication/action';

import { LocalizationContext } from 'core/context/localizationContext';
import { AssetContext } from 'core/context/AssetContext';
import { getRole } from 'core/store/authentication/selectors';

import Sidebar, { ContentProps } from 'components/Sidebar/Sidebar';

import useWindowDimensions from 'components/CustomHooks/useWindowDimensions';

export interface LayoutViewProps {
    navigation: ContentProps[]
    activePage: number
    setActivePage: Dispatch<SetStateAction<number>>
    children: ReactNode
}

const LayoutView: FC<LayoutViewProps> = ({ navigation, activePage, setActivePage, children }) => {
    const assets = useContext(AssetContext);
    const role = useSelector(getRole);
    const location = useLocation();

    const history = useHistory();
    const language = useSelector(getLanguage);
    const localization = useContext(LocalizationContext);
    const _t = localization![language] ?? localization!['en'];

    const { width } = useWindowDimensions();
    const [collapsed, setCollapsed] = useState<boolean>(width <= 768 ? true : false);

    const handleLogout = async () => {
        await Store.dispatch(logout()).catch(error => {
            if (error.response.status === 401) { // unauthenticated; (for switchting environments with redux-persist)
                Store.dispatch(resetState());
            }
        });

        history.push({
            pathname: '/',
        });
    }

    const getTranslations = (content: ContentProps[]): ContentProps[] => {
        let translatedContent = _.cloneDeep(content);

        _.forEach(translatedContent, (c) => {
            _.assign(c, { title: _t.sidebar[c.title] ?? c.title });

            _.forEach(c.pages, (p) => {
                _.assign(p, { title: _t.sidebar[p.title] ?? p.title })
            });
        });

        return translatedContent;
    }

    const getNavigation = () => {
        if (role === 'admin') {
            if (location.pathname === '/admin') {
                return [
                    {
                        title: 'Home',
                        icon: 'home',
                        onClick: () => history.push('/home')
                    },
                ];
            } else if (location.pathname === '/home') {
                return [
                    {
                        title: 'Admin',
                        icon: 'admin',
                        onClick: () => history.push('/admin')
                    }
                ];
            }  
        }  
    }

    return (
        <div className='layout-row'>

            <div className='layout-row-item'>
                <Sidebar
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    header={assets!.header}
                    navigation={getNavigation()}
                    title={_t.dashboard.navigation ?? 'Navigation'}
                    content={getTranslations(navigation)}
                    onSelect={setActivePage}
                    selected={activePage}
                    footer={
                        <div>
                            <button className='button tertiary small' style={{ width: 'fit-content', marginBottom: '10px' }} onClick={handleLogout}>{_t ? _t.authentication.logout : 'Logout'}</button>
                            <div style={{ opacity: 0.4 }}>{`© ${getYear(new Date())} ${window.Config.title}`}</div>
                        </div>
                    }

                />
            </div>

            <div className='layout-row-item-stretch'>
                <div className={`main ${collapsed ? 'collapsed' : ''}`}>
                    {children}
                </div>
            </div>

        </div>
    );
}

export default LayoutView;