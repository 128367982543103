import React, { FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import Store from 'core/store/store';
import { authenticate } from 'core/store/authentication/action';

import { getLanguage } from 'core/store/base/selectors';

import { LocalizationContext } from 'core/context/localizationContext';

import Modal from 'components/Modal/Modal';
import UserForm from 'containers/Admin/AdminPanel/ModelForm/UserForm';

export interface PersonalDetailsProps {
    user: UserType
}

const PersonalDetails: FC<PersonalDetailsProps> = ({ user }) => {
    const language = useSelector(getLanguage);
    const localization = useContext(LocalizationContext);
    const _t = localization![language] ?? localization!['en'];

    const [showModal, setShowModal] = useState<boolean>(false);

    const handleClose = async () => {
        setShowModal(false);
        await Store.dispatch(authenticate());
    }


    return (
        <div>
            <div className='property-value'><span className='property'>{`${_t.userpanel.personaldetails.name ?? 'Name'}: `}</span>{user.name}</div>
            <div className='property-value'><span className='property'>{`${_t.userpanel.personaldetails.email ?? 'Email'}: `}</span>{user.email}</div>
            <div className='property-value'><span className='property'>{`${_t.userpanel.personaldetails.phoneNumber ?? 'Phone number'}: `}</span>{user.phone_number}</div>

            <div className='horizontal-divider' />

            {/* <div className='headline'>{_t.userpanel.personaldetails.addressDetails ?? 'Address details'}</div> */}
            <div className='property-value'><span className='property'>{`${_t.userpanel.personaldetails.address ?? 'Address'}: `}</span>{user.address}</div>

            <div className='horizontal-divider' />

            <div className='footer footnote is-clickable' onClick={() => setShowModal(true)}>{_t.userpanel.personaldetails.editDetails ?? 'Edit details'}</div>

            <Modal show={showModal} handleClose={() => setShowModal(false)}>
                <UserForm target={user} handleClose={handleClose} />
            </Modal>

        </div>
    );
}

export default PersonalDetails;
