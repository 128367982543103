import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import Store from 'core/store/store';

import DatePicker from "react-datepicker";
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
    addMinutes,
    getTime,
    startOfHour
} from 'date-fns';

import Appointment from 'core/apis/Appointment';

import { getCalendars } from 'core/store/admin/selectors';

import SelectInput from 'components/Input/SelectInput/SelectInput';

import { LocalizationContext } from 'core/context/localizationContext';

import icons from 'assets/svg/icons.svg';
import LabelInput from 'components/Input/LabelInput/LabelInput';
import { Button, Confirmation } from 'components/Button';

const locales = {
    nl: require('date-fns/locale/nl'),
    en: require('date-fns/locale/en-US')
}

const AppointmentForm = ({ target, handleClose, date = new Date() }) => {

    const [validationErrors, setValidationErrors] = useState({ start_time: [], end_time: [], type: [] });
    const [formData, setFormData] = useState({ start_time: getTime(date), end_time: getTime(addMinutes(date, 60)), type: '', comments: '', recurrence: '' });

    const calendars = useSelector(getCalendars);
    const [selectedCalendar, setSelectedCalendar] = useState<any>({});

    const [showPayment, setShowPayment] = useState<boolean>(false);
    const [showRecurrenceModal, setShowRecurrenceModal] = useState<boolean>(false);

    const [hasError, setHasError] = useState<boolean>(false);

    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (target) {
            setFormData({
                start_time: target.start_time,
                end_time: target.end_time,
                type: target.type,
                comments: target.comments,
                recurrence: target.recurrence
            });
        }

        _.forEach(locales, (locale) => {
            if (locale.code!.split('-')[0] === Store.getState().base.language) {
                registerLocale(Store.getState().base.language, locale);
            }
        });

        if (target) {
            setSelectedCalendar(_.find(calendars, (calendar) => calendar.id === target.calendar_id));
        }
    }, [target, date, calendars]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const storeAppointment = async (update = false, editChildren = false) => {
        setLoading(true);
        const currentUser = Store.getState().authentication.userId;
        const appointment = {
            user_id: currentUser,
            calendar_id: _.find(calendars, (calendar) => calendar.title === formData.type)!.id,
            start_time: getTime(new Date(formData.start_time)),
            end_time: getTime(new Date(formData.end_time)),
            type: formData.type,
            comments: formData.comments,
            recurrence: formData.recurrence,
            edit_children: editChildren
        }

        let errors = {};
        if (update) {
            await Appointment.update(appointment, target!.id).catch(error => {
                errors = error.response.data.errors;
                const messages = _.assign(_.clone(validationErrors), error.response.data.errors);
                setValidationErrors(messages);
            });
        }
        else {
            await Appointment.store(appointment)
                .then(response => {
                    if (response.data.hasOwnProperty('url')) {
                        window.location.href = response.data.url;
                    }
                })
                .catch(error => {
                    errors = error.response.data.errors;
                    const messages = _.assign(_.clone(validationErrors), error.response.data.errors);
                    setValidationErrors(messages);
                });
        }

        if (!Object.keys(errors).some((key) => errors[key].length)) {

            setFormData({
                start_time: 0,
                end_time: 0,
                type: '',
                comments: '',
                recurrence: ''
            });

            setSelectedCalendar(null);
            handleClose();
        }

        setLoading(false);
    }

    const handleNextTempName = () => {
        if (formData.type) {
            setHasError(false);
            let calendar = _.find(calendars, (calendar) => calendar.title === formData.type);
            const startTime = target ? target.start_time : calendar!.time_interval === 60 ? startOfHour(date) : date;
            setFormData({ ...formData, start_time: getTime(startTime), end_time: getTime(addMinutes(startTime, calendar!.time_interval)) });
            setSelectedCalendar(calendar);
            setValidationErrors({ start_time: [], end_time: [], type: [] })
        }
        else {
            setHasError(true);
        }
    }

    const renderSelectForm = () => (
        <div>
            <SelectInput
                required
                className={hasError ? 'warning' : ''}
                label={_t.appointment.type ?? 'Appointment type'}
                value={formData.type}
                placeholder={_t.calendar.selectOption ?? '-- Select an option --'}
                errors={validationErrors.type}
            >
                {calendars.map((calendar, index) => <div key={index} onClick={() => setFormData({ ...formData, type: calendar.title })}>{calendar.title}</div>)}
            </SelectInput>

            <div className='justify-center'>
                <button style={{ width: '50%' }} type='submit' onClick={handleNextTempName} className='button' >{_t ? _t.appointment.continue : 'Continue'}</button>
            </div>
        </div>
    );

    const renderMainForm = () => (
        <div>

            {selectedCalendar.price && selectedCalendar.price > 0 ?
                <div className='vertical-stack'>
                    <label className='label'>{_t ? `${_t.appointment.attention}! ${_t.appointment.paymentRequired}:` : ''}</label>
                    <label className='label'>{`${_t ? _t.appointment.price : ''}: € ${selectedCalendar.price} / ${selectedCalendar.time_interval} min.`}</label>
                    <span>&nbsp;&nbsp;</span>
                </div>
                : null}

            <label className='label'>{(_t ? _t.appointment.time : 'Time') + ' *'}</label>
            <div className='horizontal-stack align-center'>
                <DatePicker
                    selected={new Date(formData.start_time)}
                    onChange={(date: Date) => setFormData({ ...formData, start_time: getTime(date), end_time: getTime(addMinutes(date as Date, selectedCalendar.time_interval ?? 60)) })}
                    timeFormat='HH:mm'
                    timeIntervals={selectedCalendar.time_interval ?? 60}
                    dateFormat='d-MM-yyyy, HH:mm'
                    customInput={<input className='input' />}
                    timeCaption={_t && _t.appointment.time ? _t.appointment.time : 'Time'}
                    locale={Store.getState().base.language}
                    todayButton={_t && _t.calendar ? _t.calendar.today : 'Today'}
                    showTimeSelect
                    // excludeTimes={[1, 2, 3, 4, 5, 6, 7, 8, 20, 21, 22, 23, 0].map((time) => setHours(setMinutes(new Date(), 0), time),)} // use for unavailable hours...
                    showPopperArrow={false}
                />
                <div className='horizontal-spacer' />
                <div className='icon-container'>
                    <svg style={{ width: '2rem', height: '2rem', fill: window.Branding.textColorLight }}>
                        <use xlinkHref={`${icons}#arrow-right`} />
                    </svg>
                </div>
                <div className='horizontal-spacer' />
                <DatePicker
                    selected={new Date(formData.end_time)}
                    onChange={(date: Date) => setFormData({ ...formData, end_time: getTime(date) })}
                    timeFormat='HH:mm'
                    dateFormat='d-MM-yyyy, HH:mm'
                    customInput={<input className='input' />}
                    disabled={true}
                    locale={Store.getState().base.language}
                />
            </div>
            <div className='validation-text danger'>
                {validationErrors && validationErrors.hasOwnProperty('start_time') && validationErrors.start_time.length ? validationErrors.start_time.map((e) => e) : <span>&nbsp;&nbsp;</span>}
            </div>

            {selectedCalendar.price && selectedCalendar.price > 0
                ?
                null
                :
                target
                    ?
                    target.recurrence ? <div style={{ paddingBottom: 20 }} className='label'>{`Dit is een ${_t.appointment[target.recurrence].toLowerCase()} herhaalde afspraak.`}</div> : null
                    :
                    <div>
                        <SelectInput
                            className={hasError ? 'warning' : ''}
                            label={_t.appointment.recurrence}
                            value={_t.appointment[formData.recurrence]}
                            placeholder={_t.calendar.selectOption ?? '-- Select an option --'}
                        >
                            {['daily', 'weekly', 'monthly', 'none'].map((recurrence) => (
                                <div key={recurrence} onClick={() => setFormData({ ...formData, recurrence })}>
                                    {_t.appointment[recurrence]}
                                </div>
                            ))}
                        </SelectInput>
                        <span>&nbsp;&nbsp;</span>
                    </div>

            }

            <LabelInput
                label={_t.appointment.comments ?? 'Comments'}
                name={'comments'}
                handleChange={handleChange}
                defaultValue={target ? formData.comments : ''}
                errors={[]}
            />

            <div className='horizontal-stack justify-stretch'>
                <div style={{ flexGrow: 1 }}>
                    <Button
                        label={_t ? _t.appointment.back : 'Back'}
                        onClick={() => setSelectedCalendar(null)}
                        className='button'
                        disabled={target ? true : false}
                    />
                </div>
                <div className='horizontal-divider' />
                {target
                    ?
                    <div style={{ flexGrow: 1 }}>
                        <Button
                            label={_t ? _t.appointment.submitEdit : 'Edit'}
                            onClick={formData.recurrence ? () => setShowRecurrenceModal(true) : () => storeAppointment(true)}
                            className='button'
                        />
                    </div>
                    :
                    <div style={{ flexGrow: 1 }}>
                        <Button
                            loading={loading}
                            disabled={loading}
                            label={_t ? (selectedCalendar.price && selectedCalendar.price > 0 ? _t.appointment.toPayment : _t.appointment.submit) : 'Submit'}
                            onClick={() => {
                                if (selectedCalendar.price && selectedCalendar.price > 0) {
                                    setShowPayment(true);
                                }
                                else {
                                    storeAppointment();
                                }
                            }}
                            className='button'
                        />
                    </div>
                }
            </div>

            <Confirmation
                show={showRecurrenceModal}
                close={() => setShowRecurrenceModal(false)}
                message={'Wil je de activiteit alleen op deze plaats wijzigen of wil je deze activiteit en alle toekomstige herhalingen wijzigen?'}
                onConfirm={() => { storeAppointment(true, true); setShowRecurrenceModal(false) }}
                onCancel={() => { storeAppointment(true, false); setShowRecurrenceModal(false) }}
                confirmLabel={'Alle toekomstige afspraken'}
                cancelLabel={'Alleen op deze plaats'}
                dangerous
            />
        </div>
    );

    const renderPaymentForm = () => {

        return (
            <div>
                <div className='center'>
                    <label className='label'>{`${_t ? _t.appointment.fulfil : ''}: € ${selectedCalendar.price}`}</label>
                </div>
                <span>&nbsp;&nbsp;</span>
                <div className='horizontal-stack'>
                    <button type='submit' onClick={() => setShowPayment(false)} className='button' >{_t ? _t.appointment.back : 'Close'}</button>
                    <div className='horizontal-divider' />
                    {target
                        ? <button type='submit' onClick={() => storeAppointment(true)} className='button' >{_t ? _t.appointment.submitEdit : 'Edit'}</button>
                        : <button type='submit' onClick={() => storeAppointment()} className='button' >{_t ? _t.appointment.pay : 'Pay'}</button>
                    }
                </div>
            </div>
        );
    }

    return (
        <div>
            <label className='headline center'>{target ? (_t ? _t.appointment.edit + ': ' : 'Edit appointment: ') + target.id + ` - ${selectedCalendar ? selectedCalendar.title : formData.type}` : (_t ? _t.appointment.create : 'New appointment') + (!_.isEmpty(selectedCalendar) ? ` - ${selectedCalendar.title}` : '')}</label>
            <div className='vertical-spacer' />

            {!_.isEmpty(selectedCalendar) ?
                showPayment ? renderPaymentForm() : renderMainForm()
                :
                renderSelectForm()
            }
        </div>
    )
}

export default AppointmentForm;