import React, { FC, useContext, useState } from 'react';

import { getLanguage, getLocales } from 'core/store/base/selectors';
import { getCalendars } from 'core/store/admin/selectors';
import { getUser, getAppointments } from 'core/store/user/selectors';

import { differenceInMinutes, format } from 'date-fns';

import { lightenDarkenColor } from 'components/Utility/Utility';

import { LocalizationContext } from 'core/context/localizationContext';
import { CalendarContext } from 'containers/Admin/AdminPanel/Calendar/2.0/Context/CalendarContext';

import './Event.scss';
import Modal from 'components/Modal/Modal';
import { useSelector } from 'react-redux';
import ConfirmForm from 'containers/Admin/AdminPanel/ModelForm/ConfirmForm';
import getTime from 'date-fns/getTime';

export interface ScheduledEventProps {
    event: AppointmentType
}

const ScheduledEvent: FC<ScheduledEventProps> = ({ event }) => {
    const language = useSelector(getLanguage);
    const locales = useSelector(getLocales);

    const localization = useContext(LocalizationContext);
    const _t = localization![language] ?? localization!['en'];

    const calendarActions = useContext(CalendarContext);

    const calendars = useSelector(getCalendars);
    const calendar = calendars.find((calendar) => calendar.id === event.calendar_id);
    const type = calendar ? calendar.title : '';

    const heightPercentage = (differenceInMinutes(new Date(event.end_time), new Date(event.start_time)) / 30) * 100;
    const [show, setShow] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [handleConfirm, setHandleConfirm] = useState<any>();

    const [isHovered, setIsHovered] = useState<boolean>(false);

    const user = useSelector(getUser);
    const appointments = useSelector(getAppointments);
    const appointment = user && appointments ? appointments.find((appointment) => appointment.start_time === event.start_time && appointment.calendar_id === event.calendar_id && appointment.user_id === user.id) : null;

    const unavailable = (event.comments ? event.comments.split('/')[0] === event.comments.split('/')[1].split(' ')[0] : false) || event.end_time < getTime(new Date());

    const handleHover = (e) => {
        switch (e.type) {
            case 'mouseenter':
                setIsHovered(true);
                break;
            case 'mouseleave':
                setIsHovered(false);
                break;
        }
    }

    const handleOpen = (e: { stopPropagation: () => void; }) => {
        e.stopPropagation();
        setShow(true)
    };

    const handleClose = () => {
        setShow(false)
    };

    return (
        <>
            <div
                className='event'
                style={{
                    height: `${heightPercentage}%`,
                    border: `1px solid ${window.Branding.secondaryColor}`,
                    // backgroundColor: appointment ? window.Branding.tertiaryColor : 'transparent',
                    // color: appointment ? 'white' : window.Branding.textColor,
                    opacity: unavailable && !appointment ? .5 : 1,
                    zIndex: 3,
                    overflow: 'initial',
                    backgroundColor: isHovered ? lightenDarkenColor(appointment ? window.Branding.tertiaryColor : window.Branding.secondaryColor, 40) : appointment ? window.Branding.tertiaryColor : "#4b4f52",
                    cursor: unavailable && !appointment ? 'not-allowed' : 'pointer'
                }}
                onClick={unavailable && !appointment ? () => void(0) : handleOpen}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
            >
                <div>
                    <div className="event-header">
                        {`${event.type} ${heightPercentage <= 100 ? `${format(event.start_time, 'HH:mm')}-${format(event.end_time, 'HH:mm')}` : ''}`}
                    </div>
                    <div>
                        {event.comments.split(' ')[0]}
                    </div>
                </div>



                {heightPercentage > 100 ? 
                <div>
                    {`${format(event.start_time, 'HH:mm')}-${format(event.end_time, 'HH:mm')}`}
                </div> : null}
            </div>

            <Modal key={event.id} show={show} handleClose={handleClose}>

                <div className='space-between'>

                    <div className='window-header'>
                        <div
                            className='window-type'
                            style={{
                                backgroundColor: window.Branding.secondaryColor
                            }}
                        />
                        <div className='horizontal-divider' />
                        <div>{type}</div>
                        <div className='horizontal-divider' />
                        {appointment ? <div className='signed-up'>ingeschreven</div> : null}
                    </div>

                </div>

                <div>
                    <p>{`${format(event.start_time, 'iiii dd MMMM, HH:mm', { locale: locales[language] })}-${format(event.end_time, 'HH:mm', { locale: locales[language] })}`}</p>
                    <p>{event.comments ?? ''}</p>

                    {calendarActions?.onAttach
                        ? (appointment
                            ? <div className='justify-end'><button className='button danger' type='submit' style={{ width: '50%' }} onClick={() => { setConfirm(true); setHandleConfirm(() => () => calendarActions?.onDetach ? calendarActions?.onDetach(appointment.id) : void (0)) }}>{_t && _t.calendar.signOut ? _t.calendar.signOut : 'Sign out'}</button></div>
                            : <div className='justify-end'><button className='button' type='submit' style={{ width: '50%' }} onClick={() => { setConfirm(true); setHandleConfirm(() => () => calendarActions?.onAttach ? calendarActions?.onAttach(event) : void (0)) }}>{_t && _t.calendar.signUp ? _t.calendar.signUp : 'Sign up'}</button></div>)
                        : null}
                </div>


            </Modal>

            <Modal show={confirm} handleClose={() => setConfirm(false)}>
                <ConfirmForm title={appointment ? 'Uitschrijven' : 'Inschrijven'} message={
                    appointment
                        ? 'Weet u zeker dat u zich wilt uitschrijven? Indien er kosten verbonden waren aan het maken van deze afspraak zullen deze niet worden vergoed.'
                        : (calendar && calendar.price ? `Aan het maken van deze afspraak zijn kosten verbonden:\n\n€${calendar.price} voor ${calendar.time_interval} minuten\n\nAls u bevestigd zult u doorgestuurd worden naar een betaalpagina.` : `Inschrijven voor de '${type}' van ${format(event.start_time, 'HH:mm')}-${format(event.end_time, 'HH:mm')} uur?`)
                } action='EDIT' handleConfirm={handleConfirm} handleClose={() => setConfirm(false)} />
            </Modal>
        </>
    );
}

export default ScheduledEvent;
