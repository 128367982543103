import React from 'react';

import './Notification.scss';

type Props = {
    children: any;
    color?: string;
};

const Notification = ({ children, color = 'red' }: Props) => {

    return (

        <div className='notification center' style={{ backgroundColor: color }}>
            {children}
        </div>
    );
};

export default Notification;