import React, { FC, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import { getLanguage, getLocales } from 'core/store/base/selectors';

import { AppointmentType } from '../Interfaces/Interfaces';

import Week from 'containers/Admin/AdminPanel/Calendar/2.0/Week/Week';
import { addDays, endOfWeek, getISOWeek, getMonth, isSameWeek, subDays } from 'date-fns';

import { LocalizationContext } from 'core/context/localizationContext';
import { CalendarContext } from 'containers/Admin/AdminPanel/Calendar/2.0/Context/CalendarContext';

import Timeline from '../Timeline/Timeline';
import DropdownMenu from 'components/Dropdown/DropdownMenu/DropdownMenu';
import Checkbox from 'components/Input/Checkbox/Checkbox';

import './Calendar.scss';

import icons from 'assets/svg/icons.svg';
import format from 'date-fns/format';

export interface CalendarProps {
    events: AppointmentType[]
    initialDate?: Date
    initialRange?: number
}

const Calendar: FC<CalendarProps> = ({ events, initialDate = new Date(), initialRange = 7 }) => {
    const [date, setDate] = useState<Date>(initialDate);
    const [range, setRange] = useState<number>(initialRange);

    const language = useSelector(getLanguage);
    const locales = useSelector(getLocales);
    const localization = useContext(LocalizationContext);
    const _t = localization![language] ?? localization!['en'];

    const calendarActions = useContext(CalendarContext);
    const [filterState, setFilterState] = useState<{}>({});
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        if (!initialized && _.isEmpty(filterState)) {
            setFilterState(_.mapValues(calendarActions?.getFilterOptions ? calendarActions?.getFilterOptions() : {}, () => true));
        }

        setInitialized(true);
    }, [calendarActions, filterState, initialized]);

    const prevWeek = () => {
        setDate(subDays(date, range));
    }

    const nextWeek = () => {
        setDate(addDays(date, range));
    }

    const setToday = () => {
        setDate(new Date());
    }

    const handleCheckboxChange = (e: { target: { id: any; checked: any; }; }) => {
        setFilterState({ ...filterState, [e.target.id]: e.target.checked });
    }

    const renderNavigation = () => (
        <div className='navigation'>
            <div>
                <button type='submit' onClick={setToday} className='button small'>{'Vandaag'}</button>
            </div>

            <svg className='navigation-icon' onClick={prevWeek}>
                <use xlinkHref={`${icons}#chevron-left`} />
            </svg>

            <svg className='navigation-icon' onClick={nextWeek}>
                <use xlinkHref={`${icons}#chevron-right`} />
            </svg>

            <span className='date'>{getMonth(date) !== getMonth(endOfWeek(date, { weekStartsOn: 1 })) ? `${format(date, 'MMMM', { locale: locales[language] })} - ${format(endOfWeek(date, { weekStartsOn: 1 }), 'MMMM', { locale: locales[language] })} (week ${getISOWeek(date)})` : `${format(date, 'MMMM', { locale: locales[language] })} (week ${getISOWeek(date)})`}</span>
        </div>
    );

    const renderFilterOptions = () => {
        const filterOptions = calendarActions?.getFilterOptions ? calendarActions?.getFilterOptions() : {};

        if (!_.isEmpty(filterOptions) && !_.isEmpty(filterState)) {
            return (
                <div style={{ position: 'relative' }}>
                    <DropdownMenu offset={-80} label={
                        <svg className='navigation-icon'>
                            <use xlinkHref={`${icons}#ellipsis-small`} />
                        </svg>
                    } className='' animation={/*<Ripple />*/null}>
                        {_.map(filterOptions, (title, key) =>
                            <Checkbox key={key} id={key} label={(_t.appointment && _t.appointment[title]) ?? title} onChange={handleCheckboxChange} checked={filterState[key]} />
                        )}
                    </DropdownMenu>
                </div>
            );
        }

        return null;
    }

    return (
        <div>
            <div className='calendar-toolbar'>
                <div className='space-between'>
                    {renderNavigation()}

                    <div className='horizontal-stack align-center'>
                        <div className='horizontal-stack'>
                            <button type='submit' onClick={() => setRange(1)} className='button small'>{_t ? _t.calendar.day : 'Day'}</button>
                            <div className='horizontal-divider' />
                            <button type='submit' onClick={() => setRange(7)} className='button small'>{_t ? _t.calendar.week : 'Week'}</button>
                        </div>
                        <div className='horizontal-divider' />
                        <div>
                            {renderFilterOptions()}
                        </div>
                    </div>
                </div>
            </div>
            <div className='calendar-container'>
                <Timeline />
                <Week
                    key={date.toString()}
                    range={range}
                    startDate={date}
                    events={events.filter((event) => {
                        const filterKey = calendarActions?.getFilterKey ? calendarActions?.getFilterKey() : '';
                        let filterCondition: boolean = true;
                        if (filterKey) {
                            filterCondition = filterState[event[filterKey]];
                        }
                        return filterCondition && isSameWeek(date, new Date(event.start_time), { weekStartsOn: 1 });
                    })}
                />
            </div>
        </div>
    )
}

export default Calendar;
