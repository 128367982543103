import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import queryString from 'query-string';

import User from 'core/apis/User';
import Store from 'core/store/store';

import Card from 'components/Card/Card';
import Spinner from 'components/Spinner/Spinner';

import { LocalizationContext } from 'core/context/localizationContext';

const PasswordResetForm = () => {
    const query = queryString.parse(window.location.search);
    const history = useHistory();

    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    const [loading, setLoading] = useState<boolean>(false);

    const [activeComponent, setActiveComponent] = useState<string>(query.token ? 'token' : 'email');
    const [formData, setFormData] = useState({ email: '', reset_token: query.token ? query.token as string : '', password: '', password_confirmation: '' });
    const [validationErrors, setValidationErrors] = useState<{ email: string[], reset_token: string[], password: string[] }>({ email: [], reset_token: [], password: [] });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleTokenRequest = async () => {
        setLoading(true);
        const res = await User.requestPasswordResetToken(_.pick(formData, ['email'])).catch(error => {
            if (error.response) {
                setValidationErrors(error.response.data.errors);
            }
        });

        setLoading(false);
        if (res && res.status === 200) {
            setActiveComponent('token');
        }
    }

    const handleTokenVerification = async () => {
        setLoading(true);
        const res = await User.verifyPasswordResetToken(_.pick(formData, ['reset_token'])).catch(error => {
            if (error.response) {
                setValidationErrors(error.response.data.errors);
            }
        });

        setLoading(false);
        if (res && res.status === 200) {
            setFormData({ ...formData, reset_token: res.data.token })
            setActiveComponent('password');
        }
    }

    const handleNewPassword = async () => {
        setLoading(true);
        const res = await User.setNewPasswordWithToken(_.pick(formData, ['reset_token', 'password', 'password_confirmation'])).catch(error => {
            if (error.response) {
                setValidationErrors(error.response.data.errors);
            }
        });

        setLoading(false);
        if (res && res.status === 200) {
            history.push({ pathname: '/' })
        }
    }

    return (
        <div className='form-container center'>

            <Card padding={'5rem'}>

                {activeComponent === 'email'
                    ? <div>

                        <label className='headline'>{_t ? _t.authentication.passwordForgotten : 'Forgotten password'}</label>
                        <p className=''>{_t ? _t.authentication.passwordForgottenMessage : ''}</p>
                        <label className='label'>{_t ? _t.authentication.email : 'Email'}</label>
                        <input
                            type='email'
                            className='input'
                            id='email'
                            name='email'
                            onChange={handleChange}
                        />
                        <div className='validation-text danger'>
                            {validationErrors.hasOwnProperty('email') && validationErrors.email.length ? validationErrors.email.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                        </div>
                        <button type='submit' onClick={handleTokenRequest} className='button'>{loading ? <Spinner color={window.Branding.secondaryColor} /> : _t ? _t.authentication.send : 'Send'}</button>
                        <div onClick={() => history.push('/')} className='validation-text interactive-div secondary'>{_t ? _t.authentication.loginReturn : 'Back to login screen'}</div>

                    </div>
                    : activeComponent === 'token'
                        ? <div>
                            <label className='headline'>{_t ? _t.authentication.passwordReset : 'Reset password'}</label>
                            { formData.email ? <p className=''>{_t ? _t.authentication.tokenSent + ' \'' + formData.email + '\'.' : ''}</p> : null}
                            <p className=''>{_t ? _t.authentication.tokenSentMessage : ''}</p>
                            <input
                                type='text'
                                className='input'
                                id='reset_token'
                                name='reset_token'
                                defaultValue={query.token ?? ''}
                                onChange={handleChange}
                            />
                            <div className='validation-text danger'>
                                {validationErrors.hasOwnProperty('reset_token') && validationErrors.reset_token.length ? validationErrors.reset_token.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                            </div>
                            <button type='submit' onClick={handleTokenVerification} className='button'>{_t ? _t.authentication.reset : 'Reset'}</button>
                        </div>
                        : <div>
                            <div>
                                <label className='headline'>{_t ? _t.authentication.passwordReset : 'Reset password'}</label>
                                <p className=''>{_t ? _t.authentication.editPasswordMessage : ''}</p>
                                <label className='label'>{_t ? _t.authentication.password : 'Password'}</label>
                                <input
                                    type='password'
                                    v-model='form.password'
                                    className='input'
                                    id='password'
                                    name='password'
                                    onChange={handleChange}
                                />
                                <div className='validation-text danger'>
                                    {validationErrors.hasOwnProperty('password') && validationErrors.password.length ? validationErrors.password.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                                </div>
                                <label className='label'>{_t ? _t.authentication.passwordConfirmation : 'Confirm password'}</label>
                                <input
                                    type='password'
                                    v-model='form.passwordConfirmation'
                                    className='input'
                                    id='passwordConfirmation'
                                    name='password_confirmation'
                                    onChange={handleChange}
                                />
                                <div className='validation-text danger'>
                                    {validationErrors.hasOwnProperty('password') && validationErrors.password.length ? validationErrors.password.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                                </div>
                                <button type='submit' onClick={handleNewPassword} className='button'>Submit</button>
                            </div>
                        </div>
                }

            </Card>

        </div>
    );
}

export default PasswordResetForm;