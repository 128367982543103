import React, { FC } from 'react';

export interface HalfHourProps {
    isOver?: boolean
}

const HalfHour: FC<HalfHourProps> = ({ isOver, children }) => {
    const className = isOver ? 'highlight-region' : '';

    return (
        <div className={`half-hour ${className}`}>
            {children}
        </div>
    )
}

export default HalfHour;
