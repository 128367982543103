import React, { useContext } from 'react';

import Store from 'core/store/store';

import Card from 'components/Card/Card';

import { LocalizationContext } from 'core/context/localizationContext';

import icons from 'assets/svg/icons.svg';

import './InsightsCard.scss';

const InsightsCard = ({ insights, model, icon }: { insights: Insights, model: string, icon: string }) => {
    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    return (
        <Card>
            <div className='horizontal-stack  space-between'>
                <div>
                    <div className='headline'>{_t.dashboard && _t.dashboard[model] ? _t.dashboard[model] : model}</div>
                    <div className='footnote'>{`Aantal: ${insights.total}`}</div>
                    <div className='footnote'>{`Nieuw deze maand: ${insights.monthlyDifference}`}</div>
                </div>
                <svg className='dashboard-icon large'>
                    <use xlinkHref={`${icons}#${icon}`} />
                </svg>
            </div>

            <hr className='dashboard' />

            {isFinite(insights.monthlyDifferencePercentage) ?
                <div className='horizontal-stack align-center insights-card'>
                    <div className={' align-center ' + (insights.monthlyDifferencePercentage > 0 ? 'increase' : 'decrease')}>
                        <svg className={'dashboard-icon small ' + (insights.monthlyDifferencePercentage > 0 ? 'increase' : 'decrease')}>
                            <use xlinkHref={`${icons}#arrow-${insights.monthlyDifferencePercentage > 0 ? 'up' : 'down'}`} />
                        </svg>
                        {`${Math.abs(insights.monthlyDifferencePercentage).toFixed(2)}%`}
                    </div>
                    <div className='horizontal-divider' />
                    <div className='footnote text-lowercase'>{_t.dashboard ? `${_t.dashboard[insights.monthlyDifferencePercentage > 0 ? 'increase' : 'decrease']} ${_t.dashboard.sinceLastMonth}` : 'since last month'}</div>
                </div>
                : <div className='horizontal-stack align-center insights-card'><div className='footnote'>{_t.dashboard && _t.dashboard.noDataTextPreviousMonth ? _t.dashboard.noDataTextPreviousMonth : 'No data available for previous month'}</div><span>&nbsp;&nbsp;</span></div>
            }

        </Card>
    );
};

export default InsightsCard;