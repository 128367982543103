import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import queryString from 'query-string'

import { Layout } from 'containers/Layout';

import { content } from 'containers/Admin/AdminPanel/Sidebar/AdminSidebarContent';

import Dashboard from 'containers/Admin/AdminPanel/Sections/Dashboard/Dashboard';

import UserIndex from 'containers/Admin/AdminPanel/Sections//Users/UserIndex/UserIndex';
import UserManagement from 'containers/Admin/AdminPanel/Sections/Users/UserManagement/UserManagement';
import CalendarIndex from 'containers/Admin/AdminPanel/Sections//Appointments/CalendarIndex/CalendarIndex';
import HorsesIndex from './Sections/Horses/HorsesIndex/HorsesIndex';
import AppointmentIndex from 'containers/Admin/AdminPanel/Sections//Appointments/AppointmentIndex/AppointmentIndex';
import AppointmentSchedule from 'containers/Admin/AdminPanel/Sections//Appointments/AppointmentSchedule/AppointmentSchedule';
import LessonIndex from 'containers/Admin/AdminPanel/Sections//Lessons/LessonIndex/LessonIndex';

import './AdminPanel.scss';

const AdminPanel = () => {
    const parsed = queryString.parse(window.location.search);

    const [activePage, setActivePage] = useState<number>(0);
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        const setup = () => {

            if (!_.isEmpty(parsed)) {
                const component = _.find(content.filter((c) => c.title === parsed.section), { pages: [{ title: parsed.page }] });
                const tag = component ? _.find(component.pages, (page) => page.title === parsed.page)!.tag : 0;
                setActivePage(tag);
            } else {
                setActivePage(0);
            }

            setInitialized(true);
        }

        if (!initialized) {
            setup();
        }
    }, [parsed, initialized])

    const renderContent = () => {

        switch (activePage) {
            case 0:
                return <Dashboard />;
            case 1:
                return <UserIndex />
            case 2:
                return <UserManagement />
            case 3:
                return <HorsesIndex />
            case 4:
                return <CalendarIndex />
            case 5:
                return <AppointmentIndex />
            case 6:
                return <AppointmentSchedule />
            case 7:
                return <LessonIndex />
            case 8:
            // return <WeekCalendar key={'lessonSchedule'} model={lessons} onCreateAction={onCreateAction} onEditAction={onEditAction} onDeleteAction={onDeleteAction} onDragAction={onDragCalendarItemAction} onRefreshAction={onRefreshAction} filterKey={'instructor'} />;
        }
    }

    return (
        activePage !== undefined
            ? <div>
                <Layout
                    navigation={content}
                    activePage={activePage}
                    setActivePage={setActivePage}
                >
                    {renderContent()}
                </Layout>
            </div>
            : null
    );
}

export default AdminPanel;