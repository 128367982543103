import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import Store from './core/store/store';
// init redux
import './core/store/index';

ReactDOM.render(
    <Provider store={Store.getStore()}>
        <PersistGate loading={null} persistor={Store.getPersistor()}>
        <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
