import { isThisMonth, isSameMonth, subMonths } from 'date-fns';

interface RootState {
    admin: {
        users: UserType[],
        calendars: [
            {
                id: number;
                title: string;
                color: string;
                time_interval: number;
                available_slots: number;
                limit: number | null;
                price: number | null;
                created_at: string;
                updated_at: string;
            }
        ],
        appointments: [
            {
                id: number;
                user_id: number;
                calendar_id: number;
                confirmed: boolean;
                type: string;
                comments: string;
                start_time: number;
                end_time: number;
                created_at: string;
                updated_at: string;
            }
        ],
        lessons: [
            {
                id: number;
                description: string;
                instructor: string;
                start_time: number;
                end_time: number;
                available_slots: number;
                created_at: string;
                updated_at: string;
            }
        ],
        payments: [
            {
                id: number;
                amount: number;
                status: string;
                paymentable_type: string;
                paymentable_id: number;
                created_at: string;
                updated_at: string;
            }
        ],
        horses: HorseType[],
        userActions: [
            {
                id: number;
                user_id: number;
                model: string;
                action: string;
                message: string;
                created_at: string;
                updated_at: string;
            }
        ],
        announcements: [
            {
                id: number;
                message: string;
                created_at: string;
                updated_at: string;
            }
        ]
    }
}

// also declared globally in 'globals.d.ts'
interface Insights {
    total: number;
    monthlyDifference: number;
    monthlyDifferencePercentage: number;
}

export const getUsers = (state: RootState) => state.admin.users;
export const getCalendars = (state: RootState) => state.admin.calendars;
export const getAppointments = (state: RootState) => state.admin.appointments;
export const getLessons = (state: RootState) => state.admin.lessons;
export const getPayments = (state: RootState) => state.admin.payments;
export const getHorses = (state: RootState) => state.admin.horses;
export const getUserActions = (state: RootState) => state.admin.userActions;
export const getAnnouncements = (state: RootState) => state.admin.announcements;

export const getUserInsights = (state: RootState): Insights => {
    const users = state.admin.users;
    return {
        total: users.length,
        monthlyDifference: _calculatemonthlyDifference(users),
        monthlyDifferencePercentage: _calculatemonthlyDifferencePercentage(users),
    }
}

export const getAppointmentInsights = (state: RootState): Insights => {
    const appointments = state.admin.appointments;
    return {
        total: appointments.length,
        monthlyDifference: _calculatemonthlyDifference(appointments),
        monthlyDifferencePercentage: _calculatemonthlyDifferencePercentage(appointments),
    }
}

export const getPaymentInsights = (state: RootState): Insights => {
    const payments = state.admin.payments;
    return {
        total: payments.length,
        monthlyDifference: _calculatemonthlyDifference(payments),
        monthlyDifferencePercentage: _calculatemonthlyDifferencePercentage(payments),
    }
}

const _calculatemonthlyDifference = (stateObject: any[]): number => {
    return stateObject.filter((obj) => isThisMonth(new Date(obj.created_at))).length;
}

const _calculatemonthlyDifferencePercentage = (stateObject: any[]): number => {
    const thisMonth = stateObject.filter((obj) => isThisMonth(new Date(obj.created_at))).length;
    const previousMonth = stateObject.filter((obj) => isSameMonth(new Date(obj.created_at), subMonths(new Date(), 1))).length;
    const percDiff = ((thisMonth - previousMonth) / previousMonth) * 100;
    return percDiff;
}