import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import { useSelector } from 'react-redux';

import Store from 'core/store/store';
// import { authenticate } from 'core/store/authentication/action';
import { setLanguage, setLocales } from 'core/store/base/action';
// import { getLanguage } from 'core/store/base/selectors';

import ThemeSwitcher from 'react-css-vars';
import LocalizationProvider from 'core/context/localizationContext';
import AssetProvider from 'core/context/AssetContext';

import './core/styling/App.scss';
import { header, logo, favicon, localization } from './core/branding/time_stables';

import Root from './containers/Root/Root';

// DEVELOPMENT MODE IMPORTS
import TestView from 'testing/TestView';

const __DEVELOPMENT__ = false;

const App = () => {

    useEffect(() => {
        const setup = async () => {
            const fav = (document.getElementById('favicon') as HTMLLinkElement)
            fav.href = favicon;
            document.title = window.Config.title;

            // const language = navigator.language.trim().split(/-|_/)[0];
            const locales = {
                nl: require('date-fns/locale/nl'),
                en: require('date-fns/locale/en-US')
            }
            Store.dispatch(setLanguage({ language: 'nl' }));
            // Store.dispatch(setLanguage({ language: language }));
            Store.dispatch(setLocales({ locales: locales }));

        }
        setup();
    }, []);

    if (__DEVELOPMENT__) {
        return (
            <div>
                <ThemeSwitcher theme={window.Branding}>

                    <AssetProvider assets={{ header: header, logo: logo, favicon: favicon }}>
                        <LocalizationProvider localization={localization} >
                            <DndProvider backend={HTML5Backend}>
                                <TestView />
                            </DndProvider>
                        </LocalizationProvider>
                    </AssetProvider>

                </ThemeSwitcher>
            </div>
        );
    }
    else {

        return (

            <Router>
                <ThemeSwitcher theme={window.Branding}>

                    <AssetProvider assets={{ header: header, logo: logo, favicon: favicon }}>
                        <LocalizationProvider localization={localization} >
                            <DndProvider backend={HTML5Backend}>
                                <Root logo={logo} />
                            </DndProvider>
                        </LocalizationProvider>
                    </AssetProvider>

                </ThemeSwitcher>
            </Router>

        );

    }

}

export default App;
