import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage, getLocales } from 'core/store/base/selectors';

import { LocalizationContext } from 'core/context/localizationContext';

import Subscription from 'core/apis/Subscription';

import Card from 'components/Card/Card';
import { format } from 'date-fns';

export interface OrderHistoryProps {
    orders: OrderType[]
}

const OrderHistory: FC<OrderHistoryProps> = ({ orders }) => {
    const language = useSelector(getLanguage);
    const locales = useSelector(getLocales);
    const localization = useContext(LocalizationContext);
    const _t = localization![language] ?? localization!['en'];

    const statusStyles = {
        paid: {
            backgroundColor: 'green'
        },
        canceled: {
            backgroundColor: 'red'
        },
        pending: {
            backgroundColor: 'orange'
        },
        expired: {
            backgroundColor: 'gray'
        },
    }

    const downloadInvoice = async (orderId: number) => {
        const request = await Subscription.getInvoice(orderId);

        const blob = request.data
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `invoice-${orderId}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    return (
        <div>
            {orders ? orders.sort((a, b) => b.processed_at.localeCompare(a.processed_at)).map((order, index) => (
                <div key={order.id}>
                    <Card header={
                        index === 0
                            ? <div className='table'>
                                <div className='table-row'>ID</div>
                                <div className='table-row'>{_t.userpanel.orderHistory.date ?? 'Date'}</div>
                                <div className='table-row'>{_t.userpanel.orderHistory.total ?? 'Total'}</div>
                                <div className='table-row'>{_t.userpanel.orderHistory.status ?? 'Status'}</div>
                                <div className='table-row'>{_t.userpanel.orderHistory.invoice ?? 'Invoice'}</div>
                            </div>
                            : ''
                    }>
                        <div className='table align-center'>
                            <div className='table-row'>{order.id}</div>
                            <div className='table-row'>{format(new Date(order.processed_at), 'dd MMMM yyyy, H:mm', { locale: locales[language] })}</div>
                            <div className='table-row'>{`${order.currency} ${(order.total / 100).toFixed(2)}`}</div>
                            <div className='table-row'>
                                <div className='payment-status align-center' style={statusStyles[order.mollie_payment_status]}>
                                    {_t.userpanel.orderHistory[order.mollie_payment_status] ?? 'status'}
                                </div>
                            </div>
                            <div className='table-row'><button className='button small' type='submit' style={{ width: 'fit-content' }} onClick={() => downloadInvoice(order.id)}>Download</button></div>
                        </div>
                    </Card>
                </div>
            )) : null}
        </div>
    );
}

export default OrderHistory;
