import React, { FC } from 'react';

import _ from 'lodash';

import Card from 'components/Card/Card';

import AppointmentComponent from 'containers/UserPanel/Appointments/Overview/AppointmentComponent';

export interface AppointmentOverviewProps {
    appointments: AppointmentType[]
    calendars: CalendarType[]
}

const AppointmentsOverview: FC<AppointmentOverviewProps> = ({ appointments, calendars }) => {
    return (
        <Card header={'Afspraken overzicht'}>
            {_.orderBy(appointments, ['start_time'], ['desc']).map((appointment) => <AppointmentComponent key={appointment.id} appointment={appointment} calendar={_.find(calendars, (calendar) => calendar.id === appointment.calendar_id)} />)}
        </Card>
    )
}

export default AppointmentsOverview;
