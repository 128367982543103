import Api from './Api';

const Horse = {

    index(query: string = '') {
        return Api().get('/horses' + query);
    },

    store(form: { user_ids: number[]; name: string; birth_date: number; chip_number: string; veterinarian: string; farrier: string; }) {
        return Api().post('/horses/', form);
    },

    update(form: { user_ids: number[]; name: string; birth_date: number; chip_number: string; veterinarian: string; farrier: string; }, id: number) {
        return Api().put('/horses/' + id, form);
    },

    delete(id: number) {
        return Api().delete('/horses/' + id);
    },

};

export default Horse;