import Api from './Api';

const Calendar = {

    index(query: string = '') {
        return Api().get('/calendars' + query);
    },

    store(form: { title: string; }) {
        return Api().post('/calendars/', form);
    },

    update(form: { title: string; }, id: number) {
        return Api().put('/calendars/' + id, form);
    },

    delete(id: number) {
        return Api().delete('/calendars/' + id);
    },

};

export default Calendar;