import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import Admin from 'core/apis/Admin';
import User from 'core/apis/User';

import Store from 'core/store/store';
import { setUsers } from 'core/store/admin/action';
import { getUsers } from 'core/store/admin/selectors';

import Card from 'components/Card/Card';
import Tooltip from 'components/Tooltip/Tooltip';
import Notification from 'components/Notification/Notification';
import Modal from 'components/Modal/Modal';

import icons from 'assets/svg/icons.svg';

import { LocalizationContext } from 'core/context/localizationContext';

import './NotificationCenter.scss';
import SubscriptionCard from './SubscriptionCard/SubscriptionCard';

export interface RoleType {
    id: number;
    name: string;
    label: string;
    created_at: string;
    updated_at: string;
}

const NotificationCenter = () => {
    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    const [target, setTarget] = useState<UserType | null>();
    const [action, setAction] = useState<string>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedPlan, setSelectedPlan] = useState<string>('');
    const [confirm, setConfirm] = useState<boolean>(false);

    const users = useSelector(getUsers).filter((user) => !user.blocked);

    const confirmUserWithSubscriptionInvitation = async (user: UserType) => {
        await Admin.confirmUserWithSubscriptionInvitation({ plan: selectedPlan }, user.id);
        dispatchUsers();
        setSelectedPlan('')
        setConfirm(false);
        handleClose();
    }

    const rejectUser = async (user: UserType) => {
        await Admin.blockUser(user.id);
        dispatchUsers();
        handleClose();
    }

    const handleOpen = (user: UserType, action: string) => {
        setTarget(user);
        setAction(action);
        setShowModal(true);
    }

    const handleClose = () => {
        setTarget(null);
        setAction('');
        setShowModal(false);
    }

    const dispatchUsers = async () => {
        const request = await User.index();
        Store.dispatch(setUsers({ users: request.data }));
    }

    return (
        <div>
            <Card header={
                <div className='horizontal-stack align-center space-between'>
                    {_t.dashboard && _t.authentication ? _t.authentication.registerUser + 's' : 'New users'}
                    {_.reduce(users, (result, user) => result + (!user.confirmed ? result + 1 : 0), 0) > 0 ?
                        <Tooltip
                            body={
                                <Notification>
                                    {_.reduce(users, (result, user) => result + (!user.confirmed ? 1 : 0), 0)}
                                </Notification>
                            }
                            hoverText={_.reduce(users, (result, user) => result + (!user.confirmed ? 1 : 0), 0) + ' actie(s) vereist'}
                        />

                        : null}
                </div>
            } height={'643px'}>
                <div className='horizontal-stack headline' style={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
                    <div style={{ flexBasis: '10%' }} />
                    <div style={{ flexBasis: '22.5%' }}>Naam</div>
                    <div style={{ flexBasis: '22.5%' }}>E-mailadres</div>
                    <div style={{ flexBasis: '22.5%' }}>Telefoonnummer</div>
                    <div className='justify-end' style={{ flexBasis: '22.5%' }}>Acties</div>
                </div>

                {_.orderBy(users, ['created_at', 'id'], ['desc', 'asc']).slice(0, 7).map((user) =>
                    <div key={user.id} className='notification-center-row'>
                        <Card key={user.id}>
                            <div className='horizontal-stack align-center'>
                                <div style={{ flexBasis: '10%' }}>
                                    <svg className='dashboard-icon small'>
                                        <use xlinkHref={`${icons}#${user.confirmed ? 'user' : 'user-padlock'}`} />
                                    </svg>
                                </div>
                                <div style={{ flexBasis: '22.5%' }}>{user.name}</div>
                                <div style={{ flexBasis: '22.5%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <a className='underline' href={`mailto:${user.email}`}>{user.email}</a>
                                </div>
                                <div style={{ flexBasis: '22.5%' }}>{user.phone_number}</div>
                                <div className='justify-end' style={{ flexBasis: '22.5%' }}>
                                    {!user.confirmed ?
                                        <div className='horizontal-stack'>
                                            <button type='submit' onClick={() => handleOpen(user, 'confirm')} className='button small'>{_t.dashboard && _t.dashboard.confirm ? _t.dashboard.confirm : 'Confirm'}</button>
                                            <div className='horizontal-divider' />
                                            <button type='submit' onClick={() => handleOpen(user, 'reject')} className='button small danger'>{_t.dashboard && _t.dashboard.reject ? _t.dashboard.reject : 'Reject'}</button>
                                        </div>
                                        :
                                        <div className='footnote italic'>{_t.dashboard && _t.dashboard.confirmed ? _t.dashboard.confirmed : 'Confirmed'}</div>
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>
                )}
            </Card>
            {target
                ? <Modal show={showModal} handleClose={handleClose}>

                    {confirm || action === 'reject'
                        ? <div>
                            <div>
                                <label className='headline'>{'Zeker weten?'}</label>
                            </div>
                            <div>
                                {action === 'reject'
                                    ? <p>{'Door op \'Weiger\' te drukken zal het account van deze gebruiker per direct geblokkeerd worden.'}</p>
                                    : <p>{'Door op \'Bevestig\' te drukken zal deze gebruiker een uitnodiging tot inschrijven van het geselecteerde abonnement.'}</p>
                                }
                                <div className='validation-text bold'>Gebruiker:</div>
                                <div className='validation-text'>{`Naam: ${target.name}`}</div>
                                <div className='validation-text'>{`E-mailadres: ${target.email}`}</div>
                                <div className='validation-text'>{`Telefoonnummer: ${target.phone_number}`}</div>
                                <div className='validation-text'>{`Geselecteerd pakket: ${_t && _t.dashboard ? _t.dashboard[selectedPlan] : selectedPlan}`}</div>
                            </div>
                            <div className='vertical-divider' />
                            <div className='dashboard-row'>
                                <div className='dashboard-item'>
                                    {action === 'reject'
                                        ? <button type='submit' onClick={handleClose} className='button'>{_t.dashboard && _t.dashboard.test ? _t.dashboard.test : 'Annuleer'}</button>
                                        : <button type='submit' onClick={() => setConfirm(false)} className='button'>{_t.dashboard && _t.dashboard.test ? _t.dashboard.test : 'Vorige'}</button>
                                    }
                                </div>
                                <div className='dashboard-item'>
                                    {action === 'reject'
                                        ? <button type='submit' onClick={() => rejectUser(target)} className='button danger'>{_t.dashboard && _t.dashboard.test ? _t.dashboard.test : 'Weiger'}</button>
                                        : <button type='submit' onClick={() => confirmUserWithSubscriptionInvitation(target)} className='button'>{_t.dashboard && _t.dashboard.test ? _t.dashboard.test : 'Bevestig'}</button>
                                    }
                                </div>
                            </div>
                        </div>
                        : <div>
                            <div>
                                <label className='headline'>{'Kies het juiste pakket:'}</label>
                            </div>

                            <div className='horizontal-divider' />

                            <div className='subscription-row'>
                                <div className='subscription-plan' onClick={() => setSelectedPlan('bronze')}>
                                    <SubscriptionCard
                                        title={'Brons'}
                                        price={'€425'}
                                        features={[
                                            'Volpension',
                                            'Paddock',
                                        ]}
                                        selected={selectedPlan === 'bronze' ? true : false}
                                    />
                                </div>
                                <div className='subscription-plan' onClick={() => setSelectedPlan('silver')}>
                                    <SubscriptionCard
                                        title={'Zilver'}
                                        price={'€485'}
                                        features={[
                                            'Volpension',
                                            'Paddock',
                                            'Stapmolen',
                                        ]}
                                        selected={selectedPlan === 'silver' ? true : false}
                                    />
                                </div>
                                <div className='subscription-plan' onClick={() => setSelectedPlan('gold')}>
                                    <SubscriptionCard
                                        title={'Goud'}
                                        price={'€585'}
                                        features={[
                                            'Volpension',
                                            'Paddock',
                                            'Stapmolen',
                                            'Supplementen'
                                        ]}
                                        selected={selectedPlan === 'gold' ? true : false}
                                    />
                                </div>
                            </div>

                            <div className='horizontal-divider' />

                            <div className='dashboard-row'>
                                <div className='dashboard-item'>
                                    <button type='submit' onClick={handleClose} className='button'>{_t.dashboard && _t.dashboard.test ? _t.dashboard.test : 'Annuleer'}</button>
                                </div>
                                <div className='dashboard-item'>
                                    {action === 'reject'
                                        ? <button type='submit' onClick={() => rejectUser(target)} className='button danger'>{_t.dashboard && _t.dashboard.test ? _t.dashboard.test : 'Weiger'}</button>
                                        : <button type='submit' onClick={() => setConfirm(true)} className='button'>{_t.dashboard && _t.dashboard.test ? _t.dashboard.test : 'Verder'}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </Modal>
                : null}

        </div>
    );
}

export default NotificationCenter;