import { Action } from 'redux'
import { RootState } from '../index'
import { ThunkAction } from 'redux-thunk'

import { REHYDRATE } from 'redux-persist';

export interface Authentication {
    requestPending: boolean,
    userId: number | null;
    username: string,
    role: string,
    verified: boolean,
    confirmed: boolean,
    blocked: boolean,
    token: string,
    userLoggedIn: boolean,
};

export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTH_REQUEST_PENDING = 'AUTH_REQUEST_PENDING';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_VERIFIED = 'SET_VERIFIED';
export const SET_STATUS = 'SET_STATUS';
export const RESET_STATE = 'RESET_STATE';

interface AuthenticateAction {
    type: typeof AUTHENTICATE
    payload: Authentication
}

interface AuthRequestPendingAction {
    type: typeof AUTH_REQUEST_PENDING
    payload: boolean
}

interface SetLoggedInAction {
    type: typeof SET_LOGGED_IN
    payload: boolean
}

interface SetTokenAction {
    type: typeof SET_TOKEN
    payload: Authentication
}

interface SetVerifiedAction {
    type: typeof SET_VERIFIED
    payload: boolean
}

interface SetStatusAction {
    type: typeof SET_STATUS
    payload: { userId: Authentication, username: Authentication, role: Authentication, verified: boolean, confirmed: boolean, blocked: boolean }
}

interface ResetState {
    type: typeof RESET_STATE
    payload: null
}

interface RehydrateAction {
    type: typeof REHYDRATE,
    payload: null
}

export type AuthenticationActionTypes = AuthenticateAction | AuthRequestPendingAction | SetLoggedInAction | SetTokenAction | SetVerifiedAction | SetStatusAction | ResetState | RehydrateAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>