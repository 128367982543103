import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';

import Store from 'core/store/store';

import Spinner from 'components/Spinner/Spinner';
import useWindowDimensions from 'components/CustomHooks/useWindowDimensions';

import {
    format,
} from 'date-fns';

import { LocalizationContext } from 'core/context/localizationContext';

import './CrudTable.scss';

import icons from 'assets/svg/icons.svg';

const CrudTable = ({ model, onEditAction = (id) => console.log(`EDIT: ${id}`), onDeleteAction = (id) => console.log(`DELETE: ${id}`), onCreateAction = () => console.log(`CREATE`), filterKey = '' }) => {
    const { width } = useWindowDimensions();

    const [page, setPage] = useState<number>(1);
    const [rows, setRows] = useState<number>(5);
    const [activeSearchQuery, setActiveSearchQuery] = useState<string>('');
    const [activeSortKey, setActiveSortKey] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<number>(1); // 1 === ascending, -1 === descending

    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    useEffect(() => {
    }, [model, activeSearchQuery]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setActiveSearchQuery(e.target.value);
    }

    const handleSort = (key: string) => {
        if (!activeSortKey) {
            model.sort((a, b) => (a[key] > b[key] ? sortDirection : -1 * sortDirection));
            setSortDirection(-1);
            setActiveSortKey(key);
        }
        else if (activeSortKey === key) {
            if (sortDirection === 1) {
                model.sort((a, b) => (a.id > b.id ? 1 : -1));
                setActiveSortKey('');
            }
            else {
                model.sort((a, b) => (a[key] > b[key] ? sortDirection : -1 * sortDirection));
                setSortDirection(1);
            }
        }
        else {
            model.sort((a, b) => (a[key] > b[key] ? 1 : -1));
            setSortDirection(-1);
            setActiveSortKey(key);
        }
    }

    const renderTable = () => {

        const tableHeaders = !_.isEmpty(model) ? Object.keys(model[0]) : [];

        const searchData = model.filter((o: { [x: string]: { toString: () => string; }; }) => Object.keys(o).some(k => o[k] ? o[k].toString().toLowerCase().includes(activeSearchQuery.toLowerCase()) : false));
        const shownData = searchData.length > rows ? searchData.filter((_d: any, i: number) => (i < (rows) * page) && i >= (rows * (page - 1))) : searchData;

        return (
            <div className='table-container' style={{ height: width > 1032 ? `${152 + (rows * 90)}px` : '' }}>

                <div className='space-between align-center'>

                    <div className='horizontal-stack' style={{ width: '100%' }}>
                        <input
                            type='text'
                            className='search-input-table'
                            id='search'
                            name='search'
                            placeholder='Zoeken...'
                            autoComplete='off'
                            onChange={handleSearch}
                        />
                        <div className='horizontal-divider' />
                        <div>
                            <button type='submit' onClick={() => onCreateAction()} className='button'>{_t && _t.model.new ? _t.model.new : 'New'}</button>
                        </div>
                    </div>

                    <div className='horizontal-stack center'>
                        <div className='horizontal-stack'>
                            <div>Aantal rijen: </div>

                            {[5, 10, 15].map((numRows) => {
                                return (
                                    <div key={numRows} className='horizontal-stack'>
                                        <div className='horizontal-divider' />
                                        <div onClick={() => setRows(numRows)} className={numRows === rows ? 'current-selection' : 'interactive-div'}>
                                            {numRows}
                                        </div>
                                        <div className='horizontal-divider' />
                                        <div className=''>|</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <ul className='responsive-table'>

                    <li className='table-header'>

                        {tableHeaders.map((h, i) => <div key={h} className='col interactive-div white' style={{ flexBasis: i === 0 ? '5%' : (90 / (tableHeaders.length - 1)) + '%' }} onClick={() => handleSort(h)}>
                            <div className='horizontal-stack' style={{display: 'flex', alignItems: 'center'}}>
                                {(_t.model && _t.model[camelize(h)]) ?? h}
                                {activeSortKey === h ?
                                    <div className='icon-container'>
                                        <svg className='icon white' style={{ marginLeft: 5, width: '1rem', height: '1rem' }}>
                                            <use xlinkHref={`${icons}#chevron-${sortDirection === 1 ? 'down' : 'up'}`} />
                                        </svg>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>)}
                        {!_.isEmpty(model) ? <div className='col' style={{ flexBasis: '5%' }}>{(_t.model && _t.model.actions) ?? 'Actions'}</div> : <div><span>&nbsp;&nbsp;</span></div>}

                    </li>

                    {!_.isEmpty(shownData) ? shownData.map((m) => {
                        return (
                            <li key={m.id} className='table-row'>

                                {
                                    Object.keys(m).map((key, i) => <div key={key} className='col align-center' style={{ flexBasis: i === 0 ? '5%' : (90 / (tableHeaders.length - 1)) + '%', overflow: 'hidden', textOverflow: 'ellipsis' }} data-label={(_t.model && _t.model[camelize(key)]) ?? key}>{getFriendlyName(key, m[key])}
                                    </div>
                                    )
                                }

                                <div className='icon-container' style={{ flexBasis: '5%' }}>
                                    <svg onClick={() => { onEditAction(m) }} className='table-icon'>
                                        <use xlinkHref={`${icons}#pencil`} />
                                    </svg>
                                    <svg onClick={() => { onDeleteAction(m) }} className='table-icon'>
                                        <use xlinkHref={`${icons}#trash-can`} />
                                    </svg>
                                </div>

                            </li>
                        )
                    }) : <div className='center hours'>{_t.model && _t.model.noDataText ? _t.model.noDataText : 'No data available'}</div>}

                </ul>

                { searchData.length > rows
                    ? <div className='horizontal-stack space-between navigation-container'>
                        <div className='icon-container' onClick={prevPage}>
                            <svg className='icon' style={{ fill: page === 1 ? window.Branding.textColorLight : 'inherit', cursor: page === 1 ? '' : 'pointer' }}>
                                <use xlinkHref={`${icons}#chevron-left`} />
                            </svg>
                        </div>
                        <div className='icon-container' onClick={() => nextPage(searchData)}>
                            <svg className='icon' style={{ fill: page === Math.ceil(searchData.length / rows) ? window.Branding.textColorLight : 'inherit', cursor: page === Math.ceil(searchData.length / rows) ? '' : 'pointer', }}>
                                <use xlinkHref={`${icons}#chevron-right`} />
                            </svg>
                        </div>
                    </div>
                    : <div><span>&nbsp;&nbsp;</span></div>
                }

            </div>
        );
    }

    const nextPage = (data: string | any[]) => {
        if (!(page === Math.ceil(data.length / rows))) {
            setPage(page + 1);
        }
    }

    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    const camelize = (s: string) => {
        return s.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
    }

    const isHexCode = (s: string) => (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(s))

    const getFriendlyName = (key, value) => {
        switch (key) {
            case 'start_time':
            case 'end_time':
            case 'created_at':
            case 'updated_at':
            case 'email_verified_at':
                return value ? format(new Date(value), 'dd-MM-y, H:mm') : '-';
            case 'birth_date':
                return value ? format(new Date(value), 'dd-MM-y') : '-';

        }

        if (value === null) {
            return '-';
        }
        else if (isHexCode(value)) {
            return <span style={{ height: '25px', width: '25px', borderRadius: '50%', display: 'inline-block', backgroundColor: value }} />
        }
        else {
            return value;
        }
    }

    return (
        <div>

            {model
                ? renderTable()
                : <Spinner color={window.Branding.primaryColor} />
            }

        </div>
    );
}

export default CrudTable;