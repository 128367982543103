import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from 'core/store/user/selectors';
import { getLanguage } from 'core/store/base/selectors';

import { LocalizationContext } from 'core/context/localizationContext';

import Card from 'components/Card/Card';

import PersonalDetails from 'containers/UserPanel/Profile/User/PersonalDetails';
import SubscriptionDetails from 'containers/UserPanel/Profile/User/SubscriptionDetails';
import OrderHistory from 'containers/UserPanel/Profile/User/OrderHistory';

import 'containers/UserPanel/UserPanel.scss';

const Profile: FC = () => {
    const language = useSelector(getLanguage);
    const localization = useContext(LocalizationContext);
    const _t = localization![language] ?? localization!['en'];

    const user = useSelector(getUser);

    return (
        <div>

            <div className='userpanel-row'>
                <div className='userpanel-row-item'>
                    <Card header={_t.userpanel.contactdetails ?? 'Contact details'} height={'377px'}>
                        <PersonalDetails user={user} />
                    </Card>
                </div>
                <div className='userpanel-row-item'>
                    <Card header={_t.userpanel.subscription ?? 'Subscription'} height={'377px'}>
                        <SubscriptionDetails subscriptions={user.subscriptions} />
                    </Card>
                </div>
            </div>

            <div className='userpanel-row'>
                <div className='userpanel-row-item'>
                    <Card header={_t.userpanel.paymentHistory ?? 'Payment history'}>
                        <OrderHistory orders={user.orders} />
                    </Card>
                </div>
            </div>

        </div>
    );
}

export default Profile;
