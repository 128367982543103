import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import Store from 'core/store/store';
import { setHorses, setUsers } from 'core/store/admin/action';
import { getHorses, getUsers } from 'core/store/admin/selectors';

import Horse from 'core/apis/Horse';
import User from 'core/apis/User';

import Modal from 'components/Modal/Modal';
import Card from 'components/Card/Card';
import HorseForm from 'containers/Admin/AdminPanel/ModelForm/HorseForm';
import ConfirmForm from 'containers/Admin/AdminPanel/ModelForm/ConfirmForm';

import CrudTable from 'containers/Admin/AdminPanel/CrudTable/CrudTable';

const _hiddenAttributes = [
    'updated_at',
    'users'
];

const HorsesIndex = () => {

    const horses = useSelector(getHorses);
    const users = useSelector(getUsers);

    const [initialized, setInitialized] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<string>('');
    const [target, setTarget] = useState<HorseType | null>();

    useEffect(() => {
        const setup = () => {

            if (!horses.length) {
                dispatchHorses();
            }

            if (!users.length) {
                dispatchUsers();
            }

            setInitialized(true);
        }

        if (!initialized) {
            setup();
        }
    }, [horses, users, initialized]);

    const dispatchHorses = async () => {
        const request = await Horse.index();
        Store.dispatch(setHorses({ horses: request.data }));
    }

    const dispatchUsers = async () => {
        const request = await User.index();
        Store.dispatch(setUsers({ users: request.data }));
    }

    const onCreate = async () => {
        setShowModal('HorseForm');
    }

    const onEdit = (target: HorseType) => {
        setTarget(horses.find((horse) => horse.id === target.id));
        setShowModal('HorseForm');
    }

    const onDelete = (target: HorseType) => {
        setTarget(target);
        setShowModal('ConfirmForm');
    }

    const handleClose = () => {
        setShowModal('');
        setTarget(null);
    }

    return (
        <div>
            <Card>
                <CrudTable key={'horses'} model={_.map(horses, lesson => _.omit(lesson, _hiddenAttributes))} onEditAction={onEdit} onDeleteAction={onDelete} onCreateAction={onCreate} />
            </Card>

            <Modal show={showModal === 'HorseForm'} handleClose={handleClose}>
                <HorseForm key={Math.random()} target={target} handleClose={() => { dispatchHorses(); handleClose() }} />
            </Modal>

            <Modal show={showModal === 'ConfirmForm'} handleClose={handleClose}>
                <ConfirmForm target={target} title='Verwijder les' message='Weet u zeker dat u deze les wilt verwijderen?' action='DELETE' handleConfirm={async () => { await Horse.delete(target!.id); dispatchHorses(); }} handleClose={handleClose} />
            </Modal>
        </div>
    );
}

export default HorsesIndex;