import React, { useState, useContext } from 'react';
import _ from 'lodash';

import Store from 'core/store/store';

import {
    format,
    addHours,
    getTime,
} from 'date-fns';

import Lesson from 'core/apis/Lesson';

import { LocalizationContext } from 'core/context/localizationContext';

const LessonForm = ({ target, handleClose, date = new Date() }) => {
    const [validationErrors, setValidationErrors] = useState({ description: [], instructor: [], start_time: [], end_time: [], available_slots: [] });
    const [formData, setFormData] = useState({ start_time: target ? target.start_time : format(date, "yyyy-MM-dd'T'HH:mm"), end_time: target ? target.end_time : format(addHours(date, 1), "yyyy-MM-dd'T'HH:mm"), description: target ? target.description : '', instructor: target ? target.instructor : '', available_slots: target ? target.available_slots : 0 });

    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    //: React.ChangeEvent<HTMLInputElement>
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const storeLesson = async (update = false) => {
        const lesson = {
            start_time: getTime(new Date(formData.start_time)),
            end_time: getTime(new Date(formData.end_time)),
            description: formData.description,
            instructor: formData.instructor,
            available_slots: formData.available_slots,
        }

        let errors = {};
        if (update) {
            await Lesson.update(lesson, target!.id).catch(error => {
                errors = error.response.data.errors;
                const messages = _.assign(_.clone(validationErrors), error.response.data.errors);
                setValidationErrors(messages);
            });
        }
        else {
            await Lesson.store(lesson).catch(error => {
                errors = error.response.data.errors;
                const messages = _.assign(_.clone(validationErrors), error.response.data.errors);
                setValidationErrors(messages);
            });
        }

        if (!Object.keys(errors).some((key) => errors[key].length)) {

            setFormData({
                start_time: '',
                end_time: '',
                description: '',
                instructor: '',
                available_slots: ''
            });

            handleClose(true);
        }
    }

    return (
        <div>
            <div className=''>
                <label className='headline center'>{target ? (_t ? _t.lesson.edit + ': ' : 'Edit lesson: ') + target.id : (_t ? _t.lesson.create : 'New lesson')}</label>
                <div className='vertical-spacer' />

                <label className='label'>{(_t ? _t.lesson.description : 'Description') + ' *'}</label>
                <input
                    type='text'
                    className='input'
                    id='description'
                    name='description'
                    onChange={handleChange}
                    defaultValue={target ? target.description : ''}
                    autoComplete='off'
                />
                <div className='validation-text danger'>
                    {validationErrors && validationErrors.hasOwnProperty('description') && validationErrors.description.length ? validationErrors.description.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                </div>

                <label className='label'>{(_t ? _t.lesson.instructor : 'Instructor') + ' *'}</label>
                <input
                    type='text'
                    className='input'
                    id='instructor'
                    name='instructor'
                    onChange={handleChange}
                    defaultValue={target ? target.instructor : ''}
                    autoComplete='off'
                />
                <div className='validation-text danger'>
                    {validationErrors && validationErrors.hasOwnProperty('instructor') && validationErrors.instructor.length ? validationErrors.instructor.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                </div>

                <label className='label'>{(_t ? _t.lesson.startTime : 'Start time') + ' *'}</label>
                <input
                    type='datetime-local'
                    className='input'
                    id='start_time'
                    name='start_time'
                    defaultValue={target ? format(target.start_time, "yyyy-MM-dd'T'HH:mm") : format(date, "yyyy-MM-dd'T'HH:mm")}
                    onChange={handleChange}
                />
                <div className='validation-text danger'>
                    {validationErrors && validationErrors.hasOwnProperty('start_time') && validationErrors.start_time.length ? validationErrors.start_time.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                </div>

                <label className='label'>{(_t ? _t.lesson.endTime : 'End time') + ' *'}</label>
                <input
                    type='datetime-local'
                    className='input'
                    id='end_time'
                    name='end_time'
                    defaultValue={target ? format(target.end_time, "yyyy-MM-dd'T'HH:mm") : format(addHours(date, 1), "yyyy-MM-dd'T'HH:mm")}
                    onChange={handleChange}
                />
                <div className='validation-text danger'>
                    {validationErrors && validationErrors.hasOwnProperty('end_time') && validationErrors.end_time.length ? validationErrors.end_time.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                </div>

                <label className='label'>{(_t ? _t.lesson.availableSlots : 'Available slots') + ' *'}</label>
                <input
                    type='text'
                    className='input'
                    id='available_slots'
                    name='available_slots'
                    onChange={handleChange}
                    defaultValue={target ? target.available_slots : ''}
                    autoComplete='off'
                />
                <div className='validation-text danger'>
                    {validationErrors && validationErrors.hasOwnProperty('available_slots') && validationErrors.available_slots.length ? validationErrors.available_slots.map((e) => e) : <span>&nbsp;&nbsp;</span>}
                </div>
            </div>
            <div className='validation-text danger'>
                <span>&nbsp;&nbsp;</span>
            </div>

            <div className='horizontal-stack justify-center'>
                {target
                    ? <button style={{ width: '50%' }} type='submit' onClick={() => storeLesson(true)} className='button' >{_t ? _t.lesson.submitEdit : 'Edit'}</button>
                    : <button style={{ width: '50%' }} type='submit' onClick={() => storeLesson()} className='button' >{_t ? _t.lesson.submit : 'Submit'}</button>
                }
                {/* <div className='horizontal-divider' />
                <button type='submit' onClick={() => { handleClose(); setValidationErrors({ description: [], instructor: [], start_time: [], end_time: [], available_slots: [] }) }} className='button' >{_t ? _t.lesson.close : 'Close'}</button> */}
            </div>
        </div>
    )
}

export default LessonForm;