import Api, { DownloadApi } from './Api';

const Subscription = {

    trigger(plan: { plan: string }, userId: number) {
        return Api().post('/subscriptions/trigger/' + userId, plan)
    },

    swap(plan: { plan: string }, userId: number) {
        return Api().post('/subscriptions/swap/' + userId, plan)
    },

    cancel(plan: { plan: string }, userId: number) {
        return Api().post('/subscriptions/cancel/' + userId, plan)
    },

    resume(plan: { plan: string }, userId: number) {
        return Api().post('/subscriptions/resume/' + userId, plan)
    },

    getInvoice(orderId: number) {
        return DownloadApi().get('/subscriptions/invoice/' + orderId);
    }

};

export default Subscription;