
interface RootState {
    user: {
        user: UserType
        appointments: AppointmentType[]
    }
}

export const getUser = (state: RootState) => state.user.user;
export const getAppointments = (state: RootState) => state.user.appointments;
