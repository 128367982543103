import { SET_LANGUAGE, SET_LOCALES, BaseActionTypes } from './types';

export const setLanguage = ({ language }: { language: string }): BaseActionTypes => ({
    type: SET_LANGUAGE,
    payload: language
});

export const setLocales = ({ locales }: { locales: {} }): BaseActionTypes => ({
    type: SET_LOCALES,
    payload: locales
});
