import React from 'react';

export interface CalendarContextProps {
    type: 'CALENDAR' | 'SCHEDULE'
    onCreate?: (date: Date) => void
    onEdit?: (target) => void
    onDelete?: (target) => void
    onDrop?: (form, target: number) => Promise<void>
    onAttach?: (appointment: AppointmentType) => Promise<void>
    onDetach?: (id: number) => void
    getFilterOptions?: () => {}
    getFilterKey?: () => string
}

export const CalendarContext = React.createContext<CalendarContextProps | null>(null);

const CalendarProvider = ({ actions, children }: { actions: CalendarContextProps, children: React.ReactNode }) => {
    return (
        <CalendarContext.Provider
            value={actions}
        >
            {children}
        </CalendarContext.Provider>
    );
};

export default CalendarProvider;