import { REHYDRATE } from 'redux-persist';

export const SET_USERS = 'SET_USERS';
export const SET_CALENDARS = 'SET_CALENDARS';
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';
export const SET_LESSONS = 'SET_LESSONS';
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const SET_HORSES = 'SET_HORSES';
export const SET_USERACTIONS = 'SET_USERACTIONS';
export const SET_ANNOUNCEMENTS = 'SET_ANNOUNCEMENTS';

interface SetUsersAction {
    type: typeof SET_USERS
    payload: []
}

interface SetCalendarsAction {
    type: typeof SET_CALENDARS
    payload: []
}

interface SetAppointmentsAction {
    type: typeof SET_APPOINTMENTS
    payload: []
}

interface SetLessonsAction {
    type: typeof SET_LESSONS
    payload: []
}

interface SetPaymentsAction {
    type: typeof SET_PAYMENTS
    payload: []
}

interface SetHorsesAction {
    type: typeof SET_HORSES
    payload: []
}

interface SetUserActionsAction {
    type: typeof SET_USERACTIONS
    payload: []
}

interface SetAnnouncementAction {
    type: typeof SET_ANNOUNCEMENTS
    payload: []
}

interface RehydrateAction {
    type: typeof REHYDRATE,
    payload: null
}

export type AdminActionTypes =  SetUsersAction | SetCalendarsAction | SetAppointmentsAction | SetLessonsAction | SetPaymentsAction | SetHorsesAction | SetUserActionsAction | SetAnnouncementAction | RehydrateAction;