import React from 'react';

import { format, addHours, startOfDay } from 'date-fns';

import './Timeline.scss';

const Timeline = () => {

    const renderTimelineHours = (): JSX.Element[] => {
        const date = startOfDay(new Date());

        const hours: JSX.Element[] = [];
        for (let i = 8; i < 21; i++) {
            const hour = format(addHours(date, i), 'HH:mm');
            hours.push(<div key={`timeline-${i}`} className='timeline-hour'>{hour}</div>);
        }
        return hours;
    }

    return (
        <div>
            <div className='timeline-col'>
                <div className='timeline-header-margin' />
                {renderTimelineHours()}
            </div>
        </div>

    )
}

export default Timeline;
