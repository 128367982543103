import { 
    Authentication, 
    AUTH_REQUEST_PENDING, 
    SET_LOGGED_IN, 
    SET_TOKEN, 
    SET_VERIFIED, 
    SET_STATUS, 
    RESET_STATE,
    AuthenticationActionTypes 
} from './types';

import { REHYDRATE } from 'redux-persist';

const initialState: Authentication = {
    requestPending: false,
    userId: null,
    username: '',
    role: '',
    verified: false,
    confirmed: false,
    blocked: false,
    token: '',
    userLoggedIn: false,
};

export const authenticationReducer = (state = initialState, action: AuthenticationActionTypes) => {
    switch (action.type) {

        case AUTH_REQUEST_PENDING: {
            return {
                ...state,
                requestPending: action.payload
            };
        }

        case SET_LOGGED_IN: {
            return {
                ...state,
                userLoggedIn: action.payload
            };
        }

        case SET_TOKEN: {
            return {
                ...state,
                token: action.payload
            };
        }

        case SET_VERIFIED: {
            return {
                ...state,
                verified: action.payload
            };
        }

        case SET_STATUS: {
            return {
                ...state,
                userId: action.payload.userId,
                username: action.payload.username,
                role: action.payload.role,
                verified: action.payload.verified,
                confirmed: action.payload.confirmed,
                blocked: action.payload.blocked
            };
        }

        case RESET_STATE: {
            return {
                ...initialState
            }
        }

        case REHYDRATE: {
            return state;
        }

        default: {
            return {
                ...state
            }
        }

    }
};