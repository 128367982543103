import React, { FC } from 'react';
import Svg from '../Icons/Svg/Svg';

export interface LabelProps {
    title: string
    icon: string
    open: boolean
}

const SidebarLabel: FC<LabelProps> = ({ title, icon, open }) => {
    return (
        <div className='sidebar-content-container title'>
            <div className='sidebar-row space-between'>

                <div className='sidebar-row-item'>
                    <div className='sidebar-row'>
                        <Svg icon={icon} className='sidebar-row-item sidebar-icon' />
                        <div className='sidebar-row-item sidebar-row-item-tag'>
                            {title}
                        </div>
                    </div>
                </div>

                <div className='sidebar-row-item'>
                    <Svg icon={`chevron-${open ? 'down' : 'right'}`} className='sidebar-icon' />
                </div>

            </div>
        </div>
    )
}

export default SidebarLabel;
