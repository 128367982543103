import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCalendars } from 'core/store/admin/selectors';

import { DragSourceMonitor, useDrag } from 'react-dnd';

import { format, differenceInMinutes } from 'date-fns';

import { AppointmentType } from '../Interfaces/Interfaces';
import { ItemTypes } from '../ItemTypes/ItemTypes';

import { lightenDarkenColor } from 'components/Utility/Utility';

import Window from 'containers/Admin/AdminPanel/Calendar/2.0/Event/Window';

import './Event.scss';

export interface EventProps {
    event: AppointmentType
}

const Event: FC<EventProps> = ({ event }) => {
    const heightPercentage = (differenceInMinutes(new Date(event.end_time), new Date(event.start_time)) / 30) * 100;
    const [show, setShow] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const calendars = useSelector(getCalendars);
    const calendar = calendars.find((calendar) => calendar.id === event.calendar_id);

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.EVENT,
            item: event,
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
    );

    const handleHover = (e) => {
        switch (e.type) {
            case 'mouseenter':
                setIsHovered(true);
                break;
            case 'mouseleave':
                setIsHovered(false);
                break;
        }
    }

    const handleOpen = (e: { stopPropagation: () => void; }) => {
        e.stopPropagation();
        setShow(true)
    };

    const handleClose = () => {
        setShow(false)
    };

    return (
        <>
            <div
                ref={drag}
                className='event'
                style={{
                    height: `${heightPercentage}%`,
                    opacity: isDragging ? 0.5 : 1,
                    zIndex: isHovered && !isDragging ? 3 : 0,
                    backgroundColor: calendar
                        ? (isHovered
                            ? lightenDarkenColor(calendar.color, 40)
                            : calendar.color)
                        : (isHovered
                            ? lightenDarkenColor(window.Branding.primaryColor, 40)
                            : window.Branding.primaryColor),
                    cursor: isDragging ? 'move' : 'pointer'
                }}
                onClick={handleOpen}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
            >
                <div>{`${event.type} ${heightPercentage <= 100 ? `${format(event.start_time, 'HH:mm')}-${format(event.end_time, 'HH:mm')}` : ''}`}</div>
                {heightPercentage > 100 ? <div>{`${format(event.start_time, 'HH:mm')}-${format(event.end_time, 'HH:mm')}`}</div> : null}
            </div>
            

            <Window
                show={show}
                onClose={handleClose}
                event={event}
            />
        </>
    );
}

export default Event;
