import React, { FC } from 'react';

export interface TokenProps {
    title: string
    handleDelete: (key: any) => void
}

const Token: FC<TokenProps> = ({ title, handleDelete }) => {
    return (
        <div className='token'>
            <div className='remove-button' onClick={handleDelete}>{'✕'}</div>
            <div>{title}</div>
        </div>
    );
}

export default Token;
