import React from 'react';

import '../Dropdown.scss';

type Props = {
    onClick?: React.MouseEventHandler,
    label: any,
};

const DropdownItem = ({ onClick, label }: Props) => {

    return (
        <li className="dropdown-option" onClick={ onClick } >
            { label }
        </li>
    );

}

export default DropdownItem;