
interface RootState {
    base: {
        language: string;
        locales: {};
    }
}

export const getLanguage = (state: RootState) => state.base.language;
export const getLocales = (state: RootState) => state.base.locales;
