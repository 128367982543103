export const lightenDarkenColor = (color: string, amount: number) => {
    let colorWithoutHash: string = color.replace('#', '');
    if (colorWithoutHash.length === 3) {
        colorWithoutHash = colorWithoutHash
            .split('')
            .map((c: any) => `${c}${c}`)
            .join('');
    }

    const getColorChannel = (substring: string) => {
        let colorChannel: number | string = parseInt(substring, 16) + amount
        colorChannel = Math.max(Math.min(255, colorChannel), 0).toString(16);

        if (colorChannel.length < 2) {
            colorChannel = `0${colorChannel}`;
        }

        return colorChannel;
    }

    const colorChannelRed: string = getColorChannel(colorWithoutHash.substring(0, 2));
    const colorChannelGreen: string = getColorChannel(colorWithoutHash.substring(2, 4));
    const colorChannelBlue: string = getColorChannel(colorWithoutHash.substring(4, 6));

    return `#${colorChannelRed}${colorChannelGreen}${colorChannelBlue}`;
}

export const camelize = (s: string) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
}