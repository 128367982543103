import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';

import Store from 'core/store/store';
import { getVerifiedStatus, getConfirmedStatus } from 'core/store/authentication/selectors';
import { logout, resetState } from 'core/store/authentication/action';

import User from 'core/apis/User';

import Card from 'components/Card/Card';

import { LocalizationContext } from 'core/context/localizationContext';

const VerifyEmailForm = () => {
    const history = useHistory();

    const verified = useSelector(getVerifiedStatus);
    const confirmed = useSelector(getConfirmedStatus);

    const localization = useContext(LocalizationContext);
    const _t = localization![Store.getState().base.language] ?? localization!['en'];

    useEffect(() => {
    }, [verified, confirmed]);

    const handleResend = async () => {
        await User.requestEmailVerification();
    }

    const handleLogout = async () => {
        await Store.dispatch(logout()).catch(error => {
            if (error.response.status === 401) { // unauthenticated; (for switchting environments with redux-persist)
                Store.dispatch(resetState());
            }
        });

        history.push({
            pathname: '/',
        });
    }

    const renderNotVerifiedForm = () => (
        <div>
            <label className='headline'>{_t ? _t.authentication.verifyEmail : 'Forgotten password'}</label>
            <p className=''>{_t ? _t.authentication.verifyEmailMessage : ''}</p>
            <div className='justify-end'>
                <button onClick={handleResend} className='button' style={{ width: '75%' }}>{_t ? _t.authentication.verifyEmailResend : 'Click here to resend verification link.'}</button>
                <div className='horizontal-divider' />
                <button onClick={handleLogout} className='button' style={{ width: '25%' }}>{_t ? _t.authentication.logout : 'Logout'}</button>
            </div>
        </div>
    );

    const renderVerifiedForm = () => (
        <div>
            <label className='headline'>Het bevestigen van uw e-mailaderes is gelukt!</label>
            <p className=''>Uw aanvraag is doorgegeven. U krijgt bericht wanneer deze verder is behandeld.</p>
            <div className='justify-end'>
                <button onClick={handleLogout} className='button' style={{ width: 'fit-content' }}>{_t ? _t.authentication.logout : 'Logout'}</button>
            </div>
        </div>
    );

    const renderConfirmedForm = () => (
        <div>
            <label className='headline'>Uw account is bevestigd en geactiveerd!</label>
            <p className=''>U kunt nu gebruik maken van de app.</p>
            <div className='justify-end'>
                <button onClick={() => history.push('/')} className='button' style={{ width: 'fit-content' }}>Naar de app</button>
            </div>
        </div>
    );


    return (
        <div className='form-container center'>

            <Card padding={'5rem'}>

                {
                    verified ? (confirmed ? renderConfirmedForm() : renderVerifiedForm()) : renderNotVerifiedForm()
                }

            </Card>

        </div>
    )
}

export default VerifyEmailForm;
