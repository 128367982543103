import React, { createRef, FC, useEffect, useState } from 'react';

import ValidationError from '../ValidationError/ValidationError';

import './SelectInput.scss';

export interface SelectInputProps {
    label?: string
    value: string
    required?: boolean
    className?: string
    placeholder?: string
    errors?: string[]
}

const SelectInput: FC<SelectInputProps> = ({ label, value, required, placeholder, className, errors, children }) => {
    const node = createRef<HTMLDivElement>();
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = (e: { target: any; }) => {
        const current = node.current;
        if (current && current!.contains(e.target)) {
            return;
        }
        setOpen(false);
    };

    const handleEscape = (e: { key: any; keyCode: any; }) => {
        let key = e.key || e.keyCode;
        if (key === 'Escape' || key === 27) {
            setOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('keydown', handleEscape, false);
        return () => {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('keydown', handleEscape, false);
        };
    });

    return (
        <div>
            {label ? <label className='input-label'>{`${label} ${required ? '*' : ''}`}</label> : null}
            <div ref={node} className='select-container' onClick={() => setOpen(!open)} style={{ position: 'relative' }}>
                <input
                    type='text'
                    className={`input read-only ${className}`}
                    autoComplete='off'
                    value={value}
                    placeholder={placeholder}
                    readOnly
                />

                <ul className={`select-input ${open ? 'open' : ''}`} onClick={() => setOpen(false)}>
                    {React.Children.map(children, (child, index) => <div key={index} className='select-item'>{child}</div>)}
                </ul>

                <div className='select-input-selector' style={{ position: 'absolute' }}>
                    <div className={`selector ${open ? 'open' : ''}`} />
                </div>
            </div>
            {errors ? (errors.length ? errors.map((error: string, index: number) => <ValidationError key={index} message={error} />) : <div style={{ marginBottom: '1rem' }}><span>&nbsp;&nbsp;</span></div>) : null}
        </div>
    );
}

export default SelectInput;
