const translations = {
    en: {
        authentication: {
            name: 'Name',
            email: 'Email-address',
            address: 'Address',
            phoneNumber: 'Phone number',
            username: 'Username',
            password: 'Password',
            passwordConfirmation: 'Confirm password',
            passwordForgotten: 'Forgot password?',
            passwordForgottenMessage: 'Fill in your email address to receive a password reset token.',
            resetPassword: 'Reset password',
            tokenSent: 'A reset token has been sent to',
            tokenSentMessage: 'Fill in the code below to reset your password.',
            loginReturn: 'Back to login screen',
            verifyEmail: 'Please verify your email-address',
            verifyEmailMessage: 'An email verification link has been sent to your email-address.',
            verifyEmailResend: 'Click here to resend verification link.',
            login: 'Login',
            logout: 'Logout',
            unregistered: 'No account?',
            register: 'Register',
            registerUser: 'New user',
            edit: 'Edit',
            editUser: 'Edit user',
            editPasswordMessage: 'Choose a new password.',
            changePassword: 'Change password',
            close: 'Close',
            send: 'Send',
            reset: 'Reset',
        },
        appointment: {
            create: 'New appointment',
            edit: 'Edit appointment',
            delete: 'Delete',
            deleteConfirm: 'Are you sure you want to delete the selected appointment?',
            startTime: 'Start time',
            endTime: 'End time',
            time: 'Time',
            comments: 'Comments',
            recurrence: 'Recurrence',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            none: 'Not applicable',
            type: 'Appointment type',
            submit: 'Submit',
            submitEdit: 'Edit',
            close: 'Close',
            selectOption: '-- Select an option --',
            pensionTrack: 'Pension track',
            lungingTrack: 'Lunging track',
            paddock: 'Paddock',
            paddockLarge: 'Paddock large',
            stepmill: 'Stepmill',
            meadow: 'Meadow',
            continue: 'Continue',
            back: 'Back',
            attention: 'Attention',
            price: 'Price',
            toPayment: 'To payment',
            pay: 'Pay',
            paymentRequired: 'There are costs associated with making this appointment',
            fulfil: 'To fulfil'
        },
        lesson: {
            create: 'New lesson',
            edit: 'Edit lesson',
            delete: 'Delete',
            deleteConfirm: 'Are you sure you want to delete the selected lesson?',
            startTime: 'Start time',
            endTime: 'End time',
            description: 'Description',
            submit: 'Submit',
            submitEdit: 'Edit',
            close: 'Close',
            availableSlots: 'Number of available slots',
            instructor: 'Instructor',
        },
        calendar: {
            create: 'New calendar',
            edit: 'Edit calendar',
            delete: 'Delete',
            deleteConfirm: 'Are you sure you want to delete the selected calendar?',
            submit: 'Submit',
            submitEdit: 'Edit',
            close: 'Close',
            title: 'Title',
            timeInterval: 'Timeslots',
            availableSlots: 'Number of available slots per timeslot',
            limit: 'Limit per day',
            price: 'Costs per timeslot (€00.00)',
            selectOption: '-- Select an option --',
            hourly: 'Every hour',
            halfHourly: 'Every half hour',
            day: 'Day',
            week: 'Week',
            today: 'Today',
            event: 'Event',
            signUp: 'Sign up',
            signOut: 'Sign out',
        },
        horse: {
            create: 'New horse',
            edit: 'Edit horse',
            delete: 'Delete',
            deleteConfirm: 'Are you sure you want to delete the selected horse?',
            submit: 'Submit',
            submitEdit: 'Edit',
            close: 'Close',
            selectOption: '-- Select an option --',
            name: 'Name',
            user: 'User',
            birthDate: 'Birth date',
            chipNumber: 'Chip number',
            veterinarian: 'Veterinarian',
            farrier: 'Farrier',
        },

        // CrudTable headers
        model: {
            name: 'Name',
            email: 'Email-address',
            address: 'Address',
            phoneNumber: 'Phone number',
            emailVerifiedAt: 'Verified at',
            createdAt: 'Created at',
            updatedAt: 'Updated at',
            userId: 'User',
            startTime: 'Start time',
            endTime: 'End time',
            type: 'Type',
            comments: 'Comments',
            description: 'Description',
            instructor: 'Instructor',
            availableSlots: 'Available slots',
            actions: 'Actions',
            title: 'Title',
            calendarId: 'Calendar',
            price: 'Price (€)',
            limit: 'Limit',
            timeInterval: 'Timeslots (min.)',
            color: 'Color',
            new: 'New', // ??
            confirmed: 'Confirmed',
            noDataText: 'No data available',
            delete: 'Delete',
            confirm: 'Confirm',
            birthDate: 'Birth date',
            chipNumber: 'Chip number',
            veterinarian: 'Veterinarian',
            farrier: 'Farrier',
        },
        dashboard: {
            users: 'Users',
            userManagement: 'Usermanagement',
            accessControl: 'Access control',
            appointments: 'Appointments',
            payments: 'Payments',
            calendars: 'Calendars',
            schedule: 'Schedule',
            lessons: 'Lessons',
            lessonSchedule: 'Lesson schedule',
            dashboard: 'Dashboard',
            increase: 'Increase',
            decrease: 'Decrease',
            sinceLastMonth: 'Since last month',
            noDataTextPreviousMonth: 'No data available for previous month',
            overview: 'Overview',
            confirmed: 'Confirmed',
            confirm: 'Confirm',
            reject: 'Reject',
            recent: 'Recent',
            revenue: 'Revenue',
            paid: 'Paid',
            pending: 'Pending',
            cancel: 'Cancel',
            canceled: 'Canceled',
            expired: 'Expired',
            minutes: 'Minutes',
            hours: 'Hours',
            days: 'Days',
            weeks: 'Weeks',
            months: 'Months',
            ago: 'Ago',
            created: 'Created',
            updated: 'Updated',
            deleted: 'Deleted',
            user: 'User',
            calendar: 'Calendar',
            appointment: 'Appointment',
            lesson: 'Lesson',
            payment: 'Payment',
            activityLog: 'Activity log',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            today: 'Today',
            thisWeek: 'This week',
            thisMonth: 'This month',
            yesterday: 'Yesterday',
            previousWeek: 'Last week',
            previousMonth: 'Last month',
            total: 'Total',
            details: 'Details',
            actions: 'Actions',
            bronze: 'Bronze',
            silver: 'Silver',
            gold: 'Gold',
            navigation: 'Navigation',
            announcement: 'Announcement',
            announcements: 'Announcements',
            publish: 'Publish'
        },
        subscription: {
            subscription: 'Subscription',
            bronze: 'Bronze',
            silver: 'Silver',
            gold: 'Gold',
            canceledMessage: 'The subscription is canceled and expires on ',
            resume: 'Resume',
            resumeMessage: 'It will be possible to resume this user\'s subscription by pressing the button below, up until the end date has been reached.',
            resumeTitle: 'Resume subscription',
            resumeConfirmMessage: 'With this action the canceled subscription of this user will be resumed. The user will be automatically notified. Do you wish to continue?',
            activeSince: 'Active since',
            change: 'Edit',
            changeTitle: 'Edit subscription',
            changeMessage: 'Edit this user\'s current subscription? The user will be automatically notified.',
            cancel: 'Cancel',
            cancelTitle: 'Cancel subscription',
            cancelMessage: 'With this action this user\'s subscription will be changed with the next billing cycle. The user will be automatically notified. Do you wisth to continue?',
            noSubscription: 'This user has no active subscription',
            signUp: 'Sing up',
            signUpTitle: 'Sign up',
            singUpMessage : 'Send this user a subscription invitation?',
            active: 'Active',
            inactive: 'Inactive',
            activateTitle: 'Activate user',
            activateMessage: 'With this action you will alter the user\'s access to the app. Do you wish to continue?',
            blockTitle: 'Block user',
            admin: 'Admin',
            regularUser: 'Regular user',
            changeRightsTitle: 'Change rights',
            removeAdmin: 'With this action you will take away admin privileges from this user. Do you wish to continue?',
            addAdmin: 'With this action you will give this user admin privileges. This opens up access for this user for potentially confidential information. Do you wish to continue?',
            canceled: 'Canceled',
            current: 'Current subscription',
            status: 'Account status',
            confirm: 'Confirm',
            confirmUser: 'This user requires confirmation (this action can also be performed from the dashboard overview)',
            confirmTitle: 'Confirm user', 
            confirmMessage: 'Are you sure you wish to confirm this user\'s registration request?',
            access: 'Access'
        },

        userpanel: {
            contactdetails: 'Contact details',
            subscription: 'Subscription',
            paymentHistory: 'Payment history',
            personaldetails: {
                name: 'Name',
                email: 'Email address',
                phoneNumber: 'Phone number',
                address: 'Address',
                addressDetails: 'Address details',
                editDetails: 'Edit details',
            },
            subscriptionDetails: {
                na: 'N.A.',
                startDate: 'Start date',
                changedNotification: 'Changes have been made',
                subscriptionCanceled: 'Your subscription is canceled and will expire on',
                subscriptionSwapped: 'Your subscription will be swapped to the \':plan\' plan at the next billing cycle (:date)',
                editDetails: 'Edit details',
                contact: 'To change your subscription, please contact Time Stables'
            },
            orderHistory: {
                date: 'Date',
                total: 'Total',
                status: 'Status',
                invoice: 'Invoice',
                paid: 'Paid',
                pending: 'Pending',
                canceled: 'Canceled',
                expired: 'Expired',
                download: 'Download',
            }
        },
        sidebar: {
            home: 'Home',
            profile: 'Profile',
            user: 'User',
            horse: 'Horse',
            appointments: 'Appointments',
            myAppointments: 'My Appointments',
            schedule: 'Schedule',
            dashboard: 'Dashboard',
            users: 'Users',
            accessControl: 'Access control',
            horses: 'Horses',
            calendars: 'Calendars',
            lessons: 'Lessons',
            lessonSchedule: 'Lesson schedule'
        }
    },
    nl: {
        authentication: {
            name: 'Naam',
            email: 'E-mailadres',
            address: 'Adres',
            phoneNumber: 'Telefoonnummer',
            username: 'Gebruikersnaam',
            password: 'Wachtwoord',
            passwordConfirmation: 'Bevestig wachtwoord',
            passwordForgotten: 'Wachtwoord vergeten?',
            passwordForgottenMessage: 'Vul hier uw e-mailadres in om een wachtwoord reset code te ontvangen.',
            passwordReset: 'Reset wachtwoord',
            tokenSent: 'Een reset code is verzonden naar',
            tokenSentMessage: 'Vul de code hieronder in om uw wachtwoord te wijzigen.',
            loginReturn: 'Terug naar login scherm',
            verifyEmail: 'Bevestig uw e-mailadres',
            verifyEmailMessage: 'Een verificatie link is verstuurd naar uw e-mailadres.',
            verifyEmailResend: 'Klik hier om de mail opnieuw te versturen.',
            login: 'Inloggen',
            logout: 'Uitloggen',
            unregistered: 'Nog geen account?',
            register: 'Registreren',
            registerUser: 'Nieuwe gebruiker',
            edit: 'Wijzig',
            editUser: 'Wijzig gebruiker',
            editPasswordMessage: 'Kies een nieuw wachtwoord.',
            changePassword: 'Wijzig wachtwoord',
            close: 'Sluiten',
            send: 'Verstuur',
            reset: 'Reset',
        },
        appointment: {
            create: 'Nieuwe afspraak',
            edit: 'Wijzig afspraak',
            delete: 'Verwijder',
            deleteConfirm: 'Weet u zeker dat u de geselecteerde afspraak wilt verwijderen?',
            startTime: 'Start tijd',
            endTime: 'Eind tijd',
            time: 'Tijd',
            comments: 'Opmerkingen',
            recurrence: 'Herhaling',
            daily: 'Dagelijks',
            weekly: 'Wekelijks',
            monthly: 'Maandelijks',
            none: 'Niet van toepassing',
            type: 'Type afspraak',
            submit: 'Toevoegen',
            submitEdit: 'Wijzig',
            close: 'Sluiten',
            selectOption: '-- Selecteer een van de opties --',
            pensionTrack: 'Pensionbaan',
            lungingTrack: 'Longeerbaan',
            paddock: 'Paddock',
            paddockLarge: 'Paddock groot',
            stepmill: 'Stapmolen',
            meadow: 'Weiland',
            continue: 'Volgende',
            back: 'Vorige',
            attention: 'Let op',
            price: 'Prijs',
            toPayment: 'Naar betaling',
            pay: 'Betalen',
            paymentRequired: 'Aan het maken van deze afspraak zijn kosten verbonden',
            fulfil: 'Te voldoen'
        },
        lesson: {
            create: 'Nieuwe les',
            edit: 'Wijzig les',
            delete: 'Verwijder',
            deleteConfirm: 'Weet u zeker dat u de geselecteerde les wilt verwijderen?',
            startTime: 'Start tijd',
            endTime: 'Eind tijd',
            description: 'Beschrijving',
            submit: 'Toevoegen',
            submitEdit: 'Wijzig',
            close: 'Sluiten',
            availableSlots: 'Aantal beschikbare plekken',
            instructor: 'Instructeur',
        },
        calendar: {
            create: 'Nieuwe agenda',
            edit: 'Wijzig agenda',
            delete: 'Verwijder',
            deleteConfirm: 'Weet u zeker dat u de geselecteerde agenda wilt verwijderen?',
            submit: 'Toevoegen',
            submitEdit: 'Wijzig',
            close: 'Sluiten',
            title: 'Titel',
            timeInterval: 'Tijdslots',
            availableSlots: 'Aantal beschikbaar plekken per tijdslot',
            limit: 'Limiet per dag',
            price: 'Kosten per tijdslot (€00,00)',
            selectOption: '-- Selecteer een van de opties --',
            hourly: 'Ieder uur',
            halfHourly: 'Ieder half uur',
            day: 'Dag',
            week: 'Week',
            today: 'Vandaag',
            event: 'Gebeurtenis',
            signUp: 'Inschrijven',
            signOut: 'Uitschrijven',
        },
        horse: {
            create: 'Nieuw paard',
            edit: 'Wijzig paard',
            delete: 'Verwijder',
            deleteConfirm: 'Weet u zeker dat u het geselecteerde paard wilt verwijderen?',
            submit: 'Toevoegen',
            submitEdit: 'Wijzig',
            close: 'Sluiten',
            selectOption: '-- electeer een van de opties --',
            name: 'Naam',
            user: 'Gebruiker',
            birthDate: 'Geboortedatum',
            chipNumber: 'Chipnummer',
            veterinarian: 'Dierenarts',
            farrier: 'Hoefsmid',
        },

        // CrudTable headers
        model: {
            name: 'Naam',
            email: 'E-mailadres',
            address: 'Adres',
            phoneNumber: 'Telefoonnummer',
            emailVerifiedAt: 'Bevestigingsdatum',
            createdAt: 'Aangemaakt',
            updatedAt: 'Geüpdatet',
            userId: 'Gebruiker',
            startTime: 'Start tijd',
            endTime: 'Eind tijd',
            type: 'Type',
            comments: 'Opmerkingen',
            description: 'Beschrijving',
            instructor: 'Instructeur',
            availableSlots: 'Beschikbaar',
            actions: 'Acties',
            title: 'Titel',
            calendarId: 'Agenda',
            price: 'Prijs (€)',
            limit: 'Limiet',
            timeInterval: 'Tijdslots (min.)',
            color: 'Kleur',
            new: 'Nieuw', // ??
            confirmed: 'Bevestigd',
            noDataText: 'Geen data beschikbaar',
            delete: 'Delete',
            confirm: 'Bevestig',
            birthDate: 'Geboortedatum',
            chipNumber: 'Chipnummer',
            veterinarian: 'Dierenarts',
            farrier: 'Hoefsmid'
        },
        dashboard: {
            users: 'Gebruikers',
            userManagement: 'Gebruikersbeheer',
            accessControl: 'Toegangsbeheer',
            appointments: 'Afspraken',
            payments: 'Betalingen',
            calendars: 'Agenda\'s',
            schedule: 'Schema',
            lessons: 'Lessen',
            lessonSchedule: 'Lesboek',
            dashboard: 'Dashboard',
            increase: 'Toename',
            decrease: 'Afname',
            sinceLastMonth: 'Vergeleken met vorige maand',
            noDataTextPreviousMonth: 'Geen data beschikbaar voor de vorige maand',
            overview: 'Overzicht',
            confirmed: 'Bevestigd',
            confirm: 'Bevestig',
            reject: 'Weiger',
            recent: 'Recente',
            revenue: 'Omzet',
            paid: 'Betaald',
            pending: 'Openstaand',
            canceled: 'Geannuleerd',
            cancel: 'Annuleer',
            expired: 'Verlopen',
            minutes: 'Minuten',
            hours: 'Uur',
            days: 'Dagen',
            weeks: 'Weken',
            months: 'Maanden',
            ago: 'Geleden',
            created: 'Aangemaakt',
            updated: 'Geüpdatet',
            deleted: 'Verwijderd',
            user: 'Gebruiker',
            calendar: 'Agenda',
            appointment: 'Afspraak',
            lesson: 'Les',
            payment: 'Betaling',
            activityLog: 'Activiteiten log',
            daily: 'Dagelijks',
            weekly: 'Wekelijks',
            monthly: 'Maandelijks',
            today: 'Vandaag',
            thisWeek: 'Deze week',
            thisMonth: 'Deze maand',
            yesterday: 'Gisteren',
            previousWeek: 'Vorige week',
            previousMonth: 'Vorige maand',
            total: 'Totaal',
            details: 'Details',
            actions: 'Acties',
            bronze: 'Brons',
            silver: 'Zilver',
            gold: 'Goud',
            navigation: 'Navigatie',
            announcement: 'Mededeling',
            announcements: 'Mededelingen',
            publish: 'Deel'
        },
        subscription: {
            subscription: 'Abonnement',
            bronze: 'Brons',
            silver: 'Zilver',
            gold: 'Goud',
            canceledMessage: 'Het abonnement is geannuleerd en loopt af op',
            resume: 'Hervat',
            resumeMessage: 'Het is tot aan de einddatum mogelijk om het abonnement te hervatten door op onderstaande knop te drukken.',
            resumeTitle: 'Hervat abonnement',
            resumeConfirmMessage: 'Met deze actie wordt het geannuleerde abonnement van deze gebruiker hervat. De gebruiker zal hier automatisch van op de hoogte worden gesteld. Wilt u hiermee doorgaan?',
            activeSince: 'Actief sinds',
            change: 'Wijzig',
            changeTitle: 'Wijzig abonnement',
            changeMessage: 'Wijzig het huidige abonnement van deze gebruiker? De gebruiker zal hier automatisch van op de hoogte worden gesteld.',
            cancel: 'Annuleer',
            cancelTitle: 'Annuleer abonnement',
            cancelMessage: 'Met deze actie zal het abonnement van deze gebruiker per de volgende betalingscyclus geannuleerd worden. De gebruiker zal hier automatisch van op de hoogte worden gesteld. Wilt u hiermee doorgaan?',
            noSubscription: 'Deze gebruiker heeft nog geen abonnement afgesloten',
            signUp: 'Schrijf in',
            signUpTitle: 'Schrijf in voor abonnement',
            singUpMessage : 'Stuur deze gebruiker een uitnodiging voor het voor het afsluiten van een abonnement?',
            active: 'Actief',
            inactive: 'Geblokkeerd',
            activateTitle: 'Activeer gebruiker',
            activateMessage: 'Met deze actie wijzigt u de toegang tot de app voor deze gebruiker. Wilt u hiermee doorgaan?',
            blockTitle: 'Blokkeer gebruiker',
            admin: 'Admin',
            regularUser: 'Reguliere gebruiker',
            changeRightsTitle: 'Wijzig rechten',
            removeAdmin: 'Met deze actie ontneemt u de admin rechten van de geselecteerde gebruiker. Wilt u hiermee doorgaan?',
            addAdmin: 'Met deze actie geeft u de geselecteerde gebruiker admin rechten. Hiermee krijgt deze toegang tot potentieel gevoelige informatie. Wilt u hiermee doorgaan?',
            canceled: 'Geannuleerd',
            current: 'Huidige abonnement',
            status: 'Account status',
            confirm: 'Bevestig',
            confirmUser: 'Deze gebruiker dient eerst bevestigd te worden (dit kan ook direcht in het dashboard overzicht)',
            confirmTitle: 'Bevestig gebruiker', 
            confirmMessage: 'Weet u zeker dat u de registratie aanvraag van deze gebruiker wilt bevestigen?',
            access: 'Toegang'
        },

        userpanel: {
            contactdetails: 'Contactgegevens',
            subscription: 'Abonnement',
            paymentHistory: 'Betaalgeschiedenis',
            personaldetails: {
                name: 'Naam',
                email: 'E-mailadres',
                phoneNumber: 'Telefoonnummer',
                address: 'Adres',
                addressDetails: 'Adresgegevens',
                editDetails: 'Gegevens wijzigen',
            },
            subscriptionDetails: {
                na: 'n.v.t',
                startDate: 'Ingangsdatum',
                changedNotification: 'Er is een wijziging doorgevoerd',
                subscriptionCanceled: 'Uw abonnement is geannuleerd en loopt af op',
                subscriptionSwapped: 'Uw abonnement zal per de eerstvolgende betalingscyclus (:date) om worden gezet naar het \':plan\' abonnement',
                editSubscription: 'Abonnement wijzigen',
                contact: 'Neem voor het wijzigen van uw abonnement contact op met de manege'
            },
            orderHistory: {
                date: 'Datum',
                total: 'Totaal',
                status: 'Status',
                invoice: 'Factuur',
                paid: 'Betaald',
                pending: 'Openstaand',
                canceled: 'Geannuleerd',
                expired: 'Verlopen',
                download: 'Download',
            }
        },
        sidebar: {
            home: 'Home',
            profile: 'Profiel',
            user: 'Gebruiker',
            horse: 'Paard',
            appointments: 'Afspraken',
            myAppointments: 'Mijn Afspraken',
            schedule: 'Schema',
            dashboard: 'Dashboard',
            users: 'Gebruikers',
            accessControl: 'Toegangsbeheer',
            horses: 'Paarden',
            calendars: 'Agenda\'s',
            lessons: 'Lessen',
            lessonSchedule: 'Lesrooster'
        }
    }
}

export default translations;