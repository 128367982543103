
import React, { FC } from 'react';
import _ from 'lodash';

import Card from 'components/Card/Card';

import AppointmentComponent from 'containers/UserPanel/Appointments/Overview/AppointmentComponent';
import AnnouncementComponent from 'containers/Admin/AdminPanel/Sections/Dashboard/Announcements/Announcement';

export interface HomeProps {
    message: string
    announcements: AnnouncementType[]
    appointments: AppointmentType[]
    horses: HorseType[]
}

// TODO: make separate components (e.g. Announcements, Horses, etc.)
const Home: FC<HomeProps> = ({ message, announcements, appointments, horses }) => {
    return (
        <div>
            <div className='userpanel-row'>
                <div className='userpanel-row-item'>
                    <Card header={'Mededelingen'} height={'476px'}>
                        <div>
                            {announcements ? _.orderBy(announcements, ['created_at'], ['desc']).slice(0, 5).map((announcement) =>
                                <AnnouncementComponent key={announcement.id} announcement={announcement} />
                            )
                                : null}
                        </div>
                    </Card>
                </div>
                <div className='userpanel-row-item'>
                    <Card header={'Eerstvolgende afspraken'} height={'476px'}>
                        {_.orderBy(appointments, ['start_time'], ['asc']).slice(0, 5).map((appointment) => new Date(appointment.start_time) > new Date() ? <AppointmentComponent key={appointment.id} appointment={appointment} /> : null)}
                    </Card>
                </div>
            </div>

            <div className='userpanel-row'>
                <div className='userpanel-row-item'>
                    <Card header={'Paard'}>
                        {horses ? horses.map((horse) =>
                            <Card key={horse.id}>
                                <div className='horizontal-stack'>
                                    <div className='horizontal-divider' />
                                    <div>
                                        <div><span className='property'>{'Naam: '}</span>{horse.name}</div>
                                        <div><span className='property'>{'Chipnummer: '}</span>{horse.chip_number}</div>
                                        <div><span className='property'>{'Dierenarts: '}</span>{horse.veterinarian}</div>
                                        <div><span className='property'>{'Hoefsmid: '}</span>{horse.farrier}</div>
                                    </div>
                                </div>
                            </Card>
                        ) : null}
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Home;
