import React, { FC } from 'react';

import './SearchInput.scss';

export interface SearchInputProps {
    placeholder?: string
    value: string
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleClear?: () => void
}

const SearchInput: FC<SearchInputProps> = ({ placeholder, value, handleSearch, handleClear }) => {
    return (
        <div className='search-container'>
            <input
                type='text'
                className='input'
                autoComplete='off'
                placeholder={placeholder}
                value={value}
                onChange={handleSearch}
            />
            {handleClear
                ? <div className='search-input-clear' style={{position: 'absolute'}} onClick={handleClear}>{'✕'}</div>
                : null}
        </div>

    );
}

export default SearchInput;
