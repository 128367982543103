import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { getLanguage, getLocales } from 'core/store/base/selectors';

import Card from 'components/Card/Card';
import { format } from 'date-fns';

export interface AppointmentComponentProps {
    appointment: AppointmentType
    calendar?: CalendarType | undefined
}

const AppointmentComponent: FC<AppointmentComponentProps> = ({ appointment, calendar }) => {
    const language = useSelector(getLanguage);
    const locales = useSelector(getLocales);

    return (
        <div style={{ marginBottom: '10px', whiteSpace: 'nowrap' }}>
            <Card>
                <div className='align-center'>
                    <div className='subscription-plan-indicator' style={{ backgroundColor: calendar ? calendar.color : window.Branding.primaryColor }} />
                    <div className='horizontal-divider' />
                    <div>
                        <div className='appointment-type'>{appointment.type}</div>
                        <div className='footnote'>{`${format(appointment.start_time, 'dd MMMM yyyy, H:mm', { locale: locales[language] })}-${format(appointment.end_time, 'H:mm', { locale: locales[language] })}`}</div>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default AppointmentComponent;
