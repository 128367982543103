import { ContentProps } from 'components/Sidebar/Sidebar';

export const content: ContentProps[] = [
    {
        title: 'home',
        icon: 'home',
        pages: [
            {
                title: 'home',
                url: '?section=home&page=home',
                tag: 0
            }
        ]
    },
    {
        title: 'profile',
        icon: 'user',
        pages: [
            {
                title: 'user',
                url: '?section=profile&page=user',
                tag: 1
            },
            {
                title: 'horse',
                url: '?section=profile&page=horse',
                tag: 2
            }
        ]
    },
    {
        title: 'appointments',
        icon: 'calendar',
        pages: [
            {
                title: 'myAppointments',
                url: '?section=appointments&page=appointments',
                tag: 3
            },
            {
                title: 'schedule',
                url: '?section=appointments&page=schedule',
                tag: 4
            },
        ]
    }
];