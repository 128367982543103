import { SET_CALENDARS, SET_USERS, SET_APPOINTMENTS, SET_LESSONS, SET_PAYMENTS, SET_HORSES, SET_USERACTIONS, SET_ANNOUNCEMENTS, AdminActionTypes } from './types';

export const setUsers = ({ users }: { users: [] }): AdminActionTypes => ({
    type: SET_USERS,
    payload: users
});

export const setCalendars = ({ calendars }: { calendars: [] }): AdminActionTypes => ({
    type: SET_CALENDARS,
    payload: calendars
});

export const setAppointments = ({ appointments }: { appointments: [] }): AdminActionTypes => ({
    type: SET_APPOINTMENTS,
    payload: appointments
});

export const setLessons = ({ lessons }: { lessons: [] }): AdminActionTypes => ({
    type: SET_LESSONS,
    payload: lessons
});

export const setPayments = ({ payments }: { payments: [] }): AdminActionTypes => ({
    type: SET_PAYMENTS,
    payload: payments
});

export const setHorses = ({ horses }: { horses: [] }): AdminActionTypes => ({
    type: SET_HORSES,
    payload: horses
});

export const setAnnouncements = ({ announcements }: { announcements: [] }): AdminActionTypes => ({
    type: SET_ANNOUNCEMENTS,
    payload: announcements
});

export const setUserActions = ({ userActions }: { userActions: [] }): AdminActionTypes => ({
    type: SET_USERACTIONS,
    payload: userActions
});